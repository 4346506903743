import { createContext, useContext } from 'react'

import { HotspotAnalysisViewType } from '@cozero/models'

export interface HotspotAnalysisContextInterface {
  type: HotspotAnalysisViewType
}

const boardsContext = createContext<HotspotAnalysisContextInterface | undefined>(undefined)

export const { Provider: HotspotAnalysisProvider } = boardsContext

export const useHotspotAnalysisContext = (): HotspotAnalysisContextInterface => {
  const context = useContext(boardsContext)

  if (!context) {
    throw new Error('useHotspotAnalysisContext must be used within a HotspotAnalysisProvider')
  }

  return context
}
