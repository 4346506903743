import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import { Col, Divider, Row, Spin, message } from 'antd'

import { saveAs } from 'file-saver'

import { FactorRequest } from '@cozero/models'

import LifecycleModalTitle from '@/pages/Organization/NewProduct/LifecycleModalTitle'

import CustomHeader from '@/organisms/CustomModalHeader'
import EmissionPerLifecycleTable from '@/organisms/EmissionPerLifecycleTable'
import { StrategyBuilderOverviewCard } from '@/organisms/StrategyBuilderOverviewCards/StrategyBuilderOverviewCard'

import Button from '@/atoms/Button'
import CheckOutlineIcon from '@/atoms/Icons/CheckOutline'
import CubeOutlineIcon from '@/atoms/Icons/CubeOutline'
import PaperClipOutlineIcon from '@/atoms/Icons/PaperClipOutline'
import XOutlineIcon from '@/atoms/Icons/XOutline'
import LazyLoadImage from '@/atoms/LazyLoadImage'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'

import productConfiguration from '@/assets/lifecycle-steps/product-configuration.svg'
import placeholderImage from '@/assets/placeholder-image.png'
import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useFactorContext } from '@/contexts/factor'
import { CINDER_BLUE_50 } from '@/styles/variables'
import { formatLifecycleSteps } from '@/utils/formats'
import { formatCompact } from '@/utils/number'
import { calculateEmissionValue } from '@/utils/recalculations'

import classes from './classes.module.less'

function FactorReviewPage(): JSX.Element {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [loadingAccept, setLoadingAccept] = useState(false)
  const [loadingReject, setLoadingReject] = useState(false)
  const { factorRequest, getFactorRequest, saveFactorRequest } = useFactorContext()

  const fetchData = async (): Promise<void> => {
    if (id) {
      setLoading(true)
      await getFactorRequest(+id)
      setLoading(false)
    }
  }

  const totalEmissionValue = useMemo(() => {
    if (factorRequest) {
      return calculateEmissionValue(factorRequest.product?.mass, factorRequest.value)
    }
  }, [factorRequest])

  const sharedLifeCycles = useMemo(() => {
    if (factorRequest) {
      return formatLifecycleSteps(
        factorRequest?.factorRequestResponse?.shownLifecycleSteps as {
          id: number
          value: number
        }[],
      )
    }
    return []
  }, [factorRequest])

  const handleUpdateFactorRequest = async (
    id: number,
    factorRequest: Partial<FactorRequest>,
    setLoadingButton: React.Dispatch<React.SetStateAction<boolean>>,
  ): Promise<void> => {
    setLoadingButton(true)
    await saveFactorRequest(id, { ...factorRequest })
    if (factorRequest.status === 'ACCEPTED') {
      message.success(t('factors.accept-success'), 5)
    }
    if (factorRequest.status === 'REJECTED') {
      message.info(t('factors.reject-info'), 5)
    }
    setLoadingButton(false)
    navigate(-1)
  }

  const downloadFile = (blob: Blob | string, filename: string): void => {
    saveAs(blob, filename)
  }

  useEffect(() => {
    fetchData()
  }, [id])

  return (
    <div className={classes.container}>
      <div className={classes.sider}></div>
      <div className={classes.mainSection}>
        {factorRequest && !loading ? (
          <>
            <CustomHeader
              showArrow={true}
              title={factorRequest?.activityDataSource?.name as string}
              onClose={() => navigate(-1)}
              goBack={() => {
                navigate(-1)
              }}
            />

            <div className={classes.content}>
              <LifecycleModalTitle
                image={productConfiguration}
                title={
                  <Trans
                    i18nKey="customer-requests.review-modal.title"
                    components={{
                      br: <br />,
                    }}
                  />
                }
                description={''}
              />

              <Row
                className={classes.productDetails}
                gutter={[0, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              >
                <Col flex={2} className={classes.productInfo}>
                  <Row gutter={20}>
                    <Col>
                      <LazyLoadImage
                        src={factorRequest.product?.image?.url ?? placeholderImage}
                        className={classes.productImage}
                      />
                    </Col>
                    <Col className={classes.productLabels}>
                      <Row gutter={40}>
                        <Col className={classes.productLabel}>
                          <Text fontWeight="medium" size="xl">
                            {t('customer-requests.product-name')}
                          </Text>
                        </Col>
                        <Col className={classes.productValue}>
                          <Tag
                            className={classes.tag}
                            icon={
                              <CubeOutlineIcon width={16} height={16} className={classes.icon} />
                            }
                          >
                            <Text fontWeight="medium">
                              {factorRequest?.activityDataSource?.name as string}
                            </Text>
                          </Tag>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col flex={1} className={classes.productFootprint}>
                  <StrategyBuilderOverviewCard title={t('log.products-title')}>
                    <div>
                      <Text
                        mode="title"
                        fontWeight="medium"
                        size="md"
                        className={classes.valueWithUnit}
                      >
                        {totalEmissionValue ? formatCompact(totalEmissionValue) : '--'}
                      </Text>

                      <Text size="lg" fontWeight="medium">
                        {factorRequest?.unit?.name as string}
                      </Text>
                    </div>
                  </StrategyBuilderOverviewCard>
                </Col>
              </Row>

              {Boolean(factorRequest?.factorRequestResponse?.shownLifecycleSteps.length) && (
                <EmissionPerLifecycleTable
                  factorRequest={factorRequest}
                  sharedLifeCycles={sharedLifeCycles}
                  totalEmissionValue={totalEmissionValue}
                />
              )}

              {(Boolean(factorRequest?.factorRequestResponse?.message) ||
                Boolean(factorRequest?.factorRequestResponse?.files.length)) && (
                <div className={classes.additionalSection}>
                  <Row>
                    <Col span={14}>
                      <Text fontWeight="semibold" size="xl">
                        {t('carbon-actions.metadata.title')}
                      </Text>
                    </Col>

                    <Col span={10}>
                      <Text fontWeight="semibold" size="xl">
                        {t('customer-requests.review-modal.attached-files')}
                      </Text>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12} className={classes.additionalInfo}>
                      <div className={classes.message}>
                        <Text color="secondary" fontWeight="regular" mode="paragraph" size="xl">
                          {factorRequest?.factorRequestResponse?.message}
                        </Text>
                      </div>
                    </Col>
                    <Col span={2}>
                      <Divider type="vertical" style={{ height: '100%', marginLeft: '50%' }} />
                    </Col>
                    <Col span={10} className={classes.filesSection}>
                      {factorRequest?.factorRequestResponse?.files?.map((file, index) => {
                        const { name, path } = file || {}
                        const fileName =
                          name || (path && path.split('/').pop()) || `file-${index + 1}`
                        return file.url ? (
                          <div
                            className={classes.file}
                            key={file.id}
                            onClick={() => downloadFile(file.url as string, fileName)}
                          >
                            <PaperClipOutlineIcon width={14} height={14} color={CINDER_BLUE_50} />
                            <Text size="xl" color="secondary">
                              {fileName}
                            </Text>
                          </div>
                        ) : null
                      })}
                    </Col>
                  </Row>
                </div>
              )}
            </div>

            <footer className={classes.footer}>
              <Row gutter={30} className={classes.footerContent}>
                <Col>
                  <Button
                    size="lg"
                    prefixIcon={<XOutlineIcon width={16} height={16} />}
                    action={'reject'}
                    category={AnalyticsCategories.FACTOR_REQUESTS}
                    color="danger"
                    loading={loadingReject}
                    onClick={() =>
                      handleUpdateFactorRequest(
                        factorRequest.id,
                        {
                          status: 'REJECTED',
                          unitId: factorRequest.unitId,
                        },
                        setLoadingReject,
                      )
                    }
                  >
                    {t('customer-requests.reject')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    prefixIcon={<CheckOutlineIcon width={16} height={16} />}
                    action={'accept'}
                    category={AnalyticsCategories.FACTOR_REQUESTS}
                    size="lg"
                    loading={loadingAccept}
                    onClick={() =>
                      handleUpdateFactorRequest(
                        factorRequest.id,
                        {
                          status: 'ACCEPTED',
                          unitId: factorRequest.unitId,
                        },
                        setLoadingAccept,
                      )
                    }
                  >
                    {t('customer-requests.accept')}
                  </Button>
                </Col>
              </Row>
            </footer>
          </>
        ) : (
          <Row className={classes.centeredSpin}>
            <Spin spinning />
          </Row>
        )}
      </div>
    </div>
  )
}

export default FactorReviewPage
