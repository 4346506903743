import { omit } from 'lodash-es'

import { BusinessUnit, EmissionsSumGroupResultItem, Location } from '@cozero/models'

import { Emissions, NodeToEmissionsMapType } from '@/redux/organigram/slice'

export enum NodeType {
  BUSINESS_UNIT = 'Business unit',
  LOCATION = 'Location',
}

export type OrganigramNodeDatum = {
  name: string
  attributes: {
    id: number
    type: NodeType
    organizationName?: string
    externalId?: string
    responsibleName?: string
  }
  children?: OrganigramNodeDatum[]
}

export function updateTreeData(
  list: BusinessUnit[],
  id: React.Key,
  children: BusinessUnit[],
): BusinessUnit[] {
  const cleanChildren = children.map((child) => {
    if (child.children?.length === 0) {
      child = omit(child, 'children') as BusinessUnit
    }

    return child
  })

  return list.map((node) => {
    if (node.id === id) {
      return {
        ...node,
        children: cleanChildren,
      }
    }

    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children as BusinessUnit[], id, cleanChildren),
      }
    }

    return node
  })
}

export const createNodeToEmissionsMap = (
  rootNode: OrganigramNodeDatum,
  locationEmissions: EmissionsSumGroupResultItem[],
): NodeToEmissionsMapType => {
  const processingQueue = getPostorderTraversalForTree(rootNode)

  return createNodeToEmissionsMapFromQueue(processingQueue, locationEmissions)
}

const getPostorderTraversalForTree = (rootNode: OrganigramNodeDatum): OrganigramNodeDatum[] => {
  const parseTreeStack: OrganigramNodeDatum[] = [rootNode]
  const processingQueue: OrganigramNodeDatum[] = [rootNode]

  while (parseTreeStack.length !== 0) {
    const currentNode = parseTreeStack.pop()
    const children = currentNode?.children ?? []
    processingQueue.unshift(...children)
    parseTreeStack.push(...children)
  }
  return processingQueue
}

const createNodeToEmissionsMapFromQueue = (
  processingQueue: OrganigramNodeDatum[],
  locationEmissions: EmissionsSumGroupResultItem[],
): NodeToEmissionsMapType => {
  const nodeToEmissionsMap: NodeToEmissionsMapType = {
    locations: getLocationToEmissionsMap(locationEmissions),
    businessUnits: {},
  }

  for (const node of processingQueue) {
    if (node.attributes.type === NodeType.LOCATION) {
      if (!nodeToEmissionsMap.locations[node.attributes.id]) {
        nodeToEmissionsMap.locations[node.attributes.id] = {
          total: 0,
          scope1: 0,
          scope2: 0,
          scope3: 0,
        }
      }
      continue
    }
    if (node?.children && node.children.length > 0) {
      const childEmissions = mapChildNodeToEmissions(node.children, nodeToEmissionsMap)
      nodeToEmissionsMap.businessUnits[node.attributes.id] = sumChildEmissions(childEmissions)
      continue
    }

    nodeToEmissionsMap.businessUnits[node.attributes.id] = {
      total: 0,
      scope1: 0,
      scope2: 0,
      scope3: 0,
    }
  }
  return nodeToEmissionsMap
}

export const getLocationToEmissionsMap = (
  items: EmissionsSumGroupResultItem[],
): { [key: number]: Emissions } => {
  return items.reduce((mappedBusinessUnitEmissions, item) => {
    const scope1 = item.scope.find((scope) => scope.scope === 1)?.total ?? 0
    const scope2 = item.scope.find((scope) => scope.scope === 2)?.total ?? 0
    const scope3 = item.scope.find((scope) => scope.scope === 3)?.total ?? 0
    const total = item.total ?? 0

    return {
      ...mappedBusinessUnitEmissions,
      [item.id]: { scope1, scope2, scope3, total },
    }
  }, {})
}

export const mapChildNodeToEmissions = (
  childNodes: OrganigramNodeDatum[],
  nodeToEmissionsMap: NodeToEmissionsMapType,
): Emissions[] => {
  return childNodes.map((childNode) => {
    const zeroEmissions = {
      scope1: 0,
      scope2: 0,
      scope3: 0,
      total: 0,
    }
    if (childNode.attributes.type === NodeType.LOCATION) {
      return nodeToEmissionsMap.locations[childNode.attributes.id] ?? zeroEmissions
    }
    return nodeToEmissionsMap.businessUnits[childNode.attributes.id] ?? zeroEmissions
  })
}

export const sumChildEmissions = (childNodeEmissions: Emissions[]): Emissions => {
  return childNodeEmissions.reduce(
    (sum, childEmissions) => {
      return {
        total: sum.total + childEmissions.total,
        scope1: sum.scope1 + childEmissions.scope1,
        scope2: sum.scope2 + childEmissions.scope2,
        scope3: sum.scope3 + childEmissions.scope3,
      }
    },
    { scope1: 0, scope2: 0, scope3: 0, total: 0 },
  )
}

const mapBusinessUnitToNode = (node: BusinessUnit): OrganigramNodeDatum => {
  return {
    name: node.title,
    attributes: {
      id: node.id,
      externalId: node?.metadata?.externalId,
      type: NodeType.BUSINESS_UNIT,
      organizationName: node.organization?.name,
    },
    children: [],
  }
}

const mapLocationToNode = (node: Location): OrganigramNodeDatum => {
  const responsibleName =
    node.responsible?.firstName && node.responsible?.lastName
      ? `${node.responsible?.firstName} ${node.responsible?.lastName}`
      : ''

  return {
    name: node.name,
    attributes: {
      id: node.id,
      externalId: node?.metadata?.externalId,
      type: NodeType.LOCATION,
      organizationName: node.organization?.name,
      responsibleName,
    },
    children: [],
  }
}

export const computeGraphData = (
  organizationUnit: BusinessUnit | Location,
): OrganigramNodeDatum => {
  const isBusinessUnit = 'ancestorIds' in organizationUnit

  if (isBusinessUnit) {
    const businessUnit = organizationUnit as BusinessUnit

    const mappedNode = mapBusinessUnitToNode(businessUnit)

    if (businessUnit?.children || businessUnit?.locations) {
      const businessUnitChildren = (businessUnit?.children || []).map((child) =>
        computeGraphData(child as BusinessUnit),
      )
      const locationChildren = (businessUnit?.locations || []).map((child) =>
        computeGraphData(child as Location),
      )
      mappedNode.children = [...businessUnitChildren, ...locationChildren]
    }
    return mappedNode
  }
  return mapLocationToNode(organizationUnit)
}
