/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { HiDownload, HiOutlineDotsHorizontal } from 'react-icons/hi'

import { Dropdown } from 'antd/es'

import { HotspotAnalysisData } from '@cozero/models'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useAppSelector } from '@/redux'
import {
  selectHotspotAnalysisDateFilter,
  selectHotspotAnalysisExpandedRowKeys,
} from '@/redux/actAnalytics/selectors'
import { round } from '@/utils/number'

import classes from './HotspotAnalysisExportDropdown.module.less'
import {
  CsvRow,
  downloadBlob,
  flattenTree,
  getCsvBlob,
  getExportFilename,
  getXlsxBlob,
} from './HotspotAnalysisExportDropdown.utils'
import { getParentRowKey, getScope, getScopeCategory } from './utils/HotspotAnalysis.utils'

interface Props {
  disabled: boolean
  data: HotspotAnalysisData[]
}

export const HotspotAnalysisExportDropdown = ({ data, disabled }: Props): ReactElement => {
  const { t } = useTranslation('common')
  const { trackAction } = useMixpanel()

  const dates = useAppSelector(selectHotspotAnalysisDateFilter)
  const expandedRowKeys = useAppSelector(selectHotspotAnalysisExpandedRowKeys)

  const handleExport = useCallback(
    async ({
      extension,
      filterOpen = false,
    }: {
      extension: 'csv' | 'xlsx'
      filterOpen?: boolean
    }) => {
      trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'export', {
        key: 'hotspot-analysis',
        format: extension,
      })

      if (data) {
        const flattenedData = flattenTree(data)
          .filter((record) => {
            const parentRowKey = getParentRowKey(record)
            const isRoot = record.level === 1

            return !filterOpen || isRoot || expandedRowKeys.includes(parentRowKey)
          })
          .map((record) => {
            return {
              ...record,
              scopeCategoryNumber: getScope(record),
              scopeSubcategory: getScopeCategory(record),
              emissions: round(record.emissions, 2),
              emissionsOfScopePercent: round(record.emissionsOfScopePercent, 2),
              emissionsOfOrganization: round(record.emissionsOfOrganization, 2),
            }
          })

        const headers: (keyof CsvRow)[] = [
          'scopeCategoryNumber',
          'scopeSubcategory',
          'logCategoryName',
          'logEntrySubcategoryName',
          'activityDataSourceName',
          'emissions',
          'emissionsOfScopePercent',
          'emissionsOfOrganization',
          'hotspotAssessment',
        ]

        const columns = headers.map((field) => {
          const title = t(`act.analytics.hotspotAnalysis.table.${field}.label`)
          return [field, title] as const
        })

        const filename = getExportFilename({ dates, extension })

        if (extension === 'csv') {
          const options = columns.map(([field, title]) => ({ field, title }))

          const blob = await getCsvBlob(flattenedData, options)
          downloadBlob(blob, filename)
        }

        if (extension === 'xlsx') {
          const options = columns.map(([key, header]) => ({ key, header }))

          const blob = await getXlsxBlob(flattenedData, options)
          downloadBlob(blob, filename)
        }
      }
    },
    [data, dates, expandedRowKeys, t, trackAction],
  )

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlayClassName={classes.dotsMenu}
      getPopupContainer={(trigger) => trigger.parentElement || document.body}
      menu={{
        items: [
          {
            key: 'csv-export-all',
            label: t('act.analytics.hotspotAnalysis.export.all.csv'),
            icon: <HiDownload />,
            disabled,
            onClick: () => handleExport({ extension: 'csv' }),
          },
          {
            key: 'xlsx-export-all',
            label: t('act.analytics.hotspotAnalysis.export.all.xlsx'),
            icon: <HiDownload />,
            disabled,
            onClick: () => handleExport({ extension: 'xlsx' }),
          },
          {
            key: 'csv-export-open',
            label: t('act.analytics.hotspotAnalysis.export.open.csv'),
            icon: <HiDownload />,
            disabled,
            onClick: () => handleExport({ extension: 'csv', filterOpen: true }),
          },
          {
            key: 'xlsx-export-open',
            label: t('act.analytics.hotspotAnalysis.export.open.xlsx'),
            icon: <HiDownload />,
            disabled,
            onClick: () => handleExport({ extension: 'xlsx', filterOpen: true }),
          },
        ],
      }}
    >
      <HiOutlineDotsHorizontal className={classes.dotsIcon} size={18} />
    </Dropdown>
  )
}
