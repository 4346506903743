import { InputProps } from 'antd/es'

import { LogicOperatorInputValueDto } from '@cozero/dtos'

import { LogicOperatorOption } from './inputs/LogicOperatorInput'

// Define FilterValues as a combination of different filter value types
export type FilterValues<
  TSearchSelectFiltersValuesKey extends string,
  TDatePickerFiltersValuesKey extends string,
  TTextFiltersValuesKey extends string,
  TLogicOperatorFiltersValuesKey extends string,
> = Partial<
  SearchSelectFiltersValues<TSearchSelectFiltersValuesKey> &
    DatePickerFiltersValues<TDatePickerFiltersValuesKey> &
    TextFiltersValues<TTextFiltersValuesKey> &
    LogicOperatorFiltersValues<TLogicOperatorFiltersValuesKey>
>

/** Filters where the outcome is a multi-select that supports search while typing */
export type SearchSelectFiltersValues<TSearchSelectFiltersValuesKey extends string> = Record<
  TSearchSelectFiltersValuesKey,
  number[]
>

/** filters that outcome will be a date */
export type DatePickerFiltersValues<TDatePickerFiltersValuesKey extends string> = Record<
  TDatePickerFiltersValuesKey,
  Date
>

/** filters that outcome must be a string */
export type TextFiltersValues<TTextFiltersValuesKey extends string> = Record<
  TTextFiltersValuesKey,
  string
>

/** filters that outcome must be a logic object */
export type LogicOperatorFiltersValues<TLogicOperatorFiltersValuesKey extends string> = Record<
  TLogicOperatorFiltersValuesKey,
  LogicOperatorInputValueDto
>

export type SearchSelectFiltersOptions<TSearchSelectFiltersValuesKey extends string> =
  SearchSelectFiltersValuesMap<
    TSearchSelectFiltersValuesKey,
    {
      value: number
      label: string
    }[]
  >
export type SearchSelectFiltersOptionsLoading<TSearchSelectFiltersValuesKey extends string> =
  SearchSelectFiltersValuesMap<TSearchSelectFiltersValuesKey, boolean>
export type SearchSelectFiltersOptionsOnSearch<TSearchSelectFiltersValuesKey extends string> =
  SearchSelectFiltersValuesMap<TSearchSelectFiltersValuesKey, (value: string) => void>

type SearchSelectFiltersValuesMap<TSearchSelectFiltersValuesKey extends string, T> = Partial<
  Record<keyof SearchSelectFiltersValues<TSearchSelectFiltersValuesKey>, T>
>

export enum FomInputType {
  SEARCH_SELECT = 'SEARCH_SELECT',
  DATE_PICKER = 'DATE_PICKER',
  DATE_PICKER_YEAR = 'DATE_PICKER_YEAR',
  TEXT = 'TEXT',
  LOGIC_OPERATOR = 'LOGIC_OPERATOR',
}

export type FormInput<
  TSearchSelectFiltersValuesKey extends string,
  TDatePickerFiltersValuesKey extends string,
  TTextFiltersValuesKey extends string,
  TLogicOperatorFiltersValuesKey extends string,
> = { label: string } & (
  | {
      type: FomInputType.SEARCH_SELECT
      key: keyof SearchSelectFiltersValues<TSearchSelectFiltersValuesKey>
    }
  | {
      type: FomInputType.DATE_PICKER
      key: keyof DatePickerFiltersValues<TDatePickerFiltersValuesKey>
    }
  | {
      type: FomInputType.DATE_PICKER_YEAR
      key: keyof DatePickerFiltersValues<TDatePickerFiltersValuesKey>
    }
  | {
      type: FomInputType.TEXT
      key: keyof TextFiltersValues<TTextFiltersValuesKey>
      placeholder: string
    }
  | {
      type: FomInputType.LOGIC_OPERATOR
      key: keyof LogicOperatorFiltersValues<TLogicOperatorFiltersValuesKey>
      options: LogicOperatorOption[]
      inputProps?: InputProps
    }
)
