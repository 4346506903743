/* eslint react-hooks/exhaustive-deps: 2 */
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganizationCarbonFootprintSearchFiltersDto } from '@cozero/dtos'

import { FilterValues, FomInputType, FormInput } from '@/molecules/FilterDrawerForm/types'

export type SearchSelectFiltersValuesKey = keyof Pick<
  OrganizationCarbonFootprintSearchFiltersDto,
  | 'businessUnit'
  | 'location'
  | 'category'
  | 'subCategory'
  | 'calculationMethod'
  | 'responsible'
  | 'territory'
  | 'supplier'
  | 'organizationTags'
  | 'scopeAndSubcategory'
  | 'activityDataSource'
  | 'emissionFactorUnit'
  | 'emissionFactorSource'
  | 'emissionFactorTerritory'
>

export type DatePickerFiltersValuesKey = keyof Pick<
  OrganizationCarbonFootprintSearchFiltersDto,
  'timePeriodStart' | 'timePeriodEnd' | 'createdAt' | 'emissionFactorYear'
>

export type TextFiltersValuesKey = keyof Pick<
  OrganizationCarbonFootprintSearchFiltersDto,
  'logDescription' | 'logEntryTitle' | 'logId' | 'logEntryId'
>

export type LogicOperatorFiltersValuesKey = keyof Pick<
  OrganizationCarbonFootprintSearchFiltersDto,
  'organizationEmissions' | 'numberOfFiles' | 'emissionFactorValue'
>

export type OrganizationCarboonFootprintFormValues = FilterValues<
  SearchSelectFiltersValuesKey,
  DatePickerFiltersValuesKey,
  TextFiltersValuesKey,
  LogicOperatorFiltersValuesKey
>

type FormInputs = FormInput<
  SearchSelectFiltersValuesKey,
  DatePickerFiltersValuesKey,
  TextFiltersValuesKey,
  LogicOperatorFiltersValuesKey
>[]

export type OrganizationCarboonFootprintInputsHookReturn = {
  inputs: FormInputs
}

export const useOrganizationCarbonFootprintFormConfiguration =
  (): OrganizationCarboonFootprintInputsHookReturn => {
    const { t } = useTranslation()

    const inputs: FormInputs = useMemo(() => {
      const inputs: FormInputs = []

      // Push each input individually (for easier validation)
      inputs.push({
        key: 'logId',
        type: FomInputType.TEXT,
        label: t('log.log-entries-drawer.input.log-id.label'),
        placeholder: t('log.log-entries-drawer.input.enter-id'),
      })

      /*
      // We are hidding this filter for the moment because we currently do not show the log entry id to the user
      inputs.push({
        key: 'logEntryId',
        type: FomInputType.TEXT,
        placeholder: t('log.log-entries-drawer.input.enter-id'),
        label: t('log.log-entries-drawer.input.log-entry-id.label'),
      })
      */

      inputs.push({
        key: 'logEntryTitle',
        type: FomInputType.TEXT,
        label: t('log.log-entries-drawer.input.log-entry-title.label'),
        placeholder: t('log.log-entries-drawer.input.log-entry-title.placeholder'),
      })

      inputs.push({
        key: 'logDescription',
        type: FomInputType.TEXT,
        label: t('log.log-entries-drawer.input.description.label'),
        placeholder: t('log.log-entries-drawer.input.description.placeholder'),
      })

      inputs.push({
        key: 'category',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.category'),
      })

      inputs.push({
        key: 'subCategory',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.subcategory'),
      })

      inputs.push({
        key: 'activityDataSource',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.business-activity'),
      })

      inputs.push({
        key: 'businessUnit',
        type: FomInputType.SEARCH_SELECT,
        label: t('business-unit.name'),
      })

      inputs.push({
        key: 'location',
        type: FomInputType.SEARCH_SELECT,
        label: t('location.location'),
      })

      inputs.push({
        key: 'timePeriodStart',
        type: FomInputType.DATE_PICKER,
        label: t('log.log-entries-overview.table-columns.period-start'),
      })

      inputs.push({
        key: 'timePeriodEnd',
        type: FomInputType.DATE_PICKER,
        label: t('log.log-entries-overview.table-columns.period-end'),
      })

      inputs.push({
        key: 'calculationMethod',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.calculation-method'),
      })

      inputs.push({
        key: 'responsible',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.responsible'),
      })

      inputs.push({
        key: 'territory',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.territory'),
      })

      inputs.push({
        key: 'supplier',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.supplier'),
      })

      /*
      inputs.push({
        key: 'numberOfFiles', // TODO: Confirm if this can be a logic operator instead of a select
        type: FomInputType.LOGIC_OPERATOR,
        label: 'Number of Files', // TODO: Translate
      });
      */

      inputs.push({
        key: 'createdAt',
        type: FomInputType.DATE_PICKER,
        label: t('log.createdAt'),
      })

      inputs.push({
        key: 'emissionFactorValue',
        type: FomInputType.LOGIC_OPERATOR,
        label: t('log.log-entries-overview.table-columns.emission-factor-value'),
        options: [
          {
            label: t('filter.is-equal'),
            value: 'EQUAL',
          },
          {
            label: t('filter.lt'),
            value: 'LESS',
          },
          {
            label: t('filter.gt'),
            value: 'MORE',
          },
        ],
        inputProps: {
          type: 'number',
        },
      })

      inputs.push({
        key: 'emissionFactorUnit',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.emission-factor-unit'),
      })

      inputs.push({
        key: 'emissionFactorYear',
        type: FomInputType.DATE_PICKER_YEAR,
        label: t('log.log-entries-overview.table-columns.emission-factor-year'),
      })

      inputs.push({
        key: 'emissionFactorSource',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.emission-factor-source'),
      })

      inputs.push({
        key: 'emissionFactorTerritory',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.emission-factor-territory'),
      })

      inputs.push({
        key: 'organizationEmissions',
        type: FomInputType.LOGIC_OPERATOR,
        label: t('organigram.export.columns.total'),
        options: [
          {
            label: t('filter.is-equal'),
            value: 'EQUAL',
          },
          {
            label: t('filter.lt'),
            value: 'LESS',
          },
          {
            label: t('filter.gt'),
            value: 'MORE',
          },
        ],
        inputProps: {
          type: 'number',
        },
      })

      inputs.push({
        key: 'organizationTags',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.organization-tags'),
      })

      inputs.push({
        key: 'scopeAndSubcategory',
        type: FomInputType.SEARCH_SELECT,
        label: t('log.log-entries-overview.table-columns.scope'),
      })

      return inputs
    }, [t])

    return { inputs }
  }
