import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Form, Row, Spin } from 'antd/es'
import { Store } from 'antd/es/form/interface'

import { debounce } from 'lodash-es'

import { Report, ReportCategory } from '@cozero/models'

import Modal from '@/molecules/Modal'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useBoardsContext } from '@/contexts/boards'
import { useMixpanel } from '@/hooks/useMixpanel'
import useThrottle from '@/hooks/useThrottle'

import classes from '../classes.module.less'

import ChooseReportCategory from './ChooseReportCategory'
import ReportsMenu from './ReportsMenu'

interface Props {
  open: boolean
  reportCategories: ReportCategory[]
  onCancel: () => void
  onOk: () => void
  editReportsOfBoard: (reports: Store, boardId: number, sharePageId?: number) => Promise<void>
}

const ReportsModal = ({
  reportCategories,
  open,
  onCancel,
  onOk,
  editReportsOfBoard,
}: Props): ReactElement => {
  const { t } = useTranslation('common')
  const { selectedBoard, getAvailableReports, lastDeletedReportId } = useBoardsContext()
  const [chosenReportCategory, setChosenReportCategory] = useState<ReportCategory>()
  const [reports, setReports] = useState<Report[]>([])
  const [chosenReports, setChosenReports] = useState<{ [key: string]: boolean | undefined }>({})
  const [inputValue, setInputValue] = useState<string | undefined>()
  const { trackAction } = useMixpanel()
  const throttle = useThrottle()

  const updateChosenReports = (availableReports: Report[]): void => {
    const fields = getInitialState(availableReports)
    setChosenReports({ ...fields })
  }

  const onSetReports = useCallback((reports: Report[]): void => {
    let auxReports = reports
    if (inputValue) {
      auxReports = reports.filter((report) =>
        (report.title as string)?.toLowerCase().includes(inputValue.toLowerCase()),
      )
    }
    setReports(auxReports)
    setInputValue(undefined)
  }, [])

  const onCancelClick = (): void => {
    trackAction(AnalyticsCategories.HOME, 'add-report-modal-cancel')
    setInputValue('')
    setChosenReportCategory(undefined)
    if (onCancel) {
      onCancel()
    }
  }

  const onSubmit = useCallback((): void => {
    trackAction(AnalyticsCategories.HOME, 'add-report-modal')
    saveForm(chosenReports)
  }, [chosenReports])

  const saveForm = useCallback(
    async (values: Store): Promise<void> => {
      if (selectedBoard) {
        throttle(async () => {
          onOk()
          await editReportsOfBoard(values, selectedBoard.id)
          setChosenReportCategory(undefined)
        })
      }
      setInputValue('')
    },
    [selectedBoard],
  )

  const getInitialState = (availableReports: Report[]): { [key: string]: boolean | undefined } => {
    const fields: { [key: string]: boolean } = {}
    if (selectedBoard?.reportToBoards) {
      availableReports.forEach((report) => {
        const key = report?.id.toString()
        // report is existing in the reportToBoards of the board
        fields[key] = !!selectedBoard?.reportToBoards?.find(
          (gridData) =>
            (gridData.report as Report)?.id.toString() === report.id.toString() ||
            chosenReports[key],
        )
      })
    }
    return fields
  }

  const getAllReports = (): Report[] => {
    const allReports = reportCategories.reduce(
      (acc, category) => [...acc, ...category.reports],
      [] as Report[],
    )
    return allReports.filter(
      (value, index, self) => self.findIndex((value2) => value2.id === value.id) === index,
    )
  }

  useEffect(() => {
    getAvailableReports()
    updateChosenReports(reports)
  }, [])

  useEffect(() => {
    if (chosenReportCategory) {
      updateChosenReports(reports)
    } else {
      const allReports = getAllReports()
      updateChosenReports(allReports)
    }
  }, [selectedBoard])

  useEffect(() => {
    if (lastDeletedReportId) {
      setChosenReports((prevReports) => ({
        ...prevReports,
        [lastDeletedReportId.toString()]: false,
      }))
    }
  }, [lastDeletedReportId])

  useEffect(() => {
    const allReports = getAllReports()
    if (JSON.stringify(allReports) !== JSON.stringify(reports)) {
      setReports(allReports)
      updateChosenReports(allReports)
    }
  }, [reportCategories])

  useEffect(() => {
    if (chosenReportCategory && inputValue !== undefined) {
      const filteredReports = chosenReportCategory.reports.filter((report) =>
        (report.title as string)?.toLowerCase().includes(inputValue.toLowerCase()),
      )
      debounce(() => setReports(filteredReports as (Report & { hidden: boolean })[]), 500)()
    } else {
      // Prevent race condition when switching from another tab or category
      if (inputValue === undefined) {
        return
      }
      const newReports = reportCategories.reduce(
        (acc, category) => [...acc, ...category.reports],
        [] as Report[],
      )
      debounce(
        () =>
          setReports(
            newReports
              .filter(
                (value, index, self) =>
                  self.findIndex((value2) => value2.id === value.id) === index,
              )
              .filter((report) =>
                (report.title as string)?.toLowerCase().includes(inputValue.toLowerCase()),
              ) as (Report & { hidden: boolean })[],
          ),
        500,
      )()
    }
  }, [inputValue])

  return (
    <Modal
      visible={open}
      onOk={onSubmit}
      onCancel={onCancelClick}
      title={t('share.reports.add')}
      className={classes.reportModalWrapper}
      noBodyPadding
      bodyStyle={{ paddingRight: '24px' }}
      okText={t('share.reports.add-board')}
      minWidth={'800px'}
      width={'60%'}
    >
      <Spin spinning={!reportCategories?.length}>
        <Form name="reports" onFinish={saveForm}>
          <Row gutter={[24, 24]}>
            <Col span={6}>
              <ChooseReportCategory reportCategories={reportCategories} setReports={onSetReports} />
            </Col>
            <Col className={classes.reportsCardsWrapper} span={18}>
              <ReportsMenu
                onSearch={setInputValue}
                reports={reports}
                onSelectionChanged={(newChosenReports) => {
                  setChosenReports({ ...chosenReports, ...newChosenReports })
                }}
                selection={chosenReports}
                searchValue={inputValue}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default React.memo(ReportsModal)
