import { RootState } from '..'

import { DEFAULT_HOTSPOT_ANALYTICS_FILTERS } from '@cozero/constants'
import { HotspotAnalysisViewDto } from '@cozero/dtos'
import { HotspotAnalysisFilterValues } from '@cozero/models'

export const selectHotspotAnalysisDateFilter = (state: RootState): [Date, Date] | undefined => {
  if (!state.actAnalytics.selectHotspotAnalysisView) {
    return undefined
  }
  return [
    state.actAnalytics.selectHotspotAnalysisView.startDate,
    state.actAnalytics.selectHotspotAnalysisView.endDate,
  ]
}

export const selectHotspotAnalysisFilters = (state: RootState): HotspotAnalysisFilterValues =>
  state.actAnalytics.selectHotspotAnalysisView?.filters ?? DEFAULT_HOTSPOT_ANALYTICS_FILTERS

export const selectHotspotAnalysisFiltersCount = (state: RootState): number => {
  if (!state.actAnalytics.selectHotspotAnalysisView) {
    return 0
  }
  return Object.values(state.actAnalytics.selectHotspotAnalysisView.filters).reduce(
    (acc, filter) => {
      return acc + filter.length
    },
    0,
  )
}

export const selectHotspotAnalysisExpandedRowKeys = (state: RootState): React.Key[] =>
  state.actAnalytics.expandedRowKeys

export const selectHotspotAnalysisView = (state: RootState): HotspotAnalysisViewDto | undefined =>
  state.actAnalytics?.selectHotspotAnalysisView
