import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { HotspotAnalysisViewDto } from '@cozero/dtos'

import { useAppSelector } from '@/redux'

import { selectHotspotAnalysisView } from '../selectors'
import { setHotspotAnalysisView } from '../slice'

type SetSelectedHotspotAnalysisViewParams = (view: HotspotAnalysisViewDto | undefined) => void

export const useSelectedHotspotAnalysisView = (): [
  HotspotAnalysisViewDto | undefined,
  SetSelectedHotspotAnalysisViewParams,
] => {
  const dispatch = useDispatch()
  const selectedHotspotAnalysisView = useAppSelector(selectHotspotAnalysisView)

  const setSelectedHotspotAnalysisView: SetSelectedHotspotAnalysisViewParams = useCallback(
    (view: HotspotAnalysisViewDto | undefined) => {
      if (!view) {
        return
      }

      dispatch(
        setHotspotAnalysisView({
          ...view,
          startDate: new Date(view.startDate),
          endDate: new Date(view.endDate),
        }),
      )
    },
    [],
  )

  return [selectedHotspotAnalysisView, setSelectedHotspotAnalysisView]
}
