/* eslint-disable */
import React from 'react'
import { useTranslation } from 'react-i18next'

import { message } from 'antd/es'

import { DownloadOutlined } from '@ant-design/icons'
import * as XLSX from 'xlsx'

import Button from '@/atoms/Button'

interface FormattingStrategy {
  formatWorkbook: (data: Record<string, any>[], workbook: XLSX.WorkBook) => void
  getFileName: (baseName: string) => string
}

function sortDataBySheetName(
  data: Record<string, Record<string, any>[]>,
): Record<string, Record<string, any>[]> {
  const keyFunc = (key: string): (string | number)[] => {
    return key.split('.').map((part) => (isNaN(Number(part)) ? part : parseInt(part)))
  }

  const sortedKeys = Object.keys(data).sort((a, b) => {
    const partsA = keyFunc(a)
    const partsB = keyFunc(b)

    for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
      if (partsA[i] < partsB[i]) {
        return -1
      }
      if (partsA[i] > partsB[i]) {
        return 1
      }
    }

    return partsA.length - partsB.length
  })

  return sortedKeys.reduce<Record<string, Record<string, any>[]>>((result, key) => {
    result[key] = data[key]
    return result
  }, {})
}

function formatSheetName(sheetName: string) {
  const MAX_SHEET_NAME_LENGTH = 31;
  return sheetName.length > MAX_SHEET_NAME_LENGTH ? sheetName.slice(0, MAX_SHEET_NAME_LENGTH - 3) + '...' : sheetName;
}
const isFirstSheetCell = (cell: Record<string, any>) => !cell.columnHeader && !cell.rowHeader

const csrdStrategy: FormattingStrategy = {
  formatWorkbook: (data: Record<string, any>[], workbook: XLSX.WorkBook) => {
    // Group data by sheetName
    const groupedBySheet = data.reduce((acc, item) => {
      if (!acc[item.sheetName]) {
        acc[item.sheetName] = []
      }
      acc[item.sheetName].push(item)
      return acc
    }, {} as Record<string, Record<string, any>[]>)

    const sortedGroupedBySheet = sortDataBySheetName(groupedBySheet)

    // Function to format a single worksheet
    const formatWorksheet = (sheetData: Record<string, any>[], sheetName: string) => {
      const worksheet = XLSX.utils.aoa_to_sheet([[]])

      // Sort data first by scope (groupValue), then by id
      const sortedData = [...sheetData].sort((a, b) => {
        const groupValueA = a.groupValue ?? ''
        const groupValueB = b.groupValue ?? ''

        if (groupValueA !== groupValueB) {
          return groupValueA.localeCompare(groupValueB)
        }
        return a.id - b.id
      })

      // Extract unique column keys and headers
      const columnKeyToColumnHeader = sortedData.reduce<Record<string, string>>((result, currentCell) => {
        const key = currentCell.columnKey as string
        if (key && !result[key]) {
          result[key] = currentCell.columnHeader || currentCell.columnKey
        }
        return result
      }, {})
      const columns = Object.entries(columnKeyToColumnHeader).map(([key, header]) => ({key, header}))

      
      // Add header row
      const firstCell = sortedData.find(isFirstSheetCell)
      const firstCellValue = (firstCell?.stringValue || firstCell?.numberValue) ?? ''
      XLSX.utils.sheet_add_aoa(worksheet, [[firstCellValue, ...columns.map(col => col.header)]], {
        origin: 'A1',
      })

      // Group data by rowKey
      const groupedData = sortedData.reduce((acc, item) => {
        if (isFirstSheetCell(item)) {
          return acc
        }

        if (!acc[item.rowKey]) {
          acc[item.rowKey] = {
            header: item.rowHeader,
            items: {},
            groupValue: item.groupValue,
            id: item.id,
          }
        }
        if (item.valueType === 'NUMBER') {
          acc[item.rowKey].items[item.columnKey] = item.numberValue !== null ? item.numberValue : ''
        } else {
          acc[item.rowKey].items[item.columnKey] = item.stringValue
        }
        return acc
      }, {} as Record<string, { header: string; items: Record<string, any>; groupValue: string | null; id: number }>)

      // Add data rows
      let rowIndex = 2 // Start from row 2 (after header)
      let currentGroup: string | null = null

      Object.entries(groupedData)
        .sort(([, a], [, b]) => {
          const groupValueA = a.groupValue ?? ''
          const groupValueB = b.groupValue ?? ''

          if (groupValueA !== groupValueB) {
            return groupValueA.localeCompare(groupValueB)
          }
          return a.id - b.id
        }) // Sort by id within groups
        .forEach(([rowKey, { header, items, groupValue }]) => {
          // Add group header if it's a new group
          if (groupValue !== currentGroup) {
            currentGroup = groupValue
            if (groupValue !== null) {
              XLSX.utils.sheet_add_aoa(worksheet, [[groupValue]], { origin: `A${rowIndex}` })
              // Style group header
              for (let col = 0; col <= columns.length; col++) {
                const cell = worksheet[XLSX.utils.encode_cell({ r: rowIndex - 1, c: col })]
                if (cell) {
                  cell.s = {
                    fill: { fgColor: { rgb: 'FFFFFF' } },
                    font: { bold: true },
                  }
                }
              }
              rowIndex++
            }
          }

          // Add data row
          const rowData = [header]
          columns.forEach((col) => {
            rowData.push(items[col.key] !== undefined ? items[col.key] : '')
          })

          XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: `A${rowIndex}` })

          rowIndex++
        })

      // Style header row
      for (let col = 0; col <= columns.length; col++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })]
        if (cell) {
          cell.s = { font: { bold: true }, alignment: { horizontal: 'center' } }
        }
      }

      // Auto-size columns
      const maxColWidths = columns.map((col) => col.header?.length)
      Object.values(groupedData).forEach(({ header, items }) => {
        maxColWidths[0] = Math.max(maxColWidths[0], header?.length ?? 0)
        columns.forEach((col, index) => {
          const value = items[col.key]?.toString() || ''
          maxColWidths[index + 1] = Math.max(maxColWidths[index + 1], value.length)
        })
      })
      worksheet['!cols'] = maxColWidths.map((width) => ({ wch: width + 2 })) // Add some padding

      return worksheet
    }

    // Create a worksheet for each unique sheetName
    Object.entries(sortedGroupedBySheet).forEach(([sheetName, sheetData]) => {
      const worksheet = formatWorksheet(sheetData, sheetName)
      XLSX.utils.book_append_sheet(workbook, worksheet, formatSheetName(sheetName))
    })
  },
  getFileName: (baseName: string) => `${baseName}_report`,
}

const formattingStrategies: Record<string, FormattingStrategy> = {
  'csrd-ghg-e1-6': csrdStrategy,
  'cdp-disclosure-2024': csrdStrategy,
}

interface ComputedReportsExcelDownloadButtonProps {
  jsonData: Record<string, any>[] | undefined
  fileName?: string
  disabled?: boolean
  strategyKey?: string
}

const ComputedReportsExcelDownloadButton: React.FC<ComputedReportsExcelDownloadButtonProps> = ({
  jsonData,
  fileName = 'report',
  disabled = false,
  strategyKey = 'csrd-ghg-e1-6',
}) => {
  const { t } = useTranslation('common')
  const strategy = formattingStrategies[strategyKey]

  const convertJsonToExcel = (data: Record<string, any>[]): XLSX.WorkBook => {
    if (!data || data.length === 0) {
      throw new Error('Input data is empty or undefined')
    }

    const workbook = XLSX.utils.book_new()
    strategy.formatWorkbook(data, workbook)

    return workbook
  }

  const downloadExcel = (): void => {
    if (disabled || !jsonData) {
      return
    }
    try {
      const workbook = convertJsonToExcel(jsonData)
      const fullFileName = strategy.getFileName(fileName)
      XLSX.writeFile(workbook, `${fullFileName}.xlsx`)
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        message.error(t('computed-reports.excel.error.generic'))
      }
    }
  }

  return (
    <Button
      onClick={downloadExcel}
      size="md"
      prefixIcon={<DownloadOutlined />}
      action={`download-${strategyKey}-excel`}
      analyticsProps={{ fileName, strategyKey }}
      disabled={disabled || !jsonData}
    >
      {t('computed-reports.form.download-report-button')}
    </Button>
  )
}

export default ComputedReportsExcelDownloadButton
