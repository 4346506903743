import { ComputedReportDataDto } from '@cozero/dtos'

export const formatComputedReportData = (data: ComputedReportDataDto[]): ComputedReportDataDto[] =>
  data.map((cell) => {
    if (cell.numberValue) {
      return {
        ...cell,
        numberValue: Number(cell.numberValue.toFixed(4)),
      }
    }
    return cell
  })
