import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Modal from '@/molecules/Modal'

import Text from '@/atoms/Text'

import { MODAL_SIZE_SM } from '@/styles/variables'

import classes from './HotspotAnalysisFilterConfirmationDialog.module.less'
import Image from './pins-confirmation-dialog.svg'

interface Props {
  show: boolean
  onCancel: () => void
  onConfirm: () => void
  onCreateNewView: () => void
}

export const HotspotAnalysisFilterConfirmationDialog = ({
  show,
  onCancel,
  onConfirm,
  onCreateNewView,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Modal
      variant="small"
      visible={show}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={t('act.analytics.hotspotAnalysis.modal.buttons.ok')}
      cancelText={t('act.analytics.hotspotAnalysis.modal.buttons.cancel')}
      width={MODAL_SIZE_SM}
    >
      <div className={classes.modalContent}>
        <img src={Image} />
        <Text className={classes.title} fontWeight="bold">
          {t('act.analytics.hotspotAnalysis.modal.title')}
        </Text>
        <div>
          <Trans
            t={t}
            i18nKey="act.analytics.hotspotAnalysis.modal.description"
            components={{
              1: <span className={classes.createNewViewCta} onClick={onCreateNewView} />,
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
