import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  HotspotAnalysisFilterKeys,
  HotspotAnalysisFilterValues,
  HotspotFiltersOptionsResult,
} from '@cozero/models'

type Params = {
  options: Partial<HotspotFiltersOptionsResult> & {
    [HotspotAnalysisFilterKeys.SUBCATEGORY]?: HotspotFiltersOptionsResult['subcategoryId']
    [HotspotAnalysisFilterKeys.BUSINESS_UNIT]?: HotspotFiltersOptionsResult['categoryId']
    [HotspotAnalysisFilterKeys.LOCATION]?: HotspotFiltersOptionsResult['categoryId']
    [HotspotAnalysisFilterKeys.TERRITORY]?: HotspotFiltersOptionsResult['territory']
  }
  localFilters: HotspotAnalysisFilterValues
  deletedLocations: {
    value: number
    label: string
  }[]
  deletedBusinessUnits: {
    value: number
    label: string
  }[]
}

type UseDeletedFiltersReturn = {
  showDeletedLocationsWarning: boolean
  showDeletedBusinessUnitsWarning: boolean
  showDeletedTerritoriesWarning: boolean
  setWarningVisibility: (
    type: 'locations' | 'territories' | 'businessUnits',
    visibility: boolean,
  ) => void
  businessUnitsWithoutDeletedFilters: number[]
  locationsWithoutDeletedFilters: number[]
  territoriesWithoutDeletedFilters: number[]
}

export const useDeletedFilters = ({
  options,
  localFilters,
  deletedBusinessUnits,
  deletedLocations,
}: Params): UseDeletedFiltersReturn => {
  const [showDeletedLocationsWarning, setShowDeletedLocationsWarning] = useState(false)
  const [showDeletedBusinessUnitsWarning, setShowDeletedBusinessUnitsWarning] = useState(false)
  const [showDeletedTerritoriesWarning, setShowDeletedTerritoriesWarning] = useState(false)

  const deletedLocationsIds = useMemo(
    () =>
      deletedLocations.filter(({ value }) =>
        localFilters[HotspotAnalysisFilterKeys.LOCATION].includes(value),
      ),
    [deletedLocations, localFilters],
  )

  const deletedBusinessUnitsIds = useMemo(
    () =>
      deletedBusinessUnits.filter(({ value }) =>
        localFilters[HotspotAnalysisFilterKeys.BUSINESS_UNIT].includes(value),
      ),
    [deletedBusinessUnits, localFilters],
  )

  const deletedTerritoriesIds = useMemo(
    () =>
      localFilters[HotspotAnalysisFilterKeys.TERRITORY].filter(
        (id) => !options?.[HotspotAnalysisFilterKeys.TERRITORY]?.find((t) => t.value === id),
      ),
    [localFilters, options],
  )

  useEffect(() => {
    if (deletedBusinessUnitsIds.length > 0) {
      setShowDeletedBusinessUnitsWarning(true)
    }

    if (deletedLocationsIds.length > 0) {
      setShowDeletedLocationsWarning(true)
    }

    if (deletedTerritoriesIds.length > 0) {
      setShowDeletedTerritoriesWarning(true)
    }
  }, [deletedBusinessUnits, deletedLocations, deletedTerritoriesIds])

  const setWarningVisibility = useCallback(
    (type: 'locations' | 'territories' | 'businessUnits', visibility: boolean): void => {
      switch (type) {
        case 'businessUnits':
          setShowDeletedBusinessUnitsWarning(visibility)
          break
        case 'locations':
          setShowDeletedLocationsWarning(visibility)
          break
        case 'territories':
          setShowDeletedTerritoriesWarning(visibility)
          break
        default:
          break
      }
    },
    [],
  )

  const businessUnitsWithoutDeletedFilters = localFilters[
    HotspotAnalysisFilterKeys.BUSINESS_UNIT
  ].filter((id) => !deletedBusinessUnits.find((bu) => bu.value === id))

  const locationsWithoutDeletedFilters = localFilters[HotspotAnalysisFilterKeys.LOCATION].filter(
    (id) => !deletedLocations.find((loc) => loc.value === id),
  )

  const territoriesWithoutDeletedFilters = localFilters[HotspotAnalysisFilterKeys.TERRITORY].filter(
    (id) => !deletedTerritoriesIds.includes(id),
  )

  return {
    showDeletedLocationsWarning,
    showDeletedBusinessUnitsWarning,
    showDeletedTerritoriesWarning,
    setWarningVisibility,
    businessUnitsWithoutDeletedFilters,
    locationsWithoutDeletedFilters,
    territoriesWithoutDeletedFilters,
  }
}
