/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Button, Col, Drawer, Form, Row, Space, message } from 'antd/es'

import { find } from 'lodash-es'

import { DEFAULT_HOTSPOT_ANALYTICS_FILTERS } from '@cozero/constants'
import { HotspotAnalysisFilterKeys, HotspotAnalysisFilterValues } from '@cozero/models'

import { SearchSelect } from '@/molecules/FiltersDrawer/SearchSelect'

import Alert from '@/atoms/Alert'
import Select from '@/atoms/Select'
import Text from '@/atoms/Text'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useAppSelector } from '@/redux'
import {
  useCreateHotspotViewFromFiltersMutation,
  useUpdateHotspotViewsFiltersMutation,
} from '@/redux/actAnalytics'
import { useSelectedHotspotAnalysisView } from '@/redux/actAnalytics/hooks/useSelectedHotspotAnalysisView.hook'
import { selectHotspotAnalysisFilters } from '@/redux/actAnalytics/selectors'
import { setHotspotAnalysisFilters, setHotspotAnalysisView } from '@/redux/actAnalytics/slice'

import { HotspotAnalysisFilterConfirmationDialog } from './HotspotAnalysisFilterConfirmationDialog'
import { useDeletedFilters } from './hooks/useDeletedFilters'
import { useFiltersOptions } from './hooks/useFiltersOptions'
import { useHotspotAnalysisContext } from './hooks/useHotspotAnalysisContext'
import { generateDeletedFiltersWarningText } from './utils/HotspotAnalysisFilterDrawer.utils'

type Props = {
  open: boolean
  onClose: () => void
}

const CONFLICT_UPDATE_HTTP_STATUS = 409

const DeletedFilterWarning = ({
  show,
  title,
  onClose,
}: {
  show: boolean
  title: string
  onClose: () => void
}): React.ReactElement | null => {
  if (!show || !title) {
    return null
  }

  return (
    <Alert type="warning" onClose={() => onClose()}>
      <Text size="lg">{title}</Text>
    </Alert>
  )
}

export const HotspotAnalysisFilterDrawer = ({ onClose, open }: Props): React.ReactElement => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const { t } = useTranslation()
  const { trackAction } = useMixpanel()
  const dispatch = useDispatch()

  const { type } = useHotspotAnalysisContext()

  const hotspotAnalysisFilters = useAppSelector(selectHotspotAnalysisFilters)
  const [localFilters, setLocalFilters] =
    useState<HotspotAnalysisFilterValues>(hotspotAnalysisFilters)
  const [selectedHotspotAnalysisView] = useSelectedHotspotAnalysisView()
  const [updateHotspotViewsFilters] = useUpdateHotspotViewsFiltersMutation()
  const [createHotspotViewFromFilters] = useCreateHotspotViewFromFiltersMutation()

  const {
    options,
    onBusinessUnitsSearch,
    businessUnitsLoading,
    onLocationsSearch,
    locationsLoading,
    deletedLocations,
    deletedBusinessUnits,
  } = useFiltersOptions({
    selectedCategoryIds: localFilters[HotspotAnalysisFilterKeys.CATEGORY],
  })

  const {
    showDeletedBusinessUnitsWarning,
    showDeletedLocationsWarning,
    showDeletedTerritoriesWarning,
    businessUnitsWithoutDeletedFilters,
    locationsWithoutDeletedFilters,
    territoriesWithoutDeletedFilters,
    setWarningVisibility,
  } = useDeletedFilters({
    options,
    localFilters,
    deletedBusinessUnits,
    deletedLocations,
  })

  const deletedLocationsMatched = deletedLocations.filter(({ value }) =>
    localFilters[HotspotAnalysisFilterKeys.LOCATION].includes(value),
  )

  const deletedBusinessUnitsMatched = deletedBusinessUnits.filter(({ value }) =>
    localFilters[HotspotAnalysisFilterKeys.BUSINESS_UNIT].includes(value),
  )

  const deletedTerritoriesMatched = localFilters[HotspotAnalysisFilterKeys.TERRITORY].filter(
    (id) => !options?.[HotspotAnalysisFilterKeys.TERRITORY]?.find((t) => t.value === id),
  )

  const onDeletedLocationsWarningClose = useCallback(() => {
    setLocalFilters((prev) => ({
      ...prev,
      [HotspotAnalysisFilterKeys.LOCATION]: prev[HotspotAnalysisFilterKeys.LOCATION].filter(
        (id) => !deletedLocationsMatched.map((l) => l.value).includes(id),
      ),
    }))
    setWarningVisibility('locations', false)
    trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'filters-deleted')
  }, [setLocalFilters, deletedLocationsMatched, setWarningVisibility, trackAction])

  const onDeletedBusinessUnitsWarningClose = useCallback(() => {
    setLocalFilters((prev) => ({
      ...prev,
      [HotspotAnalysisFilterKeys.BUSINESS_UNIT]: prev[
        HotspotAnalysisFilterKeys.BUSINESS_UNIT
      ].filter((id) => !deletedBusinessUnitsMatched.map((l) => l.value).includes(id)),
    }))
    setWarningVisibility('businessUnits', false)
    trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'filters-deleted')
  }, [setLocalFilters, deletedBusinessUnitsMatched, setWarningVisibility, trackAction])

  const onDeletedTerritoriesWarningClose = useCallback(() => {
    setLocalFilters((prev) => ({
      ...prev,
      [HotspotAnalysisFilterKeys.TERRITORY]: prev[HotspotAnalysisFilterKeys.TERRITORY].filter(
        (id) => !deletedTerritoriesMatched.includes(id),
      ),
    }))
    setWarningVisibility('territories', false)
    trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'filters-deleted')
  }, [setLocalFilters, deletedTerritoriesMatched, setWarningVisibility, trackAction])

  const saveFilters = useCallback(
    ({ forceUpdate = false }: { forceUpdate: boolean }) => {
      if (selectedHotspotAnalysisView?.id) {
        updateHotspotViewsFilters({
          id: selectedHotspotAnalysisView.id,
          forceUpdate,
          data: {
            filters: localFilters,
          },
          type,
        })
          .unwrap()
          .then(() => {
            dispatch(setHotspotAnalysisFilters(localFilters))
            trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'filters-updated')
          })
          .catch(({ status }) => {
            if (status === CONFLICT_UPDATE_HTTP_STATUS) {
              setShowConfirmationDialog(true)
              return
            }

            message.error(t('act.analytics.hotspotAnalysis.filters.updateError'))
          })
      }
      onClose()
    },
    [
      localFilters,
      selectedHotspotAnalysisView,
      onClose,
      trackAction,
      dispatch,
      updateHotspotViewsFilters,
      t,
      type,
    ],
  )

  const resetFilters = useCallback(() => {
    setLocalFilters(DEFAULT_HOTSPOT_ANALYTICS_FILTERS)
  }, [])

  const handleSelectChange = useCallback(
    (newValues: number[] | string[], key: HotspotAnalysisFilterKeys): void => {
      setLocalFilters((prev) => ({ ...prev, [key]: newValues }))
    },
    [],
  )

  const handleCreateNewView = useCallback(async () => {
    const newView = await createHotspotViewFromFilters({
      filters: localFilters,
      viewId: selectedHotspotAnalysisView?.id as number,
    }).unwrap()

    setLocalFilters(hotspotAnalysisFilters)

    dispatch(setHotspotAnalysisView(newView))
    onClose()
    trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'view-added-from-filters-dialog')
  }, [
    hotspotAnalysisFilters,
    dispatch,
    onClose,
    trackAction,
    createHotspotViewFromFilters,
    localFilters,
    selectedHotspotAnalysisView,
  ])

  const handleDrawerClose = useCallback(() => {
    onClose()
    setLocalFilters(hotspotAnalysisFilters)
  }, [hotspotAnalysisFilters, onClose])

  const handleDialogCancel = useCallback(() => {
    setShowConfirmationDialog(false)
    setLocalFilters(hotspotAnalysisFilters)
  }, [hotspotAnalysisFilters])

  const handleDialogConfirm = useCallback(() => {
    setShowConfirmationDialog(false)
    saveFilters({ forceUpdate: true })
  }, [saveFilters])

  const handleCreateNewViewClick = useCallback(() => {
    setShowConfirmationDialog(false)
    handleCreateNewView()
  }, [handleCreateNewView])

  return (
    <>
      <HotspotAnalysisFilterConfirmationDialog
        show={showConfirmationDialog}
        onCancel={handleDialogCancel}
        onCreateNewView={handleCreateNewViewClick}
        onConfirm={handleDialogConfirm}
      />
      <Drawer
        title={t('act.analytics.hotspotAnalysis.filters.title')}
        onClose={handleDrawerClose}
        open={open}
        footer={
          <Space>
            <Button onClick={resetFilters} data-cy="hotspot-view-filters-clear">
              {t('log.filter.clear')}
            </Button>

            <Button
              type="primary"
              onClick={() => saveFilters({ forceUpdate: false })}
              disabled={!selectedHotspotAnalysisView?.id}
              data-cy="hotspot-view-filters-apply"
            >
              {t('log.filter.save')}
            </Button>
          </Space>
        }
        footerStyle={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <Form layout="vertical">
          <Row gutter={16} key={HotspotAnalysisFilterKeys.SCOPE}>
            <Col span={24}>
              <Form.Item
                label={t('act.analytics.hotspotAnalysis.filters.scopeCategoryNumber.label')}
              >
                <Select
                  placeholder={t('act.analytics.hotspotAnalysis.filters.selectPlaceholder')}
                  mode="multiple"
                  size="large"
                  value={localFilters[HotspotAnalysisFilterKeys.SCOPE]}
                  options={options?.[HotspotAnalysisFilterKeys.SCOPE]}
                  optionFilterProp="label"
                  onChange={(value) => {
                    handleSelectChange(value, HotspotAnalysisFilterKeys.SCOPE)
                  }}
                  data-cy="hotspot-view-filters-scope"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={t('act.analytics.hotspotAnalysis.filters.logCategory.label')}>
                <Select
                  placeholder={t('act.analytics.hotspotAnalysis.filters.selectPlaceholder')}
                  mode="multiple"
                  size="large"
                  value={localFilters[HotspotAnalysisFilterKeys.CATEGORY]}
                  options={options?.[HotspotAnalysisFilterKeys.CATEGORY]}
                  optionFilterProp="label"
                  onChange={(value) => {
                    handleSelectChange(value, HotspotAnalysisFilterKeys.CATEGORY)

                    // Emoty subcategories if no category is selected
                    if (!value.length) {
                      handleSelectChange([], HotspotAnalysisFilterKeys.SUBCATEGORY)
                      return
                    }

                    // Remove selected subcategories that doesn't match anymore the options defined by the selected categories
                    handleSelectChange(
                      localFilters.subcategoryId.filter((id) =>
                        value.includes(
                          find(options[HotspotAnalysisFilterKeys.SUBCATEGORY], { value: id })
                            ?.categoryId,
                        ),
                      ),
                      HotspotAnalysisFilterKeys.SUBCATEGORY,
                    )
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={t('act.analytics.hotspotAnalysis.filters.logSubcategory.label')}>
                <Select
                  placeholder={t('act.analytics.hotspotAnalysis.filters.selectPlaceholder')}
                  disabled={!localFilters.categoryId.length}
                  mode="multiple"
                  size="large"
                  value={localFilters[HotspotAnalysisFilterKeys.SUBCATEGORY]}
                  options={options?.[HotspotAnalysisFilterKeys.SUBCATEGORY]}
                  optionFilterProp="label"
                  onChange={(value) => {
                    handleSelectChange(value, HotspotAnalysisFilterKeys.SUBCATEGORY)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={t('act.analytics.hotspotAnalysis.filters.activityDataSource.label')}
              >
                <Select
                  placeholder={t('act.analytics.hotspotAnalysis.filters.selectPlaceholder')}
                  mode="multiple"
                  size="large"
                  value={localFilters[HotspotAnalysisFilterKeys.ACTIVITY_DATA_SOURCE]}
                  options={options?.[HotspotAnalysisFilterKeys.ACTIVITY_DATA_SOURCE]}
                  optionFilterProp="label"
                  onChange={(value) => {
                    handleSelectChange(value, HotspotAnalysisFilterKeys.ACTIVITY_DATA_SOURCE)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={t('act.analytics.hotspotAnalysis.filters.hotspotAssessment.label')}>
                <Select
                  placeholder={t('act.analytics.hotspotAnalysis.filters.selectPlaceholder')}
                  mode="multiple"
                  size="large"
                  value={localFilters[HotspotAnalysisFilterKeys.HOTSPOT]}
                  options={options?.[HotspotAnalysisFilterKeys.HOTSPOT]}
                  optionFilterProp="label"
                  onChange={(value) => {
                    handleSelectChange(value, HotspotAnalysisFilterKeys.HOTSPOT)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={t('act.analytics.hotspotAnalysis.filters.businessUnit.label')}>
                <SearchSelect
                  multipleAllowed
                  size="large"
                  value={businessUnitsWithoutDeletedFilters}
                  optionFilterProp="label"
                  onSearch={onBusinessUnitsSearch}
                  loading={businessUnitsLoading}
                  options={options?.[HotspotAnalysisFilterKeys.BUSINESS_UNIT]}
                  onChange={(value) => {
                    handleSelectChange(value, HotspotAnalysisFilterKeys.BUSINESS_UNIT)
                  }}
                />
              </Form.Item>

              <DeletedFilterWarning
                title={generateDeletedFiltersWarningText({
                  filterType: 'businessUnits',
                  deletedFilters: deletedBusinessUnitsMatched,
                })}
                show={showDeletedBusinessUnitsWarning}
                onClose={() => onDeletedBusinessUnitsWarningClose()}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={t('act.analytics.hotspotAnalysis.filters.location.label')}>
                <SearchSelect
                  multipleAllowed
                  size="large"
                  value={locationsWithoutDeletedFilters}
                  optionFilterProp="label"
                  onSearch={onLocationsSearch}
                  loading={locationsLoading}
                  options={options?.[HotspotAnalysisFilterKeys.LOCATION]}
                  onChange={(value) => {
                    handleSelectChange(value, HotspotAnalysisFilterKeys.LOCATION)
                  }}
                />
              </Form.Item>

              <DeletedFilterWarning
                show={showDeletedLocationsWarning}
                title={generateDeletedFiltersWarningText({
                  filterType: 'locations',
                  deletedFilters: deletedLocationsMatched,
                })}
                onClose={() => onDeletedLocationsWarningClose()}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={t('act.analytics.hotspotAnalysis.filters.territory.label')}>
                <Select
                  placeholder={t('act.analytics.hotspotAnalysis.filters.selectPlaceholder')}
                  mode="multiple"
                  size="large"
                  value={territoriesWithoutDeletedFilters}
                  options={options?.[HotspotAnalysisFilterKeys.TERRITORY]}
                  optionFilterProp="label"
                  onChange={(value) => {
                    handleSelectChange(value, HotspotAnalysisFilterKeys.TERRITORY)
                  }}
                />
              </Form.Item>
              <DeletedFilterWarning
                show={showDeletedTerritoriesWarning}
                title={generateDeletedFiltersWarningText({
                  filterType: 'territories',
                  deletedFilters: deletedTerritoriesMatched,
                })}
                onClose={() => onDeletedTerritoriesWarningClose()}
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  )
}
