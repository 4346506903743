import React, { useMemo } from 'react'
import { useParams } from 'react-router'

import { Skeleton } from 'antd/es'

import { useGetComputedReportTypesQuery } from '@/redux/computedReports'

const ComputedReportCrumb = (): JSX.Element => {
  const { key } = useParams()

  const { data: computedReportTypes = [], isLoading } = useGetComputedReportTypesQuery()
  const computedReportTypeName = useMemo(
    () => computedReportTypes.find((type) => type.key === key)?.name,
    [key, computedReportTypes],
  )

  if (isLoading) {
    return <Skeleton.Button active style={{ maxHeight: 20, width: 30 }} />
  }

  return <>{computedReportTypeName}</>
}

export default ComputedReportCrumb
