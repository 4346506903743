/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { TabsProps } from 'antd'
import { Tabs } from 'antd'

import classNames from 'classnames'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useAppDispatch } from '@/redux'
import {
  useCreateHotspotViewsMutation,
  useDeleteHotspotViewsMutation,
  useDuplicateHotspotViewsMutation,
  useGetHotspotViewsQuery,
} from '@/redux/actAnalytics'
import { useSelectedHotspotAnalysisView } from '@/redux/actAnalytics/hooks/useSelectedHotspotAnalysisView.hook'
import { resetHotspotExpandedRowKeys } from '@/redux/actAnalytics/slice'

import { HotspotAnalysisView } from '../HotspotAnalysisView'
import { useHotspotAnalysisContext } from '../hooks/useHotspotAnalysisContext'

import { HotspotAnalysisMainDropdown } from './HotspotAnalysisMainDropdown'
import classes from './HotspotAnalysisTabsWrapper.module.less'

interface Props {
  disabled?: boolean
}

const AddViewButton = ({ disabled = false }: Props): React.ReactElement => {
  const [createHotspotViewsQuery] = useCreateHotspotViewsMutation()
  const [, setSelectedHotspotAnalysisView] = useSelectedHotspotAnalysisView()
  const { type } = useHotspotAnalysisContext()
  const { t } = useTranslation()
  const { trackAction } = useMixpanel()

  const addView = useCallback(async (): Promise<void> => {
    if (disabled) {
      return
    }

    trackAction(AnalyticsCategories.ACT_TRACKING_CATEGORY, 'view-added')
    const response = await createHotspotViewsQuery({
      name: t('act.analytics.hotspotAnalysis.views.new'),
      pins: [],
      type,
    })
    if ('data' in response) {
      setSelectedHotspotAnalysisView(response.data)
    }
  }, [disabled, trackAction, createHotspotViewsQuery, t, type, setSelectedHotspotAnalysisView])

  return (
    <span
      className={classNames(classes.addViewButton, {
        [classes.disabled]: disabled,
      })}
      onClick={() => addView()}
      data-cy="hotspot-view-add"
    >
      {t('act.analytics.hotspotAnalysis.views.add')}
    </span>
  )
}

export const HotspotAnalysisTabsWrapper = (): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { type } = useHotspotAnalysisContext()

  const { data: views = [], isFetching } = useGetHotspotViewsQuery({ type })
  const [duplicateViewMutation] = useDuplicateHotspotViewsMutation()
  const [deleteHotspotViewsMutation] = useDeleteHotspotViewsMutation()
  const [selectedHotspotAnalysisView, setSelectedHotspotAnalysisView] =
    useSelectedHotspotAnalysisView()

  const defaultViewIds = useMemo(
    () => views.filter((view) => view.isDefault).map(({ id }) => id),
    [views],
  )

  const activeKey = selectedHotspotAnalysisView?.id?.toString()

  useEffect(() => {
    const isSelected =
      !!selectedHotspotAnalysisView &&
      views.find((view) => view.id === selectedHotspotAnalysisView.id)

    if (!isSelected) {
      const defaultView = views.find((view) => view.isDefault)

      if (defaultView) {
        setSelectedHotspotAnalysisView(defaultView)
      }
    }
  }, [selectedHotspotAnalysisView, setSelectedHotspotAnalysisView, views])

  const onDuplicate = useCallback(
    async (viewId: number, viewName: string): Promise<void> => {
      const view = await duplicateViewMutation({
        data: {
          name: t('act.analytics.hotspotAnalysis.views.duplicate-name', {
            name: viewName,
          }),
        },
        id: viewId,
      }).unwrap()
      setSelectedHotspotAnalysisView(view)
    },
    [duplicateViewMutation, setSelectedHotspotAnalysisView, t],
  )

  const onDelete = useCallback(
    (viewId: number): void => {
      const deletedViewIndex = views.findIndex((v) => v.id === viewId)
      const newSelectedView =
        deletedViewIndex === 0 && views.length > 2 ? views[1] : views[deletedViewIndex - 1]

      deleteHotspotViewsMutation({ id: viewId, type })
      setSelectedHotspotAnalysisView(newSelectedView)
    },
    [deleteHotspotViewsMutation, setSelectedHotspotAnalysisView, type, views],
  )

  const onTabClick = useCallback(
    (key: string): void => {
      dispatch(resetHotspotExpandedRowKeys())
      const selectedView = views.find((view) => view.id === Number(key))
      setSelectedHotspotAnalysisView(selectedView)
    },
    [dispatch, setSelectedHotspotAnalysisView, views],
  )

  const renderViewTab: TabsProps['renderTabBar'] = (props, DefaultTabBar): React.ReactElement => {
    return (
      <DefaultTabBar {...props}>
        {(node) => {
          const viewId = Number(node?.key)
          const viewName = node.props?.children?.[0].props.children
          const activeView = props.activeKey === viewId.toString()

          if (isFetching) {
            return <div {...node.props}> {node.props.children}</div>
          }

          return (
            <HotspotAnalysisMainDropdown
              viewId={viewId}
              onClickDelete={() => onDelete(viewId)}
              onClickDuplicate={() => onDuplicate(viewId, viewName)}
              includeDelete={!defaultViewIds.includes(viewId)}
            >
              <div {...node.props} data-cy={`hotspot-view-tab${activeView ? '-active' : ''}`}>
                {node.props.children}
              </div>
            </HotspotAnalysisMainDropdown>
          )
        }}
      </DefaultTabBar>
    )
  }

  return (
    <Tabs
      className={classes.tableWrapper}
      defaultActiveKey={views?.[0]?.id?.toString()}
      tabBarExtraContent={<AddViewButton disabled={isFetching} />}
      renderTabBar={renderViewTab}
      moreIcon={false}
      onTabClick={onTabClick}
      activeKey={activeKey?.toString()}
      items={views.map((view) => ({
        key: view.id.toString(),
        label: view.name,
        children: (
          <HotspotAnalysisView
            data-cy={`hotspot-tab-item${activeKey === view.id.toString() ? '-active' : ''}`}
            isLoading={isFetching}
            viewId={view.id}
          />
        ),
      }))}
    />
  )
}
