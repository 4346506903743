/* eslint react-hooks/exhaustive-deps: 2 */

import { useCallback } from 'react'

import { CreateHotspotAnalysisViewPinDto } from '@cozero/dtos'

import { usePinMutation, useUnpinMutation } from '@/redux/actAnalytics/hotspotPinsApi'

type UsePinEmissionRowsProps = {
  viewId: number
}

type UsePinEmissionRowsReturn = {
  addPinnedRow: (row: CreateHotspotAnalysisViewPinDto) => void
  removePinnedRow: ({ pinId, viewId }: { pinId: number; viewId: number }) => void
}

export const usePinEmissionRows = ({
  viewId,
}: UsePinEmissionRowsProps): UsePinEmissionRowsReturn => {
  const [createPin] = usePinMutation()
  const [removePin] = useUnpinMutation()

  const addPinnedRow = useCallback(
    (row: CreateHotspotAnalysisViewPinDto) => {
      createPin({ ...row, viewId })
    },
    [createPin, viewId],
  )

  const removePinnedRow = useCallback(
    ({ pinId, viewId }: { pinId: number; viewId: number }) => {
      removePin({ pinId, viewId })
    },
    [removePin],
  )

  return {
    addPinnedRow,
    removePinnedRow,
  }
}
