import React, { HTMLProps, ReactElement, ReactNode } from 'react'

import classNames from 'classnames'

import Tooltip, { TooltipProps } from '@/atoms/Tooltip'

import classes from './Label.module.less'

export interface LabelProps extends Omit<HTMLProps<HTMLLabelElement>, 'size'> {
  /**
   * The content of the label
   */
  children: ReactNode
  /**
   * The size of the label
   * @defaultValue 'sm'
   */
  size?: 'xs' | 'sm' | 'md'
  /**
   * The text color of the label
   * @defaultValue 'gray'
   */
  color?: 'gray' | 'black'
  /**
   * Custom classnames to add to the label
   */
  className?: string
  /**
   * The title of the label, shown on hover
   */
  title?: string
  /**
   * A tooltip appended at the end of the label content
   */
  tooltip?: TooltipProps
}

/**
 * A basic text label
 */
const Label = ({
  size = 'sm',
  color = 'gray',
  children,
  className,
  tooltip,
  ...rest
}: LabelProps): ReactElement => {
  return (
    <label
      className={classNames(className, classes.label, classes[size], classes[color])}
      {...rest}
    >
      <span className={classes.noWrap}>
        <span className={classes.wrap}>{children}</span>

        {tooltip && (
          <>
            {/* This non breakable space makes sure the tooltip won't be isolated from the last word */}
            &nbsp;
            <Tooltip
              className={classes.tooltip}
              {...tooltip}
              showArrow={tooltip.showArrow ?? true}
            />
          </>
        )}
      </span>
    </label>
  )
}

export default Label
