/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'
import { useSelector } from 'react-redux'

import { uniqBy } from 'lodash-es'

import { selectSelectedBusinessUnit, useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'

import { SearchHookProps } from './types'
import { checkIfSearchMatchesItemProperty } from './utils'

export const useBusinessUnitSearch = (): SearchHookProps => {
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const { data, isFetching } = useGetActiveBusinessUnitsQuery(
    {
      businessUnitScopeId: selectedBusinessUnit?.id ?? -1,
    },
    {
      skip: !selectSelectedBusinessUnit,
    },
  )
  const [search, setSearch] = React.useState<string>('')

  const options = React.useMemo(
    () =>
      uniqBy(data, 'id')
        .filter((item) => checkIfSearchMatchesItemProperty(item, 'title', search))
        .map((item) => ({
          value: item.id,
          label: item.title,
        })),
    [data, search],
  )

  return {
    onSearch: setSearch,
    options,
    isFetching,
  }
}
