import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Col, Divider, Row, message } from 'antd/es'
import { Store } from 'antd/es/form/interface'

import debounce from 'lodash/debounce'
import omit from 'lodash/omit'

import { PresetFilterDto } from '@cozero/dtos'
import {
  ConfigFilter,
  Board as IBoard,
  Organization,
  PageFilter,
  PageType,
  ReportCategory,
  ReportToBoard,
} from '@cozero/models'
import { routes } from '@cozero/utils'

import CustomBoard from '@/organisms/CustomBoard'
import DashboardHeader from '@/organisms/DashboardHeader'

import FiltersDrawer from '@/molecules/FiltersDrawer'

import LoadingSpinner from '@/atoms/LoadingSpinner'

import { useAppContext } from '@/contexts/app'
import { useBoardsContext } from '@/contexts/boards'
import { useFiltersContext } from '@/contexts/filters'
import { useLogContext } from '@/contexts/log'
import useTerritories from '@/hooks/useTerritories'
import { useAppDispatch, useAppSelector } from '@/redux'
import { getFeaturesAllowed, selectUser } from '@/redux/auth'
import { selectSelectedBusinessUnit, useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'
import { useGetCategoriesQuery } from '@/redux/categories'
import { useGetActiveLocationsQuery } from '@/redux/locations'
import { useGetFullLogRangeQuery, useHasLogEntriesQuery } from '@/redux/logs'
import { selectSteps, setSteps } from '@/redux/onboarding'
import { useGetOrganizationUsersFilteredByBusinessUnitQuery } from '@/redux/organizations'
import {
  useDeletePresetFilterMutation,
  useGetPresetFiltersQuery,
  useSavePresetFilterMutation,
  useUpdatePresetFilterMutation,
} from '@/redux/presetFilters'
import { createFilterOptions } from '@/utils/filters'

import ReportsModal from '../Share/Modals/ReportsModal'

import EmptyState, { EmptyStateStatus } from './EmptyState'
import classes from './Home.module.less'

enum FilterKey {
  CATEGORY = 'categoryId',
  LOCATION = 'locationId',
  BUSINESS_UNIT = 'businessUnit',
  RESPONSIBLE = 'ownerId',
}

const Home = (): JSX.Element => {
  const { t } = useTranslation('common')
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const featuresAllowed = useAppSelector(getFeaturesAllowed)
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const steps = useAppSelector(selectSteps)
  const { loading, products, getProducts, suppliers, getSuppliers } = useAppContext()

  const {
    getTags,
    getCustomers,
    customers,
    tags,
    getHasProductLogEntries,
    hasProductLogEntries,
    getProductTags,
    productTags,
  } = useLogContext()
  const { filters, saveFilters } = useFiltersContext()
  const isInitialized = useRef(false)
  const { data: businessUnits = [] } = useGetActiveBusinessUnitsQuery(
    { businessUnitScopeId: selectedBusinessUnit?.id ?? -1 },
    { skip: !selectedBusinessUnit },
  )
  const { data: presetFilters, isLoading: isPresetFilterLoading } = useGetPresetFiltersQuery()
  const [updatePresetFilter] = useUpdatePresetFilterMutation()
  const [savePresetFilter] = useSavePresetFilterMutation()
  const [deletePresetFilter] = useDeletePresetFilterMutation()
  const { data: locations } = useGetActiveLocationsQuery(
    { selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1 },
    { skip: !selectedBusinessUnit },
  )
  const { data: logRange } = useGetFullLogRangeQuery(
    { selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1 },
    { skip: !selectedBusinessUnit },
  )
  const { data: categories } = useGetCategoriesQuery()
  const { data: users } = useGetOrganizationUsersFilteredByBusinessUnitQuery(
    { businessUnitId: selectedBusinessUnit?.id },
    { skip: !selectedBusinessUnit?.id },
  )
  const { data: hasLogEntries, isLoading: isLoadingHasLogEntries } = useHasLogEntriesQuery(
    { businessUnitId: selectedBusinessUnit?.id },
    { skip: !selectedBusinessUnit },
  )
  const { territories } = useTerritories()
  const [filterOptions, setFilterOptions] = useState<PageFilter[]>(
    createFilterOptions({
      type: 'location',
      categories,
      users,
      businessUnits,
      locations,
      products,
      customers,
      suppliers,
      tags,
      t,
      featuresAllowed,
      territories,
      productTags,
      shownOptions: [
        'locations',
        'businessUnits',
        'owners',
        'categories',
        'descriptions',
        'customers',
        'suppliers',
        'tags',
        'territories',
        'productTags',
      ],
      page: 'reports',
    }) ?? [],
  )

  const {
    boards,
    loadingGetBoard,
    loadingBoards,
    getBoards,
    getBoard,
    searchReportCategories,
    updateReportToBoard,
    editReportsOfBoard,
    selectedBoard,
    setSelectedBoard,
  } = useBoardsContext()
  const navigate = useNavigate()
  const [openReportMenu, setOpenReportMenu] = useState<boolean>(false)
  const [reportCategories, setReportCategories] = useState<ReportCategory[]>([])
  const [emptyStatus, setEmptyStatus] = useState<EmptyStateStatus>(EmptyStateStatus.NONE)
  const [drawerOpened, setDrawerOpened] = useState(false)
  const [dashboardPresetFilter, setDashboardPresetFilter] = useState<PresetFilterDto | null>(null)

  const hasFinishedStep = useMemo(
    () =>
      steps?.length === 0 ||
      steps?.filter((step) => step.onboardingStep.key === 'logs' && step.completed)?.length === 1,
    [steps],
  )

  const hasDashboardFilter = useMemo(() => {
    if (Array.isArray(presetFilters) && presetFilters.length > 0) {
      return presetFilters.some(
        (presetFilter) => presetFilter.pageType === PageType.DASHBOARD && !presetFilter.reportKey,
      )
    }
    return false
  }, [presetFilters])

  async function onSearch(filters: PageFilter[]): Promise<void> {
    if (!isInitialized.current) {
      return
    }

    if (dashboardPresetFilter) {
      saveFilters(filters)
      await updatePresetFilter({
        ...dashboardPresetFilter,
        filters: filters?.map(
          (obj) =>
            ({
              ...omit(obj, ['id', 'options', 'conditions']),
              conditions: [],
            } as PageFilter),
        ) as ConfigFilter[],
      })
    } else if (!dashboardPresetFilter && !hasDashboardFilter) {
      saveFilters(filters)
      const newDashboardFilter = await savePresetFilter({
        pageType: PageType.DASHBOARD,
        reportKey: undefined,
        filters: filters?.map(
          (obj) =>
            ({
              ...omit(obj, ['id', 'options', 'conditions']),
              conditions: [],
            } as PageFilter),
        ) as ConfigFilter[],
      }).unwrap()

      setDashboardPresetFilter(newDashboardFilter)
    }

    if (dashboardPresetFilter && filters.length === 0) {
      saveFilters(filters)
      await deletePresetFilter(dashboardPresetFilter.id)
      setDashboardPresetFilter(null)
    }
  }

  const isLoadingContent = useMemo(
    () =>
      loading ||
      loadingGetBoard ||
      loadingBoards ||
      isLoadingHasLogEntries ||
      isPresetFilterLoading,
    [loading, loadingGetBoard, loadingBoards, isLoadingHasLogEntries, isPresetFilterLoading],
  )

  useEffect(() => {
    setFilterOptions(
      createFilterOptions({
        type: 'location',
        categories,
        users,
        businessUnits,
        locations,
        products,
        tags,
        customers,
        suppliers,
        t,
        productTags,
        territories,
        featuresAllowed,
        shownOptions: [
          'locations',
          'businessUnits',
          'owners',
          'categories',
          'descriptions',
          'products',
          'customers',
          'suppliers',
          'tags',
          'territories',
          'productTags',
        ],
        page: 'reports',
      }) ?? [],
    )
  }, [
    categories,
    users,
    businessUnits,
    locations,
    selectedBusinessUnit?.key,
    products,
    tags,
    customers,
    suppliers,
    territories,
  ])

  const updateBoard = (board: IBoard): void => {
    setSelectedBoard(board)
  }

  const editReports = useCallback(
    async (reports: Store, boardId: number): Promise<void> => {
      await editReportsOfBoard(reports, boardId)
      if (selectedBoard) {
        await getBoard({ boardId })
      } else {
        message.error(t('share.reports.errors.add'))
      }
    },
    [selectedBoard, filters],
  )

  const editReportToBoard = async (
    board: IBoard,
    reportId: number,
    values: Partial<ReportToBoard>,
  ): Promise<void> => {
    if (board) {
      await updateReportToBoard(board, reportId, values)
    } else {
      message.error(t('share.reports.errors.no-board'))
    }
  }

  const fetchReportCategories = async (search: string): Promise<void> => {
    const data = await searchReportCategories(search)
    setReportCategories(data)
  }

  async function fetchData(): Promise<void> {
    await getProducts(false)
    await getSuppliers()
    await getTags()
    await getProductTags()
    await getCustomers()
    await fetchReportCategories('')
    await getHasProductLogEntries()
  }

  useEffect(() => {
    const pricing = (user?.organization as Organization)?.pricing?.key
    if (pricing === 'supplier') {
      navigate(routes.log['customer-requests'])
    }
  }, [])

  useEffect(() => {
    if (!hasLogEntries && !hasProductLogEntries) {
      setEmptyStatus(EmptyStateStatus.NO_LOGS)
    } else if (
      !selectedBoard ||
      !selectedBoard.reportToBoards ||
      (selectedBoard.reportToBoards.length <= 0 && !loadingGetBoard)
    ) {
      setEmptyStatus(EmptyStateStatus.EMPTY_BOARD)
    } else {
      setEmptyStatus(EmptyStateStatus.NONE)
    }
  }, [
    steps,
    hasFinishedStep,
    selectedBoard,
    loadingGetBoard,
    isLoadingContent,
    hasProductLogEntries,
  ])

  useEffect(() => {
    if (user?.onboardingSteps) {
      dispatch(setSteps(user.onboardingSteps))
    }
  }, [user?.onboardingSteps])

  useEffect(() => {
    if (boards.length <= 0) {
      getBoards()
    }
  }, [boards])

  useEffect(() => {
    if (boards && selectedBusinessUnit && boards.length > 0) {
      const board = boards.find((board) => board.type === PageType.DASHBOARD)
      if (board) {
        getBoard({ boardId: board.id })
      }
    }
  }, [boards, selectedBusinessUnit?.key])

  useEffect(() => {
    if (selectedBusinessUnit?.key) {
      fetchData()
    }
  }, [selectedBusinessUnit?.key])

  useEffect(() => {
    if (Array.isArray(presetFilters) && presetFilters.length > 0) {
      const dashboardFilter = presetFilters.find(
        (presetFilter) => presetFilter.pageType === PageType.DASHBOARD && !presetFilter.reportKey,
      )

      if (dashboardFilter) {
        setDashboardPresetFilter(dashboardFilter)
        saveFilters(dashboardFilter.filters as PageFilter[])
      }
    }

    isInitialized.current = true
  }, [presetFilters, saveFilters])

  return (
    <>
      <>
        <DashboardHeader
          disableAddReport={user?.role?.type === 'viewer'}
          filters={filters}
          selectedBoard={selectedBoard}
          loading={!reportCategories}
          openDrawer={() => setDrawerOpened(true)}
          openReportMenu={() => setOpenReportMenu(true)}
          onFilterSearch={onSearch}
          logRange={logRange}
          featuresAllowed={featuresAllowed}
          filterOptions={
            [...filterOptions] as (PageFilter & {
              options: {
                key?: string
                value: string
                label: string
              }[]
            })[]
          }
        />
      </>
      <>
        {emptyStatus === EmptyStateStatus.NONE && selectedBoard?.type === PageType.DASHBOARD ? (
          <>
            <Divider type="horizontal" className={classes.divider} />
            <Row>
              <Col span={24}>
                <CustomBoard
                  updateBoard={updateBoard}
                  updateReportToBoard={editReportToBoard}
                  editable
                  includeChildrenBUsData
                />
              </Col>
            </Row>

            <FiltersDrawer
              search={debounce(onSearch, 500)}
              pageType={PageType.DASHBOARD}
              filters={[...filters] as (PageFilter & { key: FilterKey })[]}
              visible={drawerOpened}
              filterOptions={
                [...filterOptions] as (PageFilter & {
                  options: {
                    key?: string
                    value: string
                    label: string
                  }[]
                })[]
              }
              onClose={() => setDrawerOpened(false)}
              featuresAllowed={featuresAllowed}
            />
          </>
        ) : (
          <>
            {isLoadingContent ? (
              <LoadingSpinner
                className={classes.spinner}
                title={t('share.reports.loading-plural')}
              />
            ) : (
              <EmptyState
                status={emptyStatus}
                customOnClickAction={() => setOpenReportMenu(true)}
              />
            )}
          </>
        )}
        <ReportsModal
          open={openReportMenu}
          onOk={() => setOpenReportMenu(false)}
          onCancel={() => setOpenReportMenu(false)}
          editReportsOfBoard={editReports}
          reportCategories={reportCategories}
        />
      </>
    </>
  )
}

export default Home
