import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Col from 'antd/es/col'

import { IGraphData, Report, ReportType } from '@cozero/models'

import ErrorGraphView from '@/organisms/ErrorGraphView'

import LoadingSpinner from '@/atoms/LoadingSpinner'
import Text from '@/atoms/Text'

import { useFiltersContext } from '@/contexts/filters'
import useBoards from '@/hooks/useBoards'
import usePollingEffect from '@/hooks/usePollingEffect'
import i18n from '@/i18n'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

import OverviewCard from '../OverviewCard'

import classes from './GraphStatisticsCard.module.less'
import { UnitMeasureText } from './UnitMeasureText'

interface GraphStatisticsCardProps {
  report: Report | null
  includeChildrenBUsData?: boolean
}

export const GraphStatisticsCard = ({
  report,
  includeChildrenBUsData = false,
}: GraphStatisticsCardProps): JSX.Element | null => {
  const { t } = useTranslation('common')

  const { filters } = useFiltersContext()
  const [statsLoading, setStatsLoading] = useState<boolean>(true)
  const [statsError, setStatsError] = useState<boolean>(false)
  const [currentStatData, setCurrentStatData] = useState<Report | null>(report)
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const { getReportWithData, handleReportData } = useBoards()
  const abortControllerRef = useRef<AbortController>()
  const location = useLocation()

  useEffect(() => {
    if (currentStatData?.type === ReportType.STATISTIC && selectedBusinessUnit) {
      setIsPollingEnabled(false)
      fetchGraphData()
    }
  }, [filters, selectedBusinessUnit?.key])

  useEffect(() => {
    return function cleanup() {
      abortControllerRef.current?.abort()
    }
  }, [location.pathname])

  useEffect(() => {
    return function cleanup() {
      abortControllerRef.current?.abort()
    }
  }, [location.pathname])

  const updateStatsState = useCallback(
    ({
      isLoading,
      isPollingEnabled,
      hasError,
      reportData,
    }: ReturnType<typeof handleReportData>) => {
      setIsPollingEnabled(isPollingEnabled)
      setStatsLoading(isLoading)
      setStatsError(hasError)
      if (reportData) {
        setCurrentStatData(reportData)
      }
    },
    [],
  )

  const { isPollingEnabled, setIsPollingEnabled } = usePollingEffect(
    async () => {
      try {
        if (currentStatData) {
          const reportWithData = await getReportWithData({
            key: currentStatData.key,
            currentFilters: filters,
          })
          if (reportWithData) {
            updateStatsState(handleReportData(reportWithData))
          }
        }
      } catch (error) {
        setIsPollingEnabled(false)
        setStatsError(true)
        setStatsLoading(false)
      }
    },
    [],
    {},
  )

  if (!currentStatData) {
    return <></>
  }

  const fetchGraphData = async (): Promise<void> => {
    setStatsLoading(true)
    try {
      if (currentStatData) {
        const reportWithData = await getReportWithData({
          key: currentStatData.key,
          currentFilters: filters,
          abortSignal: abortControllerRef.current?.signal,
          includeChildrenBUsData: includeChildrenBUsData,
        })
        if (reportWithData) {
          updateStatsState(handleReportData(reportWithData))
        }
      }
    } catch (e) {
      setStatsLoading(false)
      setIsPollingEnabled(false)
      setStatsError(true)
    }
  }

  if (!currentStatData) {
    return null
  }

  const { type, data, cubeJSQuery, title } = currentStatData

  const getCardValue = (data: IGraphData | null): string => {
    if (!data || !data.totalRow || !data.seriesNames || !data.seriesNames[0]) {
      return '0'
    }

    const targetValue = data.totalRow[data.seriesNames[0].key]
    return targetValue.toLocaleString(i18n.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  return (
    <>
      {type === ReportType.STATISTIC && !statsError && (
        <Col flex="auto" style={{ maxWidth: '50%' }}>
          <div className={classes.statisticContainer}>
            {isPollingEnabled ? (
              <LoadingSpinner
                className={classes.calculatingSpinner}
                title={t('share.reports.calculating.title')}
                text={t('share.reports.calculating.text', { joinArrays: '\n' })}
              />
            ) : statsLoading ? (
              <LoadingSpinner title={t('share.reports.loading')} />
            ) : statsError ? (
              <ErrorGraphView />
            ) : (
              <OverviewCard
                style={{ height: '100%' }}
                loading={statsLoading}
                content={
                  <>
                    <Text
                      mode="title"
                      fontWeight="medium"
                      size="lg"
                      className={classes.emissionsValue}
                    >
                      {getCardValue(data)}
                    </Text>
                    <UnitMeasureText cubeJSQuery={cubeJSQuery} />
                  </>
                }
                headerTitle={title}
              />
            )}
          </div>
        </Col>
      )}
    </>
  )
}
