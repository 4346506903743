import React, { useRef } from 'react'

type ThrottledFunction = (func: () => void, delay?: number) => void

const useThrottle = (): ThrottledFunction => {
  const throttleSeed = useRef<NodeJS.Timeout | null>(null)

  const throttleFunction = useRef<ThrottledFunction>((func, delay = 200) => {
    if (!throttleSeed.current) {
      // Call the callback immediately for the first time
      func()
      throttleSeed.current = setTimeout(() => {
        throttleSeed.current = null
      }, delay)
    }
  })

  return throttleFunction.current
}

export default useThrottle
