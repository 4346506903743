import React, { ChangeEvent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Empty, Row, Space, Spin } from 'antd/es'

import { Category, Subcategory } from '@cozero/models'

import Modal from '@/molecules/Modal'

import Card from '@/atoms/Card'
import Dot, { DotColor } from '@/atoms/Dot'
import InputField from '@/atoms/InputField'
import Label from '@/atoms/Label'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import useLogCreationCategories from '@/hooks/useLogCreationCategories'
import { truncate } from '@/utils/string'

import classes from './LogCategoryModal.module.less'

interface Props {
  open: boolean
  onChooseCategory: (id: number, name: string) => void
  onClose: () => void
  loadingLogCreation?: boolean
}

interface CategoryFilterContent {
  textLabel: string
  filterKey: string
  categories: Category[]
  dotColor?: DotColor
  indented?: boolean
}

const LogCategoryModal = ({
  open,
  onChooseCategory,
  onClose,
  loadingLogCreation = false,
}: Props): ReactElement => {
  const { t } = useTranslation('common')
  const [internalCategories, setInternalCategories] = useState<
    Partial<Category & { subcategories: Subcategory[] }>[]
  >([])
  const [filterKey, setFilterKey] = useState<string>('all')
  const [searchTerm, setSearchTerm] = useState('')
  const { logCreationCategories: categories, loading: loadingLogCreationCategories } =
    useLogCreationCategories()

  const onSearchChange = (inputVal: string): void => {
    setSearchTerm(inputVal)
    onSearchHandler(inputVal)
  }

  /**
   * The search handler used to filter the categories
   * @param inputVal the string to search for
   */
  const onSearchHandler = (inputVal: string): void => {
    let filteredCategories
    if (inputVal.length && categories) {
      // Pattern explanation: https://regex101.com/r/oT8jM4/1
      const pattern = new RegExp(`S*${inputVal}S*`, 'gi')
      filteredCategories = categories.filter(
        (c) =>
          c.name?.match(pattern) ||
          (c.subcategories as Subcategory[])?.some((s) => s.name?.match(pattern)),
      )
      setInternalCategories(filteredCategories ?? categories)
      setSearchTerm(inputVal)
    }
  }

  const sortFunction = (a: Category, b: Category): number => {
    const categorySort = a.scope.categoryNumber - b.scope.categoryNumber
    if (categorySort) {
      return categorySort
    }

    const subCategorySort =
      getOrZero(a.scope.subcategoryNumber) - getOrZero(b.scope.subcategoryNumber)
    if (subCategorySort) {
      return subCategorySort
    }

    return a.name.localeCompare(b.name)
  }

  const getOrZero = (elem: number | null): number => (elem ? elem : 0)

  const upstreamSubcategories = [1, 2, 3, 4, 5, 6, 7, 8]
  const downstreamSubcategories = [9, 10, 11, 12, 13, 14, 15]

  const sortedCategories: CategoryFilterContent[] = useMemo(() => {
    if (!categories) {
      return []
    }
    const allCategoriesSorted: Category[] = categories.sort(sortFunction)
    const scope1Sorted: Category[] = allCategoriesSorted?.filter((c) => c.scope.categoryNumber == 1)
    const scope2Sorted: Category[] = allCategoriesSorted?.filter((c) => c.scope.categoryNumber == 2)
    const scope3Sorted: Category[] = allCategoriesSorted?.filter((c) => c.scope.categoryNumber == 3)
    const scope3UpstreamSorted: Category[] = scope3Sorted?.filter(
      (c) => c.scope.subcategoryNumber && upstreamSubcategories.includes(c.scope.subcategoryNumber),
    )
    const scope3DownstreamSorted: Category[] = scope3Sorted?.filter(
      (c) =>
        c.scope.subcategoryNumber && downstreamSubcategories.includes(c.scope.subcategoryNumber),
    )

    return [
      {
        textLabel: 'log.category-modal.filters.all',
        filterKey: 'all',
        categories: allCategoriesSorted,
      },
      {
        textLabel: 'log.category-modal.filters.scope1',
        filterKey: 'one',
        categories: scope1Sorted,
        dotColor: 'scope1',
      },
      {
        textLabel: 'log.category-modal.filters.scope2',
        filterKey: 'two',
        categories: scope2Sorted,
        dotColor: 'scope2',
      },
      {
        textLabel: 'log.category-modal.filters.scope3',
        filterKey: 'three',
        categories: scope3Sorted,
        dotColor: 'scope3',
      },
      {
        textLabel: 'log.category-modal.filters.scope3Upstream',
        filterKey: 'up',
        categories: scope3UpstreamSorted,
        dotColor: 'scope3',
        indented: true,
      },
      {
        textLabel: 'log.category-modal.filters.scope3Downstream',
        filterKey: 'down',
        categories: scope3DownstreamSorted,
        dotColor: 'scope3',
        indented: true,
      },
    ]
  }, [categories])

  const chooseType = (selectedFilter: CategoryFilterContent): void => {
    setFilterKey(selectedFilter.filterKey)
    return setInternalCategories(selectedFilter.categories)
  }

  /**
   * Map the cards with the category info
   * to an array, if their are categories to show or show an empty image
   * @returns {ReactNode} An array of cards or the empty placeholder
   */
  const renderCategoryCards = (): ReactNode => {
    if (internalCategories?.length > 0) {
      const subcategoriesShowCount = 4
      return internalCategories.map(({ id, image, name, subtitle, subcategories, scope }) => (
        <Col key={id} span={12}>
          <Card
            title={name}
            icon={image}
            shadow="sm"
            hoverable
            onClick={() => onChooseCategory(id ? id : 0, name as unknown as string)}
            data-cy={name}
            boldTitle={true}
            smallerSpacingBetweenIconAndTitle={true}
          >
            <div className={classes.spacer}>
              <Tag type="default" className={classes.categoryCardTag}>{`${scope?.categoryNumber}${
                scope?.subcategoryNumber ? '.' + scope?.subcategoryNumber : ''
              } - ${scope?.title} `}</Tag>
              <Text size="xl" className={classes.categoryCardText}>
                {subtitle}
              </Text>
              {subcategories && (
                <div className={classes.subcategories}>
                  <Label>{t('log.category-modal.all')}</Label>
                  <div className={classes.subcategoriesTagContainer}>
                    {subcategories
                      ?.map(({ id, name }) => (
                        <span key={id} className={classes.subcategoryTag}>
                          <Tooltip title={name as string} key={id}>
                            {name as string}
                          </Tooltip>
                        </span>
                      ))
                      ?.slice(0, subcategoriesShowCount)}
                    {subcategories && subcategories.length > subcategoriesShowCount && (
                      <span className={classes.subcategoryTag}>
                        <Tooltip
                          title={subcategories
                            .slice(subcategoriesShowCount)
                            .reduce(
                              (previous, current) =>
                                previous ? `${previous}, ${current.name}` : `${current.name}`,
                              '',
                            )}
                          key={id}
                        >
                          ... {subcategories.length - subcategoriesShowCount}{' '}
                          {t('log.category-modal.subcategories-more')}
                        </Tooltip>
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </Col>
      ))
    }
    return (
      <Col span={24}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Col>
    )
  }

  useEffect(() => {
    if (!open && categories) {
      setInternalCategories(categories)
      setSearchTerm('')
    }
  }, [open, categories])

  useEffect(() => {
    if (searchTerm === '' && categories) {
      setInternalCategories(categories)
    }
  }, [searchTerm, categories])

  return (
    <Modal
      visible={open}
      title={t('log.category-modal.choose')}
      noBodyPadding
      closable={false}
      onCancel={onClose}
      hideFooter
      data-cy="log-category-modal"
      className={classes.logCategoryModal}
      minWidth={'800px'}
      width={'60%'}
      bodyFullHeight={true}
    >
      <Spin spinning={loadingLogCreationCategories || loadingLogCreation}>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={6}>
                <aside className={classes.sidebar}>
                  {sortedCategories.map((c) => (
                    <div className={classes.sidebarSection} key={c.filterKey}>
                      <span
                        onClick={() => chooseType(c)}
                        className={`${classes.sidebarItem} 
                        ${filterKey === c.filterKey ? classes.active : ''} 
                        ${c.indented ? classes.indented : ''}
                        ${c.dotColor ? classes.containsDot : ''}`}
                      >
                        {c.dotColor && (
                          <div className={classes.dot}>
                            <Dot type={c.dotColor}></Dot>
                          </div>
                        )}
                        {t(c.textLabel)}
                      </span>
                    </div>
                  ))}
                </aside>
              </Col>
              <Col span={18}>
                <div className={classes.listWrapper}>
                  <Row>
                    <Col span={24}>
                      <div className={classes.listHeader}>
                        <span data-cy="log-categories">
                          {t('log.categories')} ({internalCategories.length})
                        </span>
                        <div>
                          <InputField
                            type="search"
                            placeholder={t('log.search')}
                            onSearch={onSearchHandler}
                            allowClear
                            value={searchTerm}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              onSearchChange(e.target.value)
                            }
                            data-cy="log-categories-search"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className={classes.list}>
                        <Row data-cy="category-cards" gutter={[12, 12]} wrap>
                          {renderCategoryCards()}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export default LogCategoryModal
