import React, { PropsWithChildren } from 'react'
import { HiMinus, HiPlus } from 'react-icons/hi'

import { HotspotAnalysisData } from '@cozero/models'

import classes from './HotspotAnalysisCell.module.less'

type Props = {
  record: HotspotAnalysisData
  level: number
  isExpanded: boolean
}
export const HotspotAnalysisCell = ({
  level,
  record,
  isExpanded,
  children,
}: PropsWithChildren<Props>): React.ReactElement | null => {
  if (record.level === level && record.children?.length && record.children?.length > 0) {
    if (isExpanded) {
      return (
        <div className={classes.root}>
          <div className={classes.icon}>
            <HiMinus size={14} />
          </div>

          {children}
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <div className={classes.icon}>
          <HiPlus size={14} />
        </div>
        {children}
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.icon}></div>
      {children}
    </div>
  )
}
