import React from 'react'
import { useSelector } from 'react-redux'

import { HotspotAnalysisFilterKeys, HotspotFiltersOptionsResult } from '@cozero/models'

import { useGetHotspotsFiltersOptionsQuery } from '@/redux/actAnalytics'
import { useSelectedHotspotAnalysisView } from '@/redux/actAnalytics/hooks/useSelectedHotspotAnalysisView.hook'
import {
  selectSelectedBusinessUnit,
  useLazyGetActiveBusinessUnitsQuery,
} from '@/redux/businessUnits'
import { useLazyGetActiveLocationsQuery } from '@/redux/locations'

type Params = {
  selectedCategoryIds: number[]
}

export const useFiltersOptions = ({
  selectedCategoryIds,
}: Params): {
  options: Partial<HotspotFiltersOptionsResult> & {
    [HotspotAnalysisFilterKeys.SUBCATEGORY]?: HotspotFiltersOptionsResult['subcategoryId']
    [HotspotAnalysisFilterKeys.BUSINESS_UNIT]?: HotspotFiltersOptionsResult['categoryId']
    [HotspotAnalysisFilterKeys.LOCATION]?: HotspotFiltersOptionsResult['categoryId']
    [HotspotAnalysisFilterKeys.TERRITORY]?: HotspotFiltersOptionsResult['territory']
    [HotspotAnalysisFilterKeys.BUSINESS_UNIT]?: HotspotFiltersOptionsResult['businessUnit']
    [HotspotAnalysisFilterKeys.LOCATION]?: HotspotFiltersOptionsResult['locationId']
  }
  onBusinessUnitsSearch: (value: string) => void
  onLocationsSearch: (value: string) => void
  optionsLoading: boolean
  businessUnitsLoading: boolean
  locationsLoading: boolean
  deletedLocations: { value: number; label: string }[]
  deletedBusinessUnits: { value: number; label: string }[]
} => {
  const [selectedHotspotAnalysisView] = useSelectedHotspotAnalysisView()
  const { data: partialOptions, isFetching: optionsLoading } = useGetHotspotsFiltersOptionsQuery(
    {
      viewId: selectedHotspotAnalysisView?.id ?? -1,
    },
    { skip: !selectedHotspotAnalysisView?.id },
  )

  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)

  const [searchBusinessUnits, { data: searchedBusinessUnits, isFetching: businessUnitsLoading }] =
    useLazyGetActiveBusinessUnitsQuery()

  const [searchLocations, { data: searchedLocations, isFetching: locationsLoading }] =
    useLazyGetActiveLocationsQuery()

  const [locations, setLocations] = React.useState<{ value: number; label: string }[]>([])
  const [businessUnits, setBusinessUnits] = React.useState<{ value: number; label: string }[]>([])

  const deletedLocations = React.useMemo(
    () => partialOptions?.deletedFilterOptions?.[HotspotAnalysisFilterKeys.LOCATION] || [],
    [partialOptions],
  )

  const deletedBusinessUnits = React.useMemo(
    () => partialOptions?.deletedFilterOptions?.[HotspotAnalysisFilterKeys.BUSINESS_UNIT] || [],
    [partialOptions],
  )

  // Show only the subcategories of the selected categories
  const subcategoryOptions = React.useMemo(
    () =>
      (selectedCategoryIds
        ? partialOptions?.options?.[HotspotAnalysisFilterKeys.SUBCATEGORY].filter(
            ({ categoryId }) => selectedCategoryIds.includes(categoryId),
          )
        : partialOptions?.options?.[HotspotAnalysisFilterKeys.SUBCATEGORY]) || [],
    [selectedCategoryIds, partialOptions],
  )

  const handleBusinessUnitsChange = React.useCallback(() => {
    const formattedSearchedBusinessUnits = searchedBusinessUnits?.map((bu) => ({
      value: bu.id,
      label: bu.title,
    }))

    const allBusinessUnits = [
      ...(formattedSearchedBusinessUnits ?? []),
      ...(partialOptions?.options.businessUnit ?? []),
    ].filter((bu, index, self) => {
      return index === self.findIndex((t) => t.value === bu.value) // Remove duplicate results
    })

    setBusinessUnits(allBusinessUnits)
  }, [searchedBusinessUnits, partialOptions?.options.businessUnit])

  const handleLocationsChange = React.useCallback(() => {
    const formattedSearchedLocations = searchedLocations?.map((location) => ({
      value: location.id,
      label: location.name,
    }))

    const allLocations = [
      ...(formattedSearchedLocations ?? []),
      ...(partialOptions?.options.locationId ?? []),
    ].filter((location, index, self) => {
      return index === self.findIndex((t) => t.value === location.value)
    }) // Remove duplicate results

    setLocations(allLocations)
  }, [searchedLocations, partialOptions?.options.locationId])

  const onBusinessUnitsSearch = React.useCallback(
    (value: string) => {
      searchBusinessUnits({
        businessUnitScopeId: selectedBusinessUnit?.id ?? -1,
        search: value,
      })
    },
    [searchBusinessUnits, selectedBusinessUnit],
  )

  const onLocationsSearch = React.useCallback(
    (value: string) => {
      searchLocations({
        selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1,
        search: value,
      })
    },
    [searchLocations, selectedBusinessUnit],
  )

  const options = React.useMemo(
    () => ({
      ...partialOptions?.options,
      [HotspotAnalysisFilterKeys.SUBCATEGORY]: subcategoryOptions,
      [HotspotAnalysisFilterKeys.BUSINESS_UNIT]: businessUnits,
      [HotspotAnalysisFilterKeys.LOCATION]: locations,
    }),
    [partialOptions, subcategoryOptions, businessUnits, locations],
  )

  React.useEffect(() => {
    handleBusinessUnitsChange()
  }, [handleBusinessUnitsChange])

  React.useEffect(() => {
    handleLocationsChange()
  }, [handleLocationsChange])

  React.useEffect(() => {
    setBusinessUnits(partialOptions?.options.businessUnit ?? [])
    setLocations(partialOptions?.options.locationId ?? [])
  }, [selectedBusinessUnit, partialOptions])

  return {
    options,
    onBusinessUnitsSearch,
    onLocationsSearch,
    optionsLoading,
    businessUnitsLoading,
    locationsLoading,
    deletedBusinessUnits,
    deletedLocations,
  }
}
