import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Form, Menu, Row, message } from 'antd/es'

import classNames from 'classnames'

import Button from '@/atoms/Button'
import InputField from '@/atoms/InputField'
import Label from '@/atoms/Label'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useRenameHotspotViewsMutation } from '@/redux/actAnalytics'

import { useHotspotAnalysisContext } from '../../hooks/useHotspotAnalysisContext'

import classes from './HotspotAnalysisRenameViewForm.module.less'

type FormFields = {
  name: string
}

type HotspotAnalysisRenameViewFormProps = {
  viewId: number
  onCancel?: () => void
  onSubmit?: () => void
}
export const HotspotAnalysisRenameViewForm = ({
  viewId,
  onSubmit: onSubmitProps,
  onCancel,
}: HotspotAnalysisRenameViewFormProps): React.ReactElement => {
  const { t } = useTranslation('common')

  const { type } = useHotspotAnalysisContext()
  const [renameHotspotView, { isLoading }] = useRenameHotspotViewsMutation()

  const [form] = Form.useForm<FormFields>()
  const name = Form.useWatch('name', form)

  const onSubmit = useCallback(async (): Promise<void> => {
    const valid = await form.validateFields()
    if (!valid) {
      return
    }

    try {
      renameHotspotView
      const { name } = form.getFieldsValue()
      const response = await renameHotspotView({ id: viewId, type, data: { name } })
      if ('error' in response) {
        throw new Error(response.error.message)
      }
      onSubmitProps?.()
    } catch (error) {
      message.error(
        error?.errorFields?.[0]?.errors?.[0] ??
          t('act.analytics.hotspotAnalysis.views.edit-name.error'),
      )
    }
  }, [viewId])

  return (
    <Menu>
      <Form
        form={form}
        requiredMark={false}
        className={classNames(classes.form)}
        onFinish={onSubmit}
      >
        <Row>
          <Col>
            <Label>{t('act.analytics.hotspotAnalysis.views.edit-name.label')}</Label>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item name="name" rules={[{ required: true }]} className={classes.input}>
              <InputField
                autoComplete="off"
                type="text"
                size="large"
                placeholder={t('act.analytics.hotspotAnalysis.views.edit-name.placeholder')}
                data-cy="hotspot-view-rename-input"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row align={'middle'} justify={'space-between'} gutter={[16, 16]}>
          <Col>
            <Button
              category={AnalyticsCategories.ACT_HOTSPOT_ANALYSIS}
              action="cancel-rename"
              type="secondary"
              onClick={onCancel}
            >
              {t('act.analytics.hotspotAnalysis.views.edit-name.cancel')}
            </Button>
          </Col>

          <Col>
            <Button
              loading={isLoading}
              disabled={!name}
              category={AnalyticsCategories.ACT_HOTSPOT_ANALYSIS}
              action="rename"
              data-cy="hotspot-view-rename-submit"
              type="primary"
              htmlType="submit"
            >
              {t('act.analytics.hotspotAnalysis.views.edit-name.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Menu>
  )
}
