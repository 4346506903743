/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { HiOutlineInformationCircle } from 'react-icons/hi'

import { Tooltip } from 'antd/es'

import { HotspotAnalysisViewType } from '@cozero/models'

import Card from '@/atoms/Card'
import Title from '@/atoms/Title'

import { COZERO_BLUE_80 } from '@/styles/variables'

import classes from './HotspotAnalysis.module.less'
import { HotspotAnalysisTabsWrapper } from './HotspotAnalysisTabsWrapper'
import { HotspotAnalysisProvider } from './hooks/useHotspotAnalysisContext'

interface Props {
  title: string
  tooltip: string
  type: HotspotAnalysisViewType
}

export const HotspotAnalysis = ({ title, tooltip, type }: Props): React.ReactElement | null => {
  return (
    <HotspotAnalysisProvider value={{ type }}>
      <Card className={classes.root} shadow="none" data-cy="hotspot-analysis-section">
        <Title size="xs" fontWeight="semibold">
          {title}

          <Tooltip title={tooltip} className={classes.icon} placement="bottom">
            <HiOutlineInformationCircle size={15} color={COZERO_BLUE_80} />
          </Tooltip>
        </Title>

        <HotspotAnalysisTabsWrapper />
      </Card>
    </HotspotAnalysisProvider>
  )
}
