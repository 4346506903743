import { ComputedReportDto, ComputedReportTypeDto, CreateComputedReportDto } from '@cozero/dtos'
import { reportsApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'
import { LIST } from '../types'

import {
  CREATE_COMPUTED_REPORT,
  GET_COMPUTED_REPORT_BY_ID,
  GET_COMPUTED_REPORT_TYPES,
  GET_LATEST_COMPUTED_REPORT_DATA,
  TAG_COMPUTED_REPORTS,
  TAG_LATEST_COMPUTED_REPORT_DATA,
} from './constants'
import { TAG_COMPUTED_REPORT_TYPES } from './tags'

export type GetLatestComputedReportParams = {
  businessUnitId: number
  computedReportTypeKey: string
  baseYearStart: string
  baseYearEnd: string
  reportingYearStart: string
  reportingYearEnd: string
}

const ComputedReportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_COMPUTED_REPORT_TYPES]: builder.query<ComputedReportTypeDto[], void>({
      query: () => ({
        url: reportsApiGatewayClient.computedReports.GET_ALL_TYPES,
        method: 'GET',
      }),
      providesTags: (result) => {
        return providesList<ComputedReportTypeDto[], typeof TAG_COMPUTED_REPORT_TYPES>(
          result,
          TAG_COMPUTED_REPORT_TYPES,
        )
      },
    }),
    [GET_LATEST_COMPUTED_REPORT_DATA]: builder.query<
      ComputedReportDto | null,
      GetLatestComputedReportParams
    >({
      query: (params) => ({
        url: reportsApiGatewayClient.computedReports.GET_LAST,
        method: 'GET',
        params: params,
      }),
      transformErrorResponse: (res) => res,
      providesTags: (result) => [{ type: TAG_LATEST_COMPUTED_REPORT_DATA, id: result?.id }],
    }),
    [GET_COMPUTED_REPORT_BY_ID]: builder.query<ComputedReportDto | null, string>({
      query: (id) => ({
        url: reportsApiGatewayClient.computedReports.GET_ONE.replace(':id', id),
        method: 'GET',
      }),
    }),
    [CREATE_COMPUTED_REPORT]: builder.mutation<ComputedReportDto, CreateComputedReportDto>({
      query: (data) => ({
        url: reportsApiGatewayClient.computedReports.CREATE,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: [{ type: TAG_COMPUTED_REPORTS, id: LIST }],
      extraOptions: { maxRetries: 0 },
    }),
  }),
})

export const {
  useGetComputedReportTypesQuery,
  useGetLatestComputedReportDataQuery,
  useCreateComputedReportMutation,
  useLazyGetLatestComputedReportDataQuery,
  useGetComputedReportByIdQuery,
} = ComputedReportsApiSlice

export default ComputedReportsApiSlice
