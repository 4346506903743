/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  HiDownload,
  HiOutlineCheckCircle,
  HiOutlineDotsHorizontal,
  HiOutlineViewBoards,
} from 'react-icons/hi'

import { Dropdown, MenuProps, message } from 'antd'

import { SearchLogEntryDto } from '@cozero/dtos'
import { LogEntriesTableColumnKey } from '@cozero/models/src'
import { logApiGatewayClient } from '@cozero/uris'

import { SpinnerElement } from '@/atoms/LoadingSpinner'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { COZERO_GREEN_60 } from '@/styles/variables'
import axios from '@/utils/axios'

import { OrganizationCarboonFootprintFormValues } from '../OrganizationCarbonFootprintFiltersDrawer/hooks/useOrganizationCarbonFootprintFormConfiguration'
import { ColumnSorting } from '../hooks/useSorting'

import classes from './ExportDropdown.module.less'

type Props = {
  startDate: SearchLogEntryDto['startDate'] | null
  endDate: SearchLogEntryDto['endDate'] | null
  sorting?: ColumnSorting
  filters?: OrganizationCarboonFootprintFormValues
  visibleColumns?: LogEntriesTableColumnKey[]
  manageColumns: () => void
}

const ExportDropdown = ({
  startDate,
  endDate,
  sorting,
  filters,
  visibleColumns,
  manageColumns,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('common')
  const { trackAction } = useMixpanel()
  const selectedBusinessUnit = useAppSelector(selectSelectedBusinessUnit)
  const [runningExport, setRunningExport] = React.useState<string>()
  const [open, setOpen] = React.useState(false)

  const onExport = React.useCallback(
    async (exportKey: string, type: 'csv' | 'xlsx', filtered: boolean) => {
      setRunningExport(exportKey)

      trackAction(
        AnalyticsCategories.LOG_ENTRY_OVERVIEW,
        filtered ? 'filtered-view-exported' : 'entire-view-exported',
      )

      if (!selectedBusinessUnit?.id) {
        return
      }

      const params = {
        type,
        startDate,
        endDate,
        sortingKey: sorting?.key,
        sortingDirection: sorting?.direction,
        filters: filtered ? filters : undefined,
        visibleColumns: filtered ? visibleColumns : undefined,
        selectedBusinessUnitId: selectedBusinessUnit.id,
      }

      try {
        const { data: downloadUrl } = await axios.get<unknown, { data: string }>(
          `${logApiGatewayClient.logEntries.EXPORT}`,
          { params },
        )

        // Create link to the download url and click on it programatically
        const link = document.createElement('a')
        link.href = downloadUrl
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        message.success({
          content: t('log.log-entries-overview.export.success'),
          className: classes.exportSuccess,
          icon: <HiOutlineCheckCircle size={18} color={COZERO_GREEN_60} />,
        })
      } catch (error) {
        message.error(t('general-errors.app-error'))
      }

      setRunningExport(undefined)
      setOpen(false)
    },
    [
      trackAction,
      selectedBusinessUnit?.id,
      startDate,
      endDate,
      sorting?.key,
      sorting?.direction,
      filters,
      visibleColumns,
      t,
    ],
  )

  const exportItems: MenuProps['items'] = React.useMemo(
    () =>
      [
        {
          key: 'full-view-csv',
          icon: <HiDownload />,
          label: t('log.log-entries-overview.export.full-csv'),
          onClick: () => onExport('full-view-csv', 'csv', false),
        },
        {
          key: 'full-view-xslx',
          icon: <HiDownload />,
          label: t('log.log-entries-overview.export.full-xlsx'),
          onClick: () => onExport('full-view-xslx', 'xlsx', false),
        },
        {
          key: 'filtered-view-csv',
          icon: <HiDownload />,
          label: t('log.log-entries-overview.export.filtered-csv'),
          onClick: () => onExport('filtered-view-csv', 'csv', true),
        },
        {
          key: 'filtered-view-xslx',
          icon: <HiDownload />,
          label: t('log.log-entries-overview.export.filtered-xlsx'),
          onClick: () => onExport('filtered-view-xslx', 'xlsx', true),
        },
      ].map((item) => ({
        ...item,
        icon:
          runningExport === item.key ? (
            <SpinnerElement size="2xs" color="grey" className={classes.spinner} />
          ) : (
            item.icon
          ),
        disabled: Boolean(runningExport),
      })),
    [t, onExport, runningExport],
  )

  const items: MenuProps['items'] = React.useMemo(
    () => [
      ...exportItems,
      {
        key: `manage-columns`,
        icon: <HiOutlineViewBoards />,
        label: t('log.log-entries-overview.manage-columns.label'),
        onClick: () => {
          setOpen(false)
          manageColumns()
        },
      },
    ],
    [exportItems, manageColumns, t],
  )

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      getPopupContainer={(trigger) => trigger.parentElement || document.body}
      overlayClassName={classes.dotsMenu}
      onOpenChange={setOpen}
      open={open}
    >
      <HiOutlineDotsHorizontal className={classes.dotsIcon} size={16} />
    </Dropdown>
  )
}

export default ExportDropdown
