import React from 'react'
import { useTranslation } from 'react-i18next'

import { Image } from 'antd/es'

import classNames from 'classnames'
import moment from 'moment'

import Modal from '@/molecules/Modal'

import Button from '@/atoms/Button'
import Tag from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import CalendarSelectionImage from '@/assets/act/calendar-selection.png'
import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { MODAL_SIZE_SM } from '@/styles/variables'

import classes from './HotspotAnalysisReferencePeriodConflictModal.module.less'

type Props = {
  referencePeriod: [Date, Date] | undefined
  open: boolean
  onClose: () => void
}
export const HotspotAnalysisReferencePeriodConflictModal = ({
  referencePeriod,
  open,
  onClose,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Modal open={open} centered className={classes.modal} width={MODAL_SIZE_SM}>
      <div className={classes.content}>
        <div className={classes.center}>
          <Image className={classes.image} src={CalendarSelectionImage} preview={false} />
        </div>

        <div className={classes.message}>
          <Title size="sm" className={classes.center}>
            {t('act.analytics.hotspotAnalysis.referencePeriodConflictModal.title')}
          </Title>

          <div className={classNames(classes.center, classes.dates)}>
            <Tag className={classes.tag}>{moment(referencePeriod?.[0]).utc().format('LL')}</Tag>
            <Text size="xl">{t('to')}</Text>
            <Tag className={classes.tag}>{moment(referencePeriod?.[1]).utc().format('LL')}</Tag>
          </div>

          <Text
            size="xl"
            color="secondary"
            className={classNames(classes.center, classes.description)}
          >
            {t('act.analytics.hotspotAnalysis.referencePeriodConflictModal.description')}
          </Text>
        </div>

        <div className={classes.center}>
          <Button
            onClick={onClose}
            category={AnalyticsCategories.ACT_HOTSPOT_ANALYSIS}
            action="close-reference-year-conflict-modal"
            data-cy="close-reference-year-conflict-modal"
            type="primary"
            size="lg"
          >
            {t('okay')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
