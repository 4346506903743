/* eslint react-hooks/exhaustive-deps: 2 */

import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const FEATURE_FLAGS_LOCAL_STORAGE_KEY = 'featureFlags'

/**
 * Hook to manage a specific feature flag by key
 * It reads the flag from the URL, then from localStorage, and allows updating the value.
 * @param {string} key - The name of the feature flag
 * @returns {[boolean, (value: boolean) => void]} - The flag's current value and a setter function to update it
 */
export const useFeatureFlag = (
  key: string,
): { featureFlag: boolean; setFeatureFlag: (value: boolean) => void } => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  // Read the persisted feature flags from localStorage
  const storedFeatureFlags = JSON.parse(
    localStorage.getItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY) || '{}',
  )

  // Determine whether we're in a production or staging environment
  const isProductionOrStaging = [
    'app.staging.cozero.io',
    'app.cozero.io',
    'edelivery.cozero.io',
  ].includes(window.location.hostname)

  // Read flag value from the URL search params
  const searchParamValue = searchParams.get(key)
  const isFlagInUrl = searchParamValue !== null
  const flagValueFromUrl = searchParamValue === 'true'

  // Initialize state with the current value of the feature flag
  const [featureFlag, _setFeatureFlagValue] = useState<boolean>(() => {
    if (isFlagInUrl) {
      return flagValueFromUrl
    }

    if (storedFeatureFlags[key] !== undefined) {
      return storedFeatureFlags[key]
    }

    return !isProductionOrStaging
  })

  // Function to update the flag value and persist it
  const setFeatureFlag = useCallback(
    (value: boolean): void => {
      _setFeatureFlagValue(value)

      localStorage.setItem(
        FEATURE_FLAGS_LOCAL_STORAGE_KEY,
        JSON.stringify({ ...storedFeatureFlags, [key]: value }),
      )
    },
    [key, storedFeatureFlags],
  )

  // Update the value of the flag when URL search param changes
  useEffect(() => {
    if (isFlagInUrl) {
      setFeatureFlag(flagValueFromUrl)
    }
  }, [flagValueFromUrl, isFlagInUrl, searchParamValue, setFeatureFlag])

  return { featureFlag, setFeatureFlag }
}
