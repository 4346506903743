import React, { useState } from 'react'

import { Col, Row } from 'antd/es'

import { useEmissionHotspots } from '@/pages/Act/Analytics/components/HotspotAnalysis/hooks/useEmissionHotspots'

import { useUpdateHotspotViewsDateFilterMutation } from '@/redux/actAnalytics'

import {
  DATE_PICKER_UPDATE_FIXED_CACHED_KEY,
  HotspotAnalysisDatePicker,
} from './HotspotAnalysisDatePicker'
import { HotspotAnalysisExportDropdown } from './HotspotAnalysisExportDropdown'
import { HotspotAnalysisFilterButton } from './HotspotAnalysisFilterButton'
import { HotspotAnalysisFilterDrawer } from './HotspotAnalysisFilterDrawer'
import { HotspotAnalysisTable } from './HotspotAnalysisTable'
import classes from './HotspotAnalysisView.module.less'

interface Props {
  viewId: number
  isLoading: boolean
  'data-cy'?: string
}

export const HotspotAnalysisView = ({
  viewId,
  isLoading: isLoadingProps,
  ...props
}: Props): React.ReactElement => {
  const [showFilterSidebar, setShowFilterSidebar] = useState(false)

  const {
    data = [],
    pins = [],
    isLoading: IsLoadingEmissionHotspots,
  } = useEmissionHotspots({ viewId })
  const [, { isLoading: updateHotspotViewsDateFilterIsLoading }] =
    useUpdateHotspotViewsDateFilterMutation({
      fixedCacheKey: DATE_PICKER_UPDATE_FIXED_CACHED_KEY,
    })

  const isLoading =
    IsLoadingEmissionHotspots || isLoadingProps || updateHotspotViewsDateFilterIsLoading
  return (
    <div data-cy={props['data-cy']}>
      <Row gutter={[32, 8]} align="middle" justify={'end'} className={classes.header}>
        <Col>
          <Row align="middle" gutter={8} wrap={false}>
            <Col>
              <HotspotAnalysisFilterButton
                disabled={isLoading}
                setShowFilterSidebar={setShowFilterSidebar}
              />
            </Col>

            <Col>
              <HotspotAnalysisDatePicker disabled={isLoading} />
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="middle">
            <HotspotAnalysisExportDropdown data={data} disabled={isLoading} />
          </Row>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <HotspotAnalysisTable data={data} isLoading={isLoading} viewId={viewId} pins={pins} />
        </Col>
      </Row>

      <HotspotAnalysisFilterDrawer
        open={showFilterSidebar}
        onClose={() => setShowFilterSidebar(false)}
      />
    </div>
  )
}
