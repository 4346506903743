import React, { ChangeEvent, KeyboardEvent, cloneElement, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiTable } from 'react-icons/hi'

import { Col, Row, message } from 'antd/es'

import { Report } from '@cozero/models'

import { graphSelectorAttributes } from '@/molecules/GraphSelectorDropdown'

import Card from '@/atoms/Card'
import Checkbox from '@/atoms/Checkbox'
import InputField from '@/atoms/InputField'
import Text from '@/atoms/Text'

import { COZERO_BLUE_BASE } from '@/styles/variables'
import { truncate } from '@/utils/string'

import classes from '../../classes.module.less'

const ReportsMenu = memo(
  ({
    searchValue,
    reports: reportsFromProps,
    selection,
    onSearch,
    onSelectionChanged,
  }: {
    searchValue?: string
    reports: Report[]
    selection: { [key: string]: boolean | undefined }
    onSearch: (pattern: string) => void
    onSelectionChanged: (values: { [key: string]: boolean | undefined }) => void
  }): JSX.Element => {
    const { t } = useTranslation('common')
    const [reports, setReports] = useState<(Report & { icon: JSX.Element })[]>([])

    useEffect(() => {
      const reportsWithIcons = reportsFromProps.map((report) => {
        const graphAttributes = graphSelectorAttributes.primary.find(
          (graphAttr) => graphAttr.name === report.type,
        )

        return graphAttributes
          ? { ...report, icon: cloneElement(graphAttributes.icon, { color: COZERO_BLUE_BASE }) }
          : {
              ...report,
              icon: <HiTable size={20} color={COZERO_BLUE_BASE} />,
            }
      })

      setReports(reportsWithIcons)
    }, [reportsFromProps])

    return (
      <>
        <Row className={classes.reportMenuHeader}>
          <Col span={18}>
            <h3 className={classes.reportMenuHeaderText}>{`${t('share.reports.add-board')}`}</h3>
          </Col>
          <Col span={6}>
            <div>
              <InputField
                type="search"
                placeholder={t('log.search')}
                allowClear
                className={classes.reportMenuSearchField}
                value={searchValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onSearch(e.target.value)}
                onPressEnter={(e: KeyboardEvent<HTMLInputElement>) => e.preventDefault()}
                data-cy="log-categories-search"
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          {reports.map((report) => (
            <Col key={report.id} span={12} className={classes.reportWrapper}>
              <Card
                title={
                  <>
                    <Row>
                      <Col span={3}>
                        <div className={classes.reportIconWrapper}>{report.icon}</div>
                      </Col>
                      <Col span={19}>
                        <Text ellipsis={{ tooltip: report.title }} fontWeight="semibold" size="xl">
                          {report.title}
                        </Text>
                      </Col>
                      <Col span={2}>
                        <Checkbox checked={selection[report.id]} />
                      </Col>
                    </Row>
                  </>
                }
                hideTooltip
                className={classes.reportCard}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()

                  const _selections = { ...selection }

                  const selectedReportsCount = Object.keys(_selections).reduce(
                    (acc, key) => (_selections[key] ? ++acc : acc),
                    0,
                  )

                  if (!_selections[report.id] && selectedReportsCount > 9) {
                    return message.warning(t('share.reports.errors.limit'))
                  }
                  onSelectionChanged({
                    ..._selections,
                    [report.id]: !_selections[report.id],
                  })
                }}
              >
                <Row justify="space-between">
                  <Col span={24}>
                    <div className={classes.reportCardTextWrapper}>
                      <Text fontWeight="medium" size="lg">
                        {truncate(report.description || '', 500)}
                      </Text>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </>
    )
  },
)

ReportsMenu.displayName = 'ReportsMenu'

export default ReportsMenu
