import {
  CreateHotspotAnalysisViewPinDto,
  HotspotAnalysisViewPinResultDto,
  HotspotAnalysisViewUnPinResultDto,
  RemoveHotspotAnalysisViewPinDto,
} from '@cozero/dtos'
import { actApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

import { TAG_EMISSION_HOTSPOTS_DATA, TAG_HOTSPOTS_PINS } from './constants'

const PIN = 'pin'
const UNPIN = 'unpin'

const actAnalyticsPinsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [PIN]: builder.mutation<HotspotAnalysisViewPinResultDto, CreateHotspotAnalysisViewPinDto>({
      query: (data) => ({
        url: actApiGatewayClient.hotspotPins.PIN,
        method: 'POST',
        data,
      }),
      invalidatesTags: [TAG_HOTSPOTS_PINS, TAG_EMISSION_HOTSPOTS_DATA],
    }),
    [UNPIN]: builder.mutation<HotspotAnalysisViewUnPinResultDto, RemoveHotspotAnalysisViewPinDto>({
      query: (data) => ({
        url: actApiGatewayClient.hotspotPins.UNPIN.replace(':pinId', data.pinId.toString()),
        method: 'DELETE',
        params: {
          viewId: data.viewId,
        },
      }),
      invalidatesTags: [TAG_HOTSPOTS_PINS, TAG_EMISSION_HOTSPOTS_DATA],
    }),
  }),
})

export const { usePinMutation, useUnpinMutation } = actAnalyticsPinsApiSlice

export default actAnalyticsPinsApiSlice
