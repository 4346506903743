import { HotspotAnalysisData } from '@cozero/models'

import { useAppSelector } from '@/redux'
import { useGetEmissionHotspotsDataQuery } from '@/redux/actAnalytics'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'

type UseEmissionHotspotsProps = {
  viewId: number
}

type UseEmissionHotspotsReturn = {
  data?: HotspotAnalysisData[]
  pins?: HotspotAnalysisData[]
  isLoading: boolean
  isError: boolean
}

export const useEmissionHotspots = ({
  viewId,
}: UseEmissionHotspotsProps): UseEmissionHotspotsReturn => {
  const businessUnit = useAppSelector(selectSelectedBusinessUnit)

  const { data, isLoading, isFetching, isError } = useGetEmissionHotspotsDataQuery(
    {
      viewId,
      businessUnitId: businessUnit?.id || -1,
    },
    { skip: typeof businessUnit?.id !== 'number' },
  )

  return {
    data: data?.data,
    pins: data?.pins,
    isLoading: isLoading || isFetching,
    isError,
  }
}
