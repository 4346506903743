import React from 'react'

import { Row, Space, Tooltip } from 'antd/es'

import { PageFilter } from '@cozero/models'

import BookmarkIcon from '@/atoms/Icons/Outlines/Bookmark'
import ClipboardListIcon from '@/atoms/Icons/Outlines/ClipboardList'
import CubeIcon from '@/atoms/Icons/Outlines/Cube'
import GlobeIcon from '@/atoms/Icons/Outlines/Globe'
import LocationMarkerIcon from '@/atoms/Icons/Outlines/LocationMarker'
import OfficeBuildingIcon from '@/atoms/Icons/Outlines/OfficeBuilding'
import SupplierIcon from '@/atoms/Icons/Outlines/Supplier'
import TagIcon from '@/atoms/Icons/Outlines/Tag'
import UserGroupIcon from '@/atoms/Icons/Outlines/UserGroup'
import XIcon from '@/atoms/Icons/Outlines/X'
import Tag from '@/atoms/Tag'

import classes from './FilterTags.module.less'

const filterIcons = {
  categoryId: <BookmarkIcon width={14} height={14} className={classes.filterIcon} />,
  locationId: <LocationMarkerIcon width={14} height={14} className={classes.filterIcon} />,
  businessUnit: <OfficeBuildingIcon width={14} height={14} className={classes.filterIcon} />,
  customer: <UserGroupIcon width={14} height={14} className={classes.filterIcon} />,
  supplier: <SupplierIcon width={14} height={14} className={classes.filterIcon} />,
  tag: (
    <>
      <OfficeBuildingIcon width={14} height={14} className={classes.filterIcon} />
      <TagIcon width={14} height={14} className={classes.filterIcon} />
    </>
  ),
  tags: (
    <>
      <CubeIcon width={14} height={14} className={classes.filterIcon} />
      <TagIcon width={14} height={14} className={classes.filterIcon} />
    </>
  ),
  territory: <GlobeIcon width={14} height={14} className={classes.filterIcon} />,
  description: <ClipboardListIcon width={14} height={14} className={classes.filterIcon} />,
}

const getIcon = (key: string | undefined): JSX.Element | string => {
  return filterIcons[key as keyof typeof filterIcons] || ''
}

const getMatchingLabels = (
  filter: IFilter,
  filterOptions: (PageFilter & {
    options: {
      key?: string
      value: string
      label: string
    }[]
  })[],
): string[] | string => {
  const matchingOption = filterOptions.find((option) => option.key === filter.key)

  if (!matchingOption) {
    return []
  }

  // Get the matching labels
  if (Array.isArray(filter.value)) {
    return filter.value
      .map((value) => {
        const matchingValueOption = matchingOption.options.find(
          (option) => option.value === value.toString(),
        )
        return matchingValueOption ? matchingValueOption.label : value.toString()
      })
      .filter((label) => label !== null) as string[]
  } else {
    // Handle single value
    const matchingValueOption = matchingOption.options?.find(
      (option) => option.value === filter.value.toString(),
    )
    return matchingValueOption ? matchingValueOption.label : filter.value
  }
}

const getDisplayContent = (
  filter: IFilter,
  filterOptions: (PageFilter & {
    options: {
      key?: string
      value: string
      label: string
    }[]
  })[],
): string => {
  const value = getMatchingLabels(filter, filterOptions)
  return Array.isArray(value) ? value.join(', ') : (value as string)
}

interface IFilter {
  key: string
  value: string[] | number[] | string
}

interface Props {
  filters: PageFilter[]
  openDrawer?: () => void
  onRemoveFilter: (filterKey: string) => void
  filterOptions: (PageFilter & {
    options: {
      key?: string
      value: string
      label: string
    }[]
  })[]
}

function FilterTags({ filters, onRemoveFilter, openDrawer, filterOptions }: Props): JSX.Element {
  return (
    <Row>
      {filters.map((filter) => {
        const { key, label } = filter
        const content = getDisplayContent(filter as IFilter, filterOptions)
        const isLong = content?.length > 45
        const displayValue = isLong ? `${content.slice(0, 45)}...` : content
        const icon = getIcon(key)

        const title = (
          <span>
            {icon}
            <span style={{ marginLeft: 2 }}>
              <strong>{label}:</strong>
            </span>
            <span style={{ marginLeft: 4 }}>{content}</span>
          </span>
        )

        return (
          <Tooltip title={title} key={key}>
            <Space>
              <Tag
                type="info"
                className={classes.tag}
                icon={icon}
                closable
                closeIcon={<XIcon width={8} height={8} className={classes.icon} />}
                onClick={openDrawer}
                onClose={() => onRemoveFilter(key as string)}
              >
                {displayValue}
              </Tag>
            </Space>
          </Tooltip>
        )
      })}
    </Row>
  )
}

export default FilterTags
