/* eslint react-hooks/exhaustive-deps: 2 */

import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { TablePaginationConfig } from 'antd/es'

const DEFAULT_PAGE_SIZE = 15

export const usePagination = (): TablePaginationConfig & {
  onChange: (pagination: TablePaginationConfig) => void
} => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [pageSize, setPageSize] = React.useState(
    Number(searchParams.get('pageSize')) || DEFAULT_PAGE_SIZE,
  )
  const [currentPage, setCurrentPage] = React.useState<TablePaginationConfig['current']>(
    Number(searchParams.get('currentPage')) || 1,
  )

  const onChange = React.useCallback(
    (pagination: TablePaginationConfig): void => {
      if (pagination.current && pagination.current !== currentPage) {
        setCurrentPage(pagination.current)
      }
    },
    [currentPage],
  )

  React.useEffect(() => {
    setSearchParams({
      pageSize: String(pageSize),
      currentPage: String(currentPage),
    })
  }, [setSearchParams, pageSize, currentPage])

  return {
    onChange,
    current: currentPage,
    pageSize,
    defaultPageSize: DEFAULT_PAGE_SIZE,
    hideOnSinglePage: true,
    onShowSizeChange: (_current, size) => {
      setPageSize(size)
    },
  }
}
