import { useCallback, useMemo } from 'react'

import moment from 'moment'

import { useAppSelector } from '@/redux'
import { selectSelectedBusinessUnit } from '@/redux/businessUnits'
import { useGetStrategyBuilderStateQuery } from '@/redux/strategyBuilder'

type UseIsReferencePeriodSelectedReturn = {
  /** validates if the provided ranges is same as the reference period */
  isReferencePeriod: (dateRange: [Date, Date] | undefined) => boolean
  /** Reference period (closed period used for forecast generation) */
  referencePeriod: [Date, Date] | undefined
}

/** Hook to get and verify reference period */
export const useReferencePeriod = (): UseIsReferencePeriodSelectedReturn => {
  const businessUnit = useAppSelector(selectSelectedBusinessUnit)
  const { data: strategyBuilderState } = useGetStrategyBuilderStateQuery(
    { businessUnitId: businessUnit?.id || -1 },
    { skip: typeof businessUnit?.id !== 'number' },
  )

  const referencePeriod: [Date, Date] | undefined = useMemo(() => {
    const startDate = strategyBuilderState?.forecast?.closedPeriod?.startDate
    const endDate = strategyBuilderState?.forecast?.closedPeriod?.endDate
    if (!startDate || !endDate) {
      return undefined
    }
    return [new Date(startDate), new Date(endDate)]
  }, [strategyBuilderState?.forecast?.id])

  const isReferencePeriod = useCallback(
    (dateRange: [Date, Date] | undefined) => {
      if (!dateRange || !referencePeriod) {
        return false
      }
      const [referencePeriodStart, referencePeriodEnd] = referencePeriod
      const [startDate, endDate] = dateRange
      return (
        moment(startDate).startOf('day').toISOString() ===
          moment(referencePeriodStart).startOf('day').toISOString() &&
        moment(endDate).startOf('day').toISOString() ===
          moment(referencePeriodEnd).startOf('day').toISOString()
      )
    },
    [strategyBuilderState, referencePeriod],
  )

  return { referencePeriod, isReferencePeriod }
}
