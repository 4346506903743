import React, { useMemo } from 'react'

import { ColumnType } from 'antd/lib/table'

import isEmpty from 'lodash/isEmpty'

import { ComputedReportDataDto } from '@cozero/dtos'

import Table from '@/molecules/Table'

import classes from './ComputedReportTable.module.less'

interface Props {
  data: ComputedReportDataDto[] | undefined
  emptyDescription?: string
}

interface ProcessedDataItem {
  key: string
  name: string
  groupValue: string | null
  id: number
  isGroupHeader?: boolean
  colorClass?: string
  [key: string]: string | number | boolean | undefined | null
}

interface ColumnData {
  key: string
  header: string
}

const getStringValue = (value: unknown): string => {
  if (typeof value === 'string') {
    return value
  }
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value)
  }
  return ''
}

const compareValues = (a: unknown, b: unknown): number => {
  const strA = getStringValue(a)
  const strB = getStringValue(b)
  return strA.localeCompare(strB)
}

const colorClasses = [
  classes.tableRowBlueBg,
  classes.tableRowGreenBg,
  classes.tableRowOrangeBg,
  classes.tableRowNeutralBlueBg,
  classes.tableRowPurpleBg,
  classes.tableRowYellowBg,
]

const groupColorMap = new Map<string, string>()

function ComputedReportTable({ data, emptyDescription }: Props): JSX.Element {
  const { processedData, columns } = useMemo(() => {
    if (!data || data.length === 0) {
      return {
        processedData: [] as ProcessedDataItem[],
        columns: [] as ColumnType<ProcessedDataItem>[],
      }
    }

    const uniqueColumns: ColumnData[] = Array.from(new Set(data.map((item) => item.columnKey))).map(
      (key) => ({
        key,
        header: getStringValue(data.find((item) => item.columnKey === key)?.columnHeader) || key,
      }),
    )

    const groupedData = data.reduce((acc, item) => {
      const rowKey = item.rowKey
      if (!acc[rowKey]) {
        acc[rowKey] = {
          key: rowKey,
          name: getStringValue(item.rowHeader),
          groupValue: isEmpty(item.groupValue) ? null : getStringValue(item.groupValue),
          id: item.id,
        }
      }
      if (item.valueType === 'NUMBER') {
        acc[rowKey][item.columnKey] = item.numberValue !== null ? item.numberValue : '--'
      } else {
        acc[rowKey][item.columnKey] = getStringValue(item.stringValue)
      }
      return acc
    }, {} as Record<string, ProcessedDataItem>)

    const sortedData = Object.values(groupedData).sort((a, b) => {
      const groupComparison = compareValues(a.groupValue, b.groupValue)
      return groupComparison !== 0 ? groupComparison : a.id - b.id
    })

    const processedData: ProcessedDataItem[] = []
    let currentGroupValue: string | null = null
    let colorIndex = 0

    sortedData.forEach((item) => {
      if (item.groupValue !== currentGroupValue) {
        processedData.push({
          key: `group-${item.groupValue}`,
          name: item.groupValue ?? '',
          groupValue: item.groupValue,
          id: -1,
          isGroupHeader: true,
          colorClass: colorClasses[colorIndex % colorClasses.length],
        })
        currentGroupValue = item.groupValue
        colorIndex++
      }
      processedData.push(item)
    })

    const columns: ColumnType<ProcessedDataItem>[] = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        width: 250,
        render: (text: string, record: ProcessedDataItem) =>
          record.isGroupHeader ? <strong>{text}</strong> : <span>{text}</span>,
      },
      ...uniqueColumns.map((col) => ({
        title: col.header,
        dataIndex: col.key,
        key: col.key,
        width: 250,
        render: (text: string, record: ProcessedDataItem) =>
          record.isGroupHeader ? null : <span className={classes.tableCell}>{text}</span>,
      })),
    ]

    return { processedData, columns }
  }, [data])

  return (
    <div>
      <Table
        emptyDescription={emptyDescription}
        columns={columns}
        dataSource={processedData}
        pagination={false}
        scroll={{ x: 'max-content', y: 600 }}
        rowClassName={(record: ProcessedDataItem) => {
          if (record.isGroupHeader) {
            const groupKey = record.groupValue ?? ''
            if (!groupColorMap.has(groupKey)) {
              const colorIndex = groupColorMap.size % colorClasses.length
              groupColorMap.set(groupKey, colorClasses[colorIndex])
            }
            const colorClass = groupColorMap.get(groupKey) || ''
            return `${classes.tableRowGroupHeader} ${colorClass}`
          }
          return ''
        }}
        className={classes.reportsTable}
      />
    </div>
  )
}

export default ComputedReportTable
