import React from 'react'
import { useTranslation } from 'react-i18next'

import { Spin } from 'antd/es'

import { debounce } from 'lodash-es'

import Select, { Option, SelectProps } from '@/atoms/Select'

import classes from './FiltersDrawer.module.less'

type Props = SelectProps & {
  multipleAllowed?: boolean
  onSearch?: (value: string) => void
}

export const SearchSelect = ({
  multipleAllowed,
  value,
  onChange,
  onSearch,
  loading,
  options,
  ...props
}: Props): React.ReactElement => {
  const { t } = useTranslation('common')

  // Extract only valid values that exist in options
  const optionValues = options?.map((option) => option.value) || []
  const filteredValue = React.useMemo(() => {
    if (multipleAllowed && Array.isArray(value)) {
      return value.filter((val) => optionValues.includes(val))
    }

    if (!multipleAllowed && optionValues.includes(value)) {
      return value
    }

    return multipleAllowed ? [] : null
  }, [value, optionValues, multipleAllowed])

  return (
    <Select
      {...props}
      showSearch
      className={classes.select}
      mode={multipleAllowed ? 'multiple' : undefined}
      placeholder={props.placeholder || t('log.filter.search')}
      value={filteredValue}
      onChange={(value, option) => {
        const cleanValue = multipleAllowed ? value : [value]
        const validValue = multipleAllowed
          ? cleanValue.filter((val: string | number | null | undefined) =>
              optionValues.includes(val),
            )
          : optionValues.includes(value)
          ? value
          : null

        onChange?.(validValue, option) // Pass only valid values to onChange
      }}
      onSearch={debounce((term) => onSearch?.(term), 500)}
      dropdownRender={(menu) => {
        if (loading) {
          return (
            <span className={classes.dropdownContainer}>
              <Spin />
            </span>
          )
        }
        return options?.length ? (
          menu
        ) : (
          <span className={classes.dropdownContainer}>{t('log.filter.search')}</span>
        )
      }}
    >
      {options?.map((option) => (
        <Option key={option.value} value={option.value} label={option.label}>
          {option.label}
        </Option>
      ))}
    </Select>
  )
}
