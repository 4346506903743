import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineDuplicate, HiTrash } from 'react-icons/hi'
import { HiOutlinePencilSquare } from 'react-icons/hi2'

import { Dropdown, DropdownProps, Menu } from 'antd'
import { ItemType } from 'antd/es/menu/hooks/useItems'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'

import { HotspotAnalysisRenameViewForm } from './HotspotAnalysisRenameViewForm/HotspotAnalysisRenameViewForm'

type HotspotAnalysisTabsWrapperDropdownMenuProps = {
  viewId: number
  onClickDuplicate: () => void
  onClickDelete: () => void
  includeDelete: boolean
  children: ReactNode
}

export const HotspotAnalysisMainDropdown = ({
  viewId,
  includeDelete,
  onClickDelete,
  onClickDuplicate,
  children,
}: HotspotAnalysisTabsWrapperDropdownMenuProps): React.ReactElement => {
  const { t } = useTranslation()
  const { trackAction: trackAnalyticsEvent } = useMixpanel()
  const [dropdownLayout, setDropdownLayout] = useState<'main' | 'rename'>('main')

  let props: DropdownProps = {}
  if (dropdownLayout === 'rename') {
    props = {
      open: true,
      dropdownRender: () => (
        <HotspotAnalysisRenameViewForm
          viewId={viewId}
          onCancel={() => setDropdownLayout('main')}
          onSubmit={() => {
            trackAnalyticsEvent(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'view-edited')
            setDropdownLayout('main')
          }}
        />
      ),
    }
  } else {
    const items: (ItemType & { 'data-cy'?: string })[] = [
      {
        key: '1',
        icon: <HiOutlinePencilSquare />,
        label: t('act.analytics.hotspotAnalysis.views.edit-name.title'),
        'data-cy': 'hotspot-view-rename',
        onClick: (e) => {
          e.domEvent.stopPropagation()
          e.domEvent.preventDefault()
          setDropdownLayout('rename')
        },
      },
      {
        key: '2',
        icon: <HiOutlineDuplicate />,
        label: t('act.analytics.hotspotAnalysis.views.duplicate'),
        'data-cy': 'hotspot-view-duplicate',
        onClick: () => {
          onClickDuplicate()
          trackAnalyticsEvent(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'view-duplicated')
        },
      },
    ]

    if (includeDelete) {
      items.push({
        key: '3',
        danger: true,
        icon: <HiTrash />,
        label: t('act.analytics.hotspotAnalysis.views.delete'),
        'data-cy': 'hotspot-view-delete',
        onClick: () => {
          onClickDelete()
          trackAnalyticsEvent(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'view-deleted')
        },
      })
    }

    props = {
      dropdownRender: () => (
        <Menu
          items={items}
          onClick={(e) => {
            e.domEvent.preventDefault()
          }}
        />
      ),
    }
  }

  return <Dropdown {...props}>{children}</Dropdown>
}
