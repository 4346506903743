import React, { useMemo, useRef } from 'react'

import { DatePicker } from 'antd/es'
import { SizeType } from 'antd/es/config-provider/SizeContext'

import moment, { Moment } from 'moment'

import classes from './DateRangePicker.module.less'

export interface RangePickerProps {
  value?: [Moment | null, Moment | null]
  className?: string
  popupClassName?: string
  drop?: string
  style?: React.CSSProperties
  format?: string
  visualStyle?: 'default' | 'ghost'
  ranges?: {
    [key: string]: [moment.Moment | null, moment.Moment | null]
  }
  bordered?: boolean
  disabled?: boolean | [boolean, boolean]
  allowClear?: boolean
  open?: boolean
  size?: SizeType
  separator?: JSX.Element
  onChange?: (filters: [Moment, Moment]) => void
  onCloseChange?: (filters: [Moment, Moment] | null) => void
  onOpenChange?: (open: boolean) => void
  picker?: 'year' | 'month' | 'date'
  showTime?: boolean
  disabledDate?: (current: Moment) => boolean
  inputReadOnly?: boolean
}

const { RangePicker } = DatePicker
function DateRangePicker({
  visualStyle,
  value,
  style,
  format,
  className,
  ranges: rangesFromProps,
  onChange,
  onOpenChange,
  onCloseChange,
  picker,
  showTime,
  ...props
}: RangePickerProps): JSX.Element {
  const classNames = useMemo(
    () => `${classes.dateRangePicker} ${visualStyle ? classes[visualStyle] : ''}`,
    [visualStyle],
  )

  const ranges = useMemo(() => {
    const rangesAux: { [key: string]: [Moment | null, Moment | null] } = {}
    rangesAux['Year-to-Date'] = [moment().utc().startOf('year'), moment().utc()]
    rangesAux['Last 12 months'] = [moment().utc().subtract(12, 'months'), moment().utc()]
    for (let i = 3; i >= 0; i--) {
      rangesAux[moment().subtract(i, 'year').year()] = [
        moment().utc().subtract(i, 'year').startOf('year').utc(),
        moment().utc().subtract(i, 'year').endOf('year').utc(),
      ]
    }
    return rangesAux
  }, [])

  /** stores current value to on close change */
  const currentValue = useRef<[Moment, Moment] | null>(null)

  return (
    <RangePicker
      {...props}
      style={style || { minWidth: 350 }}
      value={value}
      defaultValue={value}
      onChange={(dateRange) => {
        currentValue.current = [
          dateRange?.[0] ? dateRange[0].utc() : null,
          dateRange?.[1] ? dateRange[1].utc() : null,
        ] as [Moment, Moment]
        onChange &&
          onChange([
            dateRange?.[0] ? dateRange[0].utc() : null,
            dateRange?.[1] ? dateRange[1].utc() : null,
          ] as [Moment, Moment])
      }}
      onOpenChange={(isOpen) => {
        onOpenChange && onOpenChange(isOpen)
        if (!isOpen) {
          onCloseChange && onCloseChange(currentValue.current)
        }
      }}
      ranges={rangesFromProps || ranges}
      format={format || 'LL'}
      className={className || classNames}
      picker={picker}
      showTime={showTime}
    />
  )
}

export default DateRangePicker
