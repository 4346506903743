import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Table as AntdTable,
  ConfigProvider,
  Empty,
  Row,
  TablePaginationConfig,
  TableProps,
} from 'antd/es'
import {
  ColumnsType,
  ExpandableConfig,
  FilterValue,
  GetRowKey,
  SorterResult,
  TableRowSelection,
} from 'antd/es/table/interface'

import { GetComponentProps, RowClassName } from 'rc-table/lib/interface'

import defaultEmptyTableImage from '@/assets/empty-table.svg'

import classes from './Table.module.less'

interface Props<RecordType extends object> {
  /**
   *  Object that describes the columns to display, must include a `dataSource` and `name` field.
   */
  columns: ColumnsType<RecordType>
  /**
   *  The rows of type `T` to display.
   */
  dataSource: readonly RecordType[]
  /**
   *  An optional field name or function to return a unique key for the row.
   */
  rowKey: string | GetRowKey<RecordType>
  /**
   *  An optional class name to style the table.
   */
  className: string
  /**
   *  An optional class name or callback to style rows.
   */
  rowClassName: string | RowClassName<RecordType>
  /**
   *  An optional callback to perform when a row is selected.
   */
  rowSelection: TableRowSelection<RecordType>
  /**
   *  An optional configuration object to describe how rows expand.
   */
  expandable: ExpandableConfig<RecordType>
  /**
   *  Optional text to display when the table has no data.
   */
  emptyText: string
  /**
   *  Optional title to display when the table has no data.
   */
  emptyTitle: string
  /**
   *  Optional text to display when the table has no data.
   */
  emptyDescription: string
  /**
   *  Optional CTA to show when the table has no data (e.g. a button to create a new record).
   */
  emptyCTA: ReactElement
  /**
   *  Optional Image to show when the table has no data.
   */
  emptyImage: ReactElement
  /**
   *  An optional object to describe the pagination of the table, can also be set to `false` to disable.
   */
  pagination: TablePaginationConfig | false
  /**
   *  An optional object to describe the scroll behavior of the table. `x` sets horizonal scroll area, `y` sets vertical.
   */
  scroll: {
    x?: number | true | string
    y?: number | string
  }
  /**
   *  An optional object to make callbacks row dependent.
   */
  onRow: GetComponentProps<RecordType>
  /**
   *  Optional change callbacks.
   */
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
  ) => void
  /**
   *  An optional renderer for row summaries.
   */
  summary: (data: readonly RecordType[]) => React.ReactNode
  /**
   *  Toggle the header.
   */
  showHeader: boolean
  /**
   * Toggle the wrapper
   **/
  showWrapper?: boolean
  /**
   * Show loading spinner
   **/
  loading?: boolean
  /**
   * Children to customize table's column
   **/
  children?: React.ReactNode
  /**
   * The table-layout attribute of table element
   **/
  tableLayout?: TableProps<RecordType>['tableLayout']
}

const CustomEmptyComponent = ({
  emptyTitle,
  emptyDescription,
  emptyCTA,
  emptyImage,
}: {
  emptyTitle?: string
  emptyDescription?: string
  emptyCTA?: ReactElement
  emptyImage?: ReactElement
}): ReactElement => {
  const { t } = useTranslation('common')
  return (
    <Empty
      image={emptyImage ?? defaultEmptyTableImage}
      imageStyle={{ height: 108 }}
      description={
        <div>
          <Row justify={'center'}>
            <span className={classes.emptyTitle}>{emptyTitle ?? t('table.emptyTitle')}</span>
          </Row>
          <Row justify={'center'}>
            <span className={classes.emptyDescription}>
              {emptyDescription ?? t('table.emptyDescription')}
            </span>
          </Row>
          {emptyCTA ?? <Row>{emptyCTA}</Row>}
        </div>
      }
    />
  )
}

const Table = <T extends object>({
  columns,
  dataSource,
  rowKey,
  className,
  rowClassName,
  rowSelection,
  expandable,
  emptyText,
  pagination,
  scroll,
  onRow,
  onChange,
  summary,
  showHeader,
  showWrapper = true,
  loading,
  children,
  emptyTitle,
  emptyDescription,
  emptyCTA,
  emptyImage,
  tableLayout,
  ...rest
}: Partial<Props<T>>): ReactElement => (
  <div className={showWrapper ? classes.tableWrapper : ''}>
    <ConfigProvider
      renderEmpty={() => (
        <CustomEmptyComponent
          emptyTitle={emptyTitle}
          emptyDescription={emptyDescription}
          emptyCTA={emptyCTA}
          emptyImage={emptyImage}
        />
      )}
    >
      <AntdTable
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        rowKey={rowKey}
        className={className}
        rowClassName={rowClassName}
        rowSelection={rowSelection}
        expandable={expandable}
        locale={{ emptyText }}
        pagination={pagination}
        scroll={scroll}
        onRow={onRow}
        onChange={onChange}
        summary={summary}
        showHeader={showHeader}
        tableLayout={tableLayout}
        {...rest}
      >
        {children}
      </AntdTable>
    </ConfigProvider>
  </div>
)

Table.Summary = AntdTable.Summary
Table.Column = AntdTable.Column

export default Table
