/* eslint react-hooks/exhaustive-deps: 2 */
import React from 'react'

import Group from 'antd/lib/input/Group'

import { LogicOperatorInputValueDto } from '@cozero/dtos'

import InputField from '@/atoms/InputField'
import Select, { Option } from '@/atoms/Select'

import classes from './LogicOperatorInput.module.less'

/** Type for the individual options used in the Select component (operator selection) */
export type LogicOperatorOption = {
  value: LogicOperatorInputValueDto['operator']
  label: string
}

type LogicOperatorInputProps = {
  onChange?: (value: LogicOperatorInputValueDto | null) => void
  value?: LogicOperatorInputValueDto | null
  options?: LogicOperatorOption[]
  placeholder: string
}

export const LogicOperatorInput = ({
  value = {}, // Destructure and set default value as an empty object
  onChange,
  options = [],
  placeholder,
}: LogicOperatorInputProps): JSX.Element => {
  const defaultSelectdOptionValue = options?.[0].value

  return (
    <Group compact style={{ display: 'flex' }} className={classes.wrapper}>
      {/* Select component for choosing the logic operator */}
      <Select
        size="large"
        className={classes.select}
        defaultValue={defaultSelectdOptionValue}
        value={value?.operator}
        onChange={(operator) => onChange?.({ ...value, operator })}
      >
        {options.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.label} {/* Display the label for each option */}
          </Option>
        ))}
      </Select>

      {/* Input component for entering the value */}
      <InputField
        format
        type="number"
        value={value?.value}
        className={classes.input}
        placeholder={placeholder}
        onChange={(inputValue) =>
          onChange?.({
            operator: value?.operator ?? defaultSelectdOptionValue, // Ensure the operator is set, default to the first option
            value: inputValue ? Number(inputValue) : undefined, // Capture the current input value
          })
        }
      />
    </Group>
  )
}
