/* eslint react-hooks/exhaustive-deps: 2 */

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineFilter } from 'react-icons/hi'

import classNames from 'classnames'

import Button from '@/atoms/Button'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppSelector } from '@/redux'
import { selectHotspotAnalysisFiltersCount } from '@/redux/actAnalytics/selectors'

import classes from './HotspotAnalysisFilterButton.module.less'

interface Props {
  setShowFilterSidebar: (show: boolean) => void
  disabled?: boolean
}

export const HotspotAnalysisFilterButton = ({
  setShowFilterSidebar,
  disabled = false,
}: Props): ReactElement => {
  const { t } = useTranslation()
  const count = useAppSelector(selectHotspotAnalysisFiltersCount)

  return (
    <Button
      disabled={disabled}
      category={AnalyticsCategories.ACT_HOTSPOT_ANALYSIS}
      action="open-filters-drawer"
      onClick={() => setShowFilterSidebar(true)}
      prefixIcon={<HiOutlineFilter />}
      type="ghost"
      className={classNames(classes.button, { [classes.active]: count > 0 })}
      data-cy="hotspot-view-filters"
    >
      {t('act.analytics.hotspotAnalysis.filters.button')}

      {count > 0 && (
        <span className={classes.count} data-cy="hotspot-view-filters-count">
          {count}
        </span>
      )}
    </Button>
  )
}
