import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom'

import { DatePicker, Divider, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { Col, Row, Skeleton, Tooltip, message } from 'antd/es'

import { SyncOutlined } from '@ant-design/icons'
import moment, { Moment } from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'

import { ComputedReportDto, CreateComputedReportDto } from '@cozero/dtos'
import { User } from '@cozero/models'
import { routes } from '@cozero/utils'

import { BusinessUnitTreeDropdown } from '@/organisms/BusinessUnitTreeDropdown'
import ComputedReportTable from '@/organisms/ComputedReportTable'

import ComputedReportStatus from '@/molecules/ComputedReportStatus'
import ComputedReportsExcelDownloadButton from '@/molecules/ComputedReportsExcelDownloadButton'

import Alert from '@/atoms/Alert'
import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import LoadingSpinner from '@/atoms/LoadingSpinner'
import Text from '@/atoms/Text'
import Title from '@/atoms/Title'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useAppSelector } from '@/redux'
import { selectUser } from '@/redux/auth'
import {
  GetLatestComputedReportParams,
  useCreateComputedReportMutation,
  useGetComputedReportByIdQuery,
  useGetComputedReportTypesQuery,
  useLazyGetLatestComputedReportDataQuery,
} from '@/redux/computedReports/api'
import { GRAY_400 } from '@/styles/variables'

import classes from './ComputedReport.module.less'
import { formatComputedReportData } from './ComputedReport.utils'

const { RangePicker } = DatePicker

interface ComputedReportForm {
  baseYear: RangeValue<Moment>
  reportingYear: RangeValue<Moment>
  businessUnitId: number
}

const getFormValuesFromReport = ({
  runParameters,
  businessUnitId,
}: ComputedReportDto): ComputedReportForm => {
  return {
    businessUnitId,
    baseYear: [moment(runParameters.baseYearStart), moment(runParameters.baseYearEnd)],
    reportingYear: [
      moment(runParameters.reportingYearStart),
      moment(runParameters.reportingYearEnd),
    ],
  }
}

const getQueryParamsFromForm = (
  formValues: ComputedReportForm,
  key: string | undefined,
): GetLatestComputedReportParams | undefined => {
  const { businessUnitId, baseYear, reportingYear } = formValues

  if (
    !businessUnitId ||
    !key ||
    !baseYear?.[0] ||
    !baseYear?.[1] ||
    !reportingYear?.[0] ||
    !reportingYear?.[1]
  ) {
    return undefined
  }

  return {
    businessUnitId: businessUnitId,
    computedReportTypeKey: key,
    baseYearStart: baseYear[0].clone().format('YYYY-MM-DD'),
    baseYearEnd: baseYear[1].clone().endOf('month').format('YYYY-MM-DD'),
    reportingYearStart: reportingYear[0].clone().format('YYYY-MM-DD'),
    reportingYearEnd: reportingYear[1].clone().endOf('month').format('YYYY-MM-DD'),
  }
}

function formatDate(value: string, user: User | undefined): string {
  moment.locale(user?.locale || 'en')
  const date = moment(value)
  const now = moment()
  const diffDays = now.diff(date, 'days')
  const sameYear = date.year() === now.year()

  if (diffDays < 7) {
    return date.fromNow()
  } else if (sameYear) {
    return date.format('Do MMMM')
  } else {
    return date.format('Do MMMM, YYYY')
  }
}

function compareSheetKeys(a: string, b: string): number {
  const aParts: number[] = a.split('_')[1].split('.').map(Number)
  const bParts: number[] = b.split('_')[1].split('.').map(Number)

  for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
    const aPart: number = aParts[i] || 0
    const bPart: number = bParts[i] || 0

    if (aPart > bPart) {
      return 1
    }
    if (aPart < bPart) {
      return -1
    }
  }

  return 0
}

interface UseGetReportFromRouteArgs {
  routeId: string | undefined
  currentSelectedReport: ComputedReportDto | null | undefined
}

interface UseGetReportFromRouteReturn {
  formValuesFromRoute: ComputedReportForm | null
  reportFromRoute: ComputedReportDto | null | undefined
  isFetchingFromUrl: boolean
  routeIs404: boolean
}

const useGetReportFromRoute = ({
  routeId,
  currentSelectedReport,
}: UseGetReportFromRouteArgs): UseGetReportFromRouteReturn => {
  const pollingInterval = useMemo(() => {
    if (routeId && currentSelectedReport?.request.state === 'PROCESSING') {
      return 1000 * 10
    }
    return undefined
  }, [currentSelectedReport?.request.state, routeId])

  const skipFetchById = useMemo(() => {
    if (pollingInterval) {
      return false
    }
    if (routeId && currentSelectedReport?.id !== Number(routeId)) {
      return false
    }

    return true
  }, [currentSelectedReport, routeId, pollingInterval])

  const {
    currentData: reportFromRoute,
    isFetching: isFetchingFromUrl,
    error,
  } = useGetComputedReportByIdQuery(routeId!, {
    skip: skipFetchById,
    pollingInterval,
  })

  const routeIs404 = useMemo(() => {
    if (error && 'status' in error) {
      return error.status === 404
    }
    return false
  }, [error])

  const formValuesFromRoute = useMemo(
    () => (reportFromRoute ? getFormValuesFromReport(reportFromRoute) : null),
    [reportFromRoute?.id],
  )

  return {
    formValuesFromRoute,
    reportFromRoute,
    isFetchingFromUrl,
    routeIs404,
  }
}

const disabledDate = (
  current: Moment,
  startDate: Moment | null | undefined,
  endDate: Moment | null | undefined,
): boolean => {
  if (!startDate && !endDate) {
    // If no start date is selected, don't disable any date
    return false
  }

  if (startDate) {
    // Only enable the date that is exactly 12 months after the start date
    const exactEndDate = startDate.clone().startOf('month').add(12, 'months').subtract(1, 'days')
    return !current.isSame(exactEndDate, 'day')
  }

  // If end date is selected, allow only the date that is exactly 12 months before the end date
  if (endDate) {
    const exactStartDate = endDate.clone().startOf('month').subtract(11, 'months')
    return !current.isSame(exactStartDate, 'day')
  }

  return false
}

const ComputedReport = (): ReactElement => {
  const { t } = useTranslation('common')
  const { key, id: routeId } = useParams()

  const navigate = useNavigate()

  const [form] = Form.useForm<ComputedReportForm>()
  const formValues = {
    businessUnitId: Form.useWatch('businessUnitId', form) as ComputedReportForm['businessUnitId'],
    baseYear: Form.useWatch('baseYear', form) as ComputedReportForm['baseYear'],
    reportingYear: Form.useWatch('reportingYear', form) as ComputedReportForm['reportingYear'],
  }

  const [report, setReport] = useState<ComputedReportDto | undefined | null>(null)
  const [currentTab, setCurrentTab] = useState<string>('')
  const user = useAppSelector(selectUser)

  const formattedReportData = useMemo(
    () => (report?.request.data ? formatComputedReportData(report.request.data) : null),
    [report],
  )

  const queryParams = useMemo(
    () => getQueryParamsFromForm(formValues, key),
    [key, ...Object.values(formValues)],
  )

  const { data: computedReportTypes = [] } = useGetComputedReportTypesQuery()
  const computedReportTypeName = useMemo(
    () => computedReportTypes.find((type) => type.key === key)?.name,
    [key, computedReportTypes],
  )

  const { reportFromRoute, routeIs404, formValuesFromRoute } = useGetReportFromRoute({
    currentSelectedReport: report,
    routeId,
  })

  const [getLatestComputedReport, result] = useLazyGetLatestComputedReportDataQuery()
  const {
    isLoading: latestReportIsLoading,
    isFetching: latestReportIsFetching,
    error: latestReportError,
  } = result
  const latestReportIs404 = useMemo(() => {
    if (latestReportError && 'status' in latestReportError) {
      return latestReportError.status === 404
    }
    return false
  }, [latestReportError])

  const [createComputedReport, { isLoading: isCreateComputedReportLoading }] =
    useCreateComputedReportMutation()

  useEffect(() => {
    if (!key) {
      return
    }

    if (routeIs404) {
      navigate(`${routes.overview.computedReport.view.replace(':key', key)}`)
    } else if (reportFromRoute && formValuesFromRoute) {
      setReport(reportFromRoute)
      form.setFieldsValue(formValuesFromRoute)
    } else if (!routeId) {
      setReport(undefined)

      // this indicates a manual reset of the page
      // through breadcrumbs or browser navigation
      if (queryParams && !latestReportIs404) {
        form.setFieldsValue({
          businessUnitId: undefined,
          baseYear: [null, null],
          reportingYear: [null, null],
        })
      }
    }
  }, [routeIs404, reportFromRoute, routeId, key])

  const tabItems: TabsProps['items'] = useMemo(() => {
    if (!formattedReportData) {
      return []
    }

    const sheetKeysMap: { [key: string]: boolean } = {}
    const sheetKeys: string[] = []

    formattedReportData.forEach((item) => {
      if (item?.sheetKey && !sheetKeysMap[item.sheetKey]) {
        sheetKeysMap[item.sheetKey] = true
        sheetKeys.push(item.sheetKey)
      }
    })

    const sortedSheetKeys: string[] = [...sheetKeys].sort(compareSheetKeys)

    return sortedSheetKeys.map((sheetKey) => {
      const sheetData = formattedReportData.filter(
        (item) => item.sheetKey === sheetKey && (item.columnHeader || item.rowHeader),
      )
      const sheetName = (sheetData[0]?.sheetName || sheetKey).toString()

      const isLong = sheetName.length > 10
      const displayLabel = isLong ? `${sheetName.slice(0, 10)}...` : sheetName

      return {
        key: sheetKey,
        label: (
          <Tooltip title={isLong ? sheetName : ''}>
            <span className={classes.tabPane}>{displayLabel}</span>
          </Tooltip>
        ),
        children: <ComputedReportTable data={sheetData} />,
      }
    })
  }, [formattedReportData])

  const onTabChange = (key: string): void => {
    setCurrentTab(key)
  }

  useEffect(() => {
    if (tabItems.length > 0 && !currentTab) {
      setCurrentTab(tabItems[0].key)
    }
  }, [tabItems, currentTab])

  const downloadButtonDisabled =
    latestReportIsLoading ||
    latestReportIsFetching ||
    !report?.request.data?.length ||
    report.request.state !== 'SUCCESS'
  const generateReportButtonDisabled =
    latestReportIsFetching || Boolean(report?.request.state == 'PROCESSING') || !queryParams

  const handleUpdate = async (): Promise<void> => {
    if (!key) {
      return
    }

    try {
      await form.validateFields()

      const createReportDto = queryParams as unknown as CreateComputedReportDto
      if (!createReportDto) {
        return
      }

      const response = await createComputedReport(createReportDto).unwrap()
      // keeping the latestReport data up to date is necessary for navigation logic
      getLatestComputedReport(queryParams!)

      setReport(response)
      navigate(`${routes.overview.computedReport.view.replace(':key', key)}/${response.id}`)
    } catch (error) {
      message.error(t('computed-reports.error'))
    }
  }

  const handleDateFieldChange = (
    fieldName: Exclude<keyof ComputedReportForm, 'businessUnitId'>,
    values: RangeValue<Moment>,
  ): void => {
    if (values && values.length === 2 && values[0] && !values[1]) {
      // If only start date is selected, set it and calculate the end date
      const startDate = values[0].clone().startOf('month')
      form.setFieldValue(fieldName, [startDate, null])
    } else if (values && values.length === 2 && !values[0] && values[1]) {
      // If only end date is selected, set it and calculate the start date
      const endDate = values[1].clone().endOf('month')
      form.setFieldValue(fieldName, [null, endDate])
    } else if (values && values.length === 2 && values[0] && values[1]) {
      // If both dates are selected, ensure the end date is exactly 12 months after the start
      const startDate = values[0].startOf('month')
      const endDate = values[1].clone().endOf('month')
      form.setFieldValue(fieldName, [startDate, endDate])
    } else {
      form.setFieldValue(fieldName, null)
    }
  }

  const renderContent = (): ReactElement => {
    if (report?.request.state === 'PROCESSING' || latestReportIsFetching) {
      return <Skeleton active />
    }

    if (report?.request.state === 'FAILURE') {
      return (
        <>
          <Alert type="error">
            {report?.request?.error === null
              ? t('computed-reports.data.error-loading')
              : String(report?.request?.error) || t('computed-reports.data.error-loading')}
          </Alert>
          <ComputedReportTable data={undefined} />
        </>
      )
    }

    if (!formattedReportData) {
      return <ComputedReportTable data={undefined} />
    }

    if (tabItems.length === 1) {
      return (
        <ComputedReportTable
          data={formattedReportData.filter((cell) => cell.columnHeader || cell.rowHeader)}
        />
      )
    }

    return (
      <Tabs
        className={classes.computedReportTabs}
        style={{ width: '100%' }}
        activeKey={currentTab}
        items={tabItems}
        onChange={onTabChange}
      />
    )
  }

  const handleFieldChange = async (): Promise<void> => {
    if (!key) {
      return
    }

    const params = getQueryParamsFromForm(form.getFieldsValue(), key)

    if (params) {
      const { data } = await getLatestComputedReport(params)
      setReport(data)

      if (data) {
        return navigate(`${routes.overview.computedReport.view.replace(':key', key)}/${data.id}`)
      } else if (routeId) {
        navigate(`${routes.overview.computedReport.view.replace(':key', key)}`)
      }
    } else if (routeId) {
      navigate(`${routes.overview.computedReport.view.replace(':key', key)}`)
    }
  }

  if (!computedReportTypeName) {
    return <LoadingSpinner />
  }

  return (
    <>
      <div style={{ paddingBottom: '24px' }}>
        <Row justify="space-between" align="middle" className={classes.header}>
          <Col span={12}>
            <div className={classes.titleStatusContainer}>
              <Title as="h1">{computedReportTypeName}</Title>
              <ComputedReportStatus status={report?.request.state} />
            </div>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ComputedReportsExcelDownloadButton
              strategyKey={key}
              disabled={downloadButtonDisabled}
              jsonData={formattedReportData ?? undefined}
              fileName={key}
            />
          </Col>
        </Row>
      </div>
      <Divider style={{ margin: '12px 0' }} />
      <Row>
        <Col>
          <Title as="h2" size="sm">
            {t('computed-reports.form.title')}
          </Title>
        </Col>
      </Row>
      <div style={{ paddingTop: '24px' }}>
        <Form form={form} category={AnalyticsCategories.CUSTOM_REPORTS} layout="vertical">
          <Row gutter={60}>
            <Col>
              <Form.Item
                label={t('computed-reports.form.base-year.label')}
                name="baseYear"
                rules={[
                  {
                    required: true,
                    message: t('computed-reports.form.base-year.required-message'),
                  },
                ]}
                tooltip={{
                  title: t('computed-reports.form.base-year.tooltip'),
                  icon: <HiOutlineInformationCircle color={GRAY_400} />,
                }}
              >
                <RangePicker
                  picker="month"
                  disabledDate={(current) =>
                    disabledDate(current, formValues.baseYear?.[0], formValues.baseYear?.[1])
                  }
                  onCalendarChange={(v) => {
                    handleDateFieldChange('baseYear', v)
                    handleFieldChange()
                  }}
                  format="YYYY-MM"
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={t('computed-reports.form.reporting-year.label')}
                name="reportingYear"
                rules={[
                  {
                    required: true,
                    message: t('computed-reports.form.reporting-year.required-message'),
                  },
                ]}
              >
                <RangePicker
                  picker="month"
                  disabledDate={(current) =>
                    disabledDate(
                      current,
                      formValues.reportingYear?.[0],
                      formValues.reportingYear?.[1],
                    )
                  }
                  onCalendarChange={(v) => {
                    handleDateFieldChange('reportingYear', v)
                    handleFieldChange()
                  }}
                  format="YYYY-MM"
                />
              </Form.Item>
            </Col>
            <Col style={{ minWidth: '360px' }}>
              <Form.Item
                label={t('computed-reports.form.business-unit.label')}
                name="businessUnitId"
                rules={[
                  {
                    required: true,
                    message: t('computed-reports.form.business-unit.required-message'),
                  },
                ]}
              >
                <BusinessUnitTreeDropdown
                  withDefaultValue={false}
                  dark={true}
                  local={true}
                  onChange={(v) => {
                    form.setFieldValue('businessUnitId', v)
                    handleFieldChange()
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" style={{ marginTop: '16px' }}>
            <Col>
              <Form.Item>
                <Button
                  htmlType="submit"
                  action="generate-report"
                  type="primary"
                  prefixIcon={<SyncOutlined />}
                  style={{ marginRight: 8 }}
                  onClick={handleUpdate}
                  disabled={generateReportButtonDisabled}
                  loading={isCreateComputedReportLoading}
                >
                  {t('computed-reports.form.generate-report-button')}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              {report?.request.state === 'SUCCESS' && (
                <Form.Item>
                  <Text>
                    {t('computed-reports.data.created-at-label')}{' '}
                    {formatDate(report.updatedAt.toString(), user) || 'N/A'}
                  </Text>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </div>
      <Row>
        <Col span={24}>
          <Title as="h2" size="sm">
            {t('computed-reports.form.subtitle')}
          </Title>

          {renderContent()}
        </Col>
      </Row>
    </>
  )
}

export default ComputedReport
