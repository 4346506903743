import React, { ReactElement, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Col, Form, Input, Row, Spin } from 'antd/es'
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select'

import { FilterKey } from '..'
import { debounce } from 'lodash-es'

import { PageFilter, RelationshipFilter } from '@cozero/models'

import BookmarkIcon from '@/atoms/Icons/Outlines/Bookmark'
import ClipboardListIcon from '@/atoms/Icons/Outlines/ClipboardList'
import CubeIcon from '@/atoms/Icons/Outlines/Cube'
import GlobeIcon from '@/atoms/Icons/Outlines/Globe'
import LocationMarkerIcon from '@/atoms/Icons/Outlines/LocationMarker'
import OfficeBuildingIcon from '@/atoms/Icons/Outlines/OfficeBuilding'
import SupplierIcon from '@/atoms/Icons/Outlines/Supplier'
import TagIcon from '@/atoms/Icons/Outlines/Tag'
import UserIcon from '@/atoms/Icons/Outlines/User'
import UserGroupIcon from '@/atoms/Icons/Outlines/UserGroup'
import Select, { Option } from '@/atoms/Select'

import { selectSelectedBusinessUnit, useGetActiveBusinessUnitsQuery } from '@/redux/businessUnits'
import { useGetActiveLocationsQuery } from '@/redux/locations'
import { useGetActiveProductsQuery } from '@/redux/products'

import classes from './../FiltersDrawer.module.less'

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Select {
  export type value = number[]
  export type option = BaseOptionType | DefaultOptionType | (BaseOptionType | DefaultOptionType)[]
}
interface Props {
  featuresAllowed: string[]
  filters: (PageFilter & { key: FilterKey })[]
  filterOptions: (PageFilter & {
    options: {
      key?: string
      value: string
      label: string
    }[]
  })[]
  values: {
    [FilterKey.CATEGORY]: number[]
    [FilterKey.LOCATION]: number[]
    [FilterKey.BUSINESS_UNIT]: number[]
    [FilterKey.PRODUCT]: number[]
    [FilterKey.TAG]: string[]
    [FilterKey.PRODUCT_TAGS]: string[]
    [FilterKey.CUSTOMER]: number[]
    [FilterKey.SUPPLIER]: number[]
    [FilterKey.RESPONSIBLE]: number[]
    [FilterKey.DESCRIPTION]: string
    [FilterKey.SCOPE]: number[]
    [FilterKey.TERRITORY]: number[]
    [FilterKey.ACTION_AREA]: number[]
  }
  onChange: (filter: PageFilter & { key: FilterKey }, value: Select.value | string) => void
  onChangeCondition: (
    filter: RelationshipFilter & { key: FilterKey },
    value: Select.value | string,
  ) => void
  hideFilters?: FilterKey[]
}

const LocationFiltersForm = ({
  filterOptions,
  values,
  featuresAllowed,
  onChange,
  onChangeCondition,
  filters,
  hideFilters,
}: Props): ReactElement => {
  const { t } = useTranslation('')
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit)
  const multipleAllowed = featuresAllowed.includes('multiple-filtering')

  const [searchTerms, setSearchTerms] = useState({
    [FilterKey.LOCATION]: '',
    [FilterKey.BUSINESS_UNIT]: '',
    [FilterKey.PRODUCT]: '',
  })

  const { data: paginatedLocations, isLoading: locationLoading } = useGetActiveLocationsQuery(
    {
      selectedBusinessUnitId: selectedBusinessUnit?.id ?? -1,
      search: searchTerms[FilterKey.LOCATION],
    },
    { skip: !selectedBusinessUnit },
  )

  const { data: paginatedProducts } = useGetActiveProductsQuery(
    {
      businessUnitKey: selectedBusinessUnit?.key ?? '',
      search: searchTerms[FilterKey.PRODUCT],
    },
    { skip: !selectedBusinessUnit },
  )
  const { data: paginatedBusinessUnits, isLoading: businessUnitLoading } =
    useGetActiveBusinessUnitsQuery(
      {
        businessUnitScopeId: selectedBusinessUnit?.id ?? -1,
        search: searchTerms[FilterKey.BUSINESS_UNIT],
      },
      { skip: !selectedBusinessUnit },
    )

  const handleChange = (value: Select.value | string, key: string): void => {
    const selectedFilter = filterOptions?.find((x) => x.key === key)
    if (selectedFilter) {
      onChange(selectedFilter as PageFilter & { key: FilterKey }, value)
    }
  }

  const handleChangeCondition = (value: Select.value | string, key: string): void => {
    const selectedFilter = filterOptions?.find((x) => x.key === key)
    if (selectedFilter) {
      onChangeCondition(selectedFilter as RelationshipFilter & { key: FilterKey }, value)
    }
  }

  const {
    categoryId: categoryOptions,
    ownerId: createdByOptions,
    tag: tagOptions,
    tags: productTagOptions,
    customer: customerOptions,
    supplier: supplierOptions,
    locationId: locationOptions,
    territory: territoryOptions,
    businessUnit: businessUnitOptions,
  } = useMemo(() => {
    const options = filterOptions.reduce(
      (acc, value) => {
        return {
          ...acc,
          [value.key as string]:
            value.options?.map((x) => ({
              ...x,
              value: +x.value,
              key: x.key ? +x.key : x.key,
            })) || [],
        }
      },
      {
        [FilterKey.CATEGORY]: [],
        [FilterKey.TAG]: [],
        [FilterKey.PRODUCT_TAGS]: [],
        [FilterKey.CUSTOMER]: [],
        [FilterKey.SUPPLIER]: [],
        [FilterKey.RESPONSIBLE]: [],
        [FilterKey.SCOPE]: [],
        [FilterKey.TERRITORY]: [],
        [FilterKey.ACTION_AREA]: [],
      },
    )
    return {
      ...options,
      [FilterKey.LOCATION]:
        paginatedLocations?.map((d) => (
          <Option key={d.id} value={d.id} label={d.name}>
            {d.name}
          </Option>
        )) || [],
      [FilterKey.PRODUCT]:
        paginatedProducts?.map((d) => (
          <Option key={d.id} value={d.id} label={d.name}>
            {d.name}
          </Option>
        )) || [],
      [FilterKey.BUSINESS_UNIT]:
        paginatedBusinessUnits?.map((d) => (
          <Option key={d.id} value={d.id} label={d.title}>
            {d.title}
          </Option>
        )) || [],
    }
  }, [filterOptions, paginatedLocations, paginatedBusinessUnits, paginatedProducts])

  const onSearch = (value: string, key: string): void => {
    setSearchTerms({ ...searchTerms, [key]: value })
  }

  return (
    <>
      <Form layout="vertical" hideRequiredMark>
        {!hideFilters?.includes(FilterKey.CATEGORY) && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={classes.label}>
                    <BookmarkIcon width={14} height={14} />
                    {t('log.category')}
                  </span>
                }
              >
                <Select
                  mode={multipleAllowed ? 'multiple' : undefined}
                  size="large"
                  placeholder={t('log.filter.placeholder')}
                  value={values.categoryId}
                  options={categoryOptions}
                  optionFilterProp="label"
                  onChange={(value) => {
                    const cleanValue = multipleAllowed ? value : [value]
                    handleChange(cleanValue, FilterKey.CATEGORY)
                  }}
                  style={{ width: '100%' }}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideFilters?.includes(FilterKey.LOCATION) && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={classes.label}>
                    <LocationMarkerIcon width={14} height={14} />
                    {t('log.location')}
                  </span>
                }
              >
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  mode={multipleAllowed ? 'multiple' : undefined}
                  size="large"
                  placeholder={t('log.filter.search')}
                  value={values.locationId}
                  onChange={(value) => {
                    const cleanValue = multipleAllowed ? value : [value]
                    handleChange(cleanValue, FilterKey.LOCATION)
                  }}
                  onSearch={debounce((term) => onSearch(term, FilterKey.LOCATION), 100)}
                  style={{ width: '100%' }}
                  dropdownRender={(menu) => {
                    if (locationLoading) {
                      return (
                        <span className={classes.dropdownContainer}>
                          <Spin />
                        </span>
                      )
                    }
                    return locationOptions?.length ? (
                      menu
                    ) : (
                      <span className={classes.dropdownContainer}>{t('log.filter.search')}</span>
                    )
                  }}
                >
                  {locationOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        {featuresAllowed.includes('business-units') &&
          !hideFilters?.includes(FilterKey.BUSINESS_UNIT) && (
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <span className={classes.label}>
                      <OfficeBuildingIcon width={14} height={14} />
                      {t('business-unit.name')}
                    </span>
                  }
                >
                  <Select
                    showSearch
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    mode={multipleAllowed ? 'multiple' : undefined}
                    size="large"
                    placeholder={t('log.filter.search')}
                    value={values.businessUnit}
                    onChange={(value) => {
                      const cleanValue = multipleAllowed ? value : [value]
                      handleChange(cleanValue, FilterKey.BUSINESS_UNIT)
                    }}
                    onSearch={debounce((term) => onSearch(term, FilterKey.BUSINESS_UNIT), 100)}
                    style={{ width: '100%' }}
                    dropdownRender={(menu) => {
                      if (businessUnitLoading) {
                        return (
                          <span className={classes.dropdownContainer}>
                            <Spin />
                          </span>
                        )
                      }
                      return businessUnitOptions?.length ? (
                        menu
                      ) : (
                        <span className={classes.dropdownContainer}>{t('log.filter.search')}</span>
                      )
                    }}
                  >
                    {businessUnitOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
        {!hideFilters?.includes(FilterKey.RESPONSIBLE) && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={classes.label}>
                    <UserIcon width={14} height={14} />
                    {t('log.owner')}
                  </span>
                }
              >
                <Select
                  mode={multipleAllowed ? 'multiple' : undefined}
                  size="large"
                  placeholder={t('log.filter.placeholder')}
                  fieldNames={{ label: 'label', value: 'value' }}
                  optionFilterProp="label"
                  value={values.ownerId}
                  options={createdByOptions}
                  onChange={(value) => {
                    const cleanValue = multipleAllowed ? value : [value]
                    handleChange(cleanValue, FilterKey.RESPONSIBLE)
                  }}
                  style={{ width: '100%' }}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideFilters?.includes(FilterKey.CUSTOMER) && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={classes.label}>
                    <UserGroupIcon width={14} height={14} />
                    {t('customer.customer')}
                  </span>
                }
              >
                <Select
                  mode={multipleAllowed ? 'multiple' : undefined}
                  size="large"
                  placeholder={t('log.filter.placeholder')}
                  fieldNames={{ label: 'label', value: 'value' }}
                  optionFilterProp="label"
                  value={values.customer}
                  options={customerOptions}
                  onChange={(value) => {
                    const cleanValue = multipleAllowed ? value : [value]
                    handleChange(cleanValue, FilterKey.CUSTOMER)
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideFilters?.includes(FilterKey.SUPPLIER) && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={classes.label}>
                    <SupplierIcon width={14} height={14} />
                    {t('layout.supplier')}
                  </span>
                }
              >
                <Select
                  mode={multipleAllowed ? 'multiple' : undefined}
                  size="large"
                  placeholder={t('log.filter.placeholder')}
                  fieldNames={{ label: 'label', value: 'value' }}
                  optionFilterProp="label"
                  value={values.supplier}
                  options={supplierOptions}
                  onChange={(value) => {
                    const cleanValue = multipleAllowed ? value : [value]
                    handleChange(cleanValue, FilterKey.SUPPLIER)
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideFilters?.includes(FilterKey.TAG) && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={classes.label}>
                    <OfficeBuildingIcon width={14} height={14} />
                    <TagIcon width={14} height={14} />
                    {t('location.tags')}
                  </span>
                }
              >
                <Row justify="space-between">
                  <Col span={6}>
                    <Select
                      size="large"
                      placeholder={t('log.filter.placeholder')}
                      defaultValue={
                        (
                          filterOptions.find(
                            (obj) => obj.key === FilterKey.TAG,
                          ) as RelationshipFilter
                        )?.conditions?.[0].key
                      }
                      value={
                        (filters?.find((obj) => obj.key === FilterKey.TAG) as RelationshipFilter)
                          ?.selectedCondition?.key
                      }
                      options={(
                        filterOptions.find((obj) => obj.key === FilterKey.TAG) as RelationshipFilter
                      )?.conditions?.map((obj) => ({ ...obj, value: obj.key }))}
                      onChange={(value) => {
                        handleChangeCondition(value as unknown as string, FilterKey.TAG)
                      }}
                    />
                  </Col>
                  <Col span={17}>
                    <Select
                      mode={multipleAllowed ? 'multiple' : undefined}
                      size="large"
                      placeholder={t('log.filter.placeholder')}
                      fieldNames={{ label: 'label', value: 'label' }}
                      optionFilterProp="label"
                      value={values.tag}
                      options={tagOptions}
                      onChange={(value) => {
                        const cleanValue = multipleAllowed ? value : [value]
                        handleChange(cleanValue as unknown as string, FilterKey.TAG)
                      }}
                      style={{ width: '100%' }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideFilters?.includes(FilterKey.PRODUCT_TAGS) && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={classes.label}>
                    <CubeIcon width={14} height={14} />
                    <TagIcon width={14} height={14} />
                    {t('product.tags')}
                  </span>
                }
              >
                <Row justify="space-between">
                  <Col span={6}>
                    <Select
                      size="large"
                      placeholder={t('log.filter.placeholder')}
                      defaultValue={
                        (
                          filterOptions.find(
                            (obj) => obj.key === FilterKey.PRODUCT_TAGS,
                          ) as RelationshipFilter
                        )?.conditions?.[0].key
                      }
                      value={
                        (
                          filters?.find(
                            (obj) => obj.key === FilterKey.PRODUCT_TAGS,
                          ) as RelationshipFilter
                        )?.selectedCondition?.key
                      }
                      options={(
                        filterOptions.find(
                          (obj) => obj.key === FilterKey.PRODUCT_TAGS,
                        ) as RelationshipFilter
                      )?.conditions?.map((obj) => ({ ...obj, value: obj.key }))}
                      onChange={(value) => {
                        handleChangeCondition(value as unknown as string, FilterKey.PRODUCT_TAGS)
                      }}
                    />
                  </Col>
                  <Col span={17}>
                    <Select
                      mode={multipleAllowed ? 'multiple' : undefined}
                      size="large"
                      placeholder={t('log.filter.placeholder')}
                      fieldNames={{ label: 'label', value: 'label' }}
                      optionFilterProp="label"
                      value={values.tags}
                      options={productTagOptions}
                      onChange={(value) => {
                        const cleanValue = multipleAllowed ? value : [value]
                        handleChange(cleanValue as unknown as string, FilterKey.PRODUCT_TAGS)
                      }}
                      style={{ width: '100%' }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        )}

        {!hideFilters?.includes(FilterKey.TERRITORY) && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={classes.label}>
                    <GlobeIcon width={14} height={14} />
                    {t('cozero.territory.title')}
                  </span>
                }
              >
                <Select
                  mode={multipleAllowed ? 'multiple' : undefined}
                  size="large"
                  placeholder={t('log.filter.placeholder')}
                  fieldNames={{ label: 'label', value: 'value' }}
                  optionFilterProp="label"
                  value={values.territory}
                  options={territoryOptions}
                  onChange={(value) => {
                    const cleanValue = multipleAllowed ? value : [value]
                    handleChange(cleanValue, FilterKey.TERRITORY)
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!hideFilters?.includes(FilterKey.DESCRIPTION) && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span className={classes.label}>
                    <ClipboardListIcon width={14} height={14} />
                    {t('log.description')}
                  </span>
                }
              >
                <Input
                  size="large"
                  style={{ width: '100%' }}
                  placeholder={t('log.description')}
                  value={values.description}
                  onChange={(el) => {
                    handleChange(el.target.value, FilterKey.DESCRIPTION)
                  }}
                ></Input>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </>
  )
}

export default React.memo(LocationFiltersForm)
