import React, { PropsWithChildren, ReactElement } from 'react'

import { MenuItemProps } from 'antd'

import MenuItem from '@/atoms/Menu/MenuItem'

import { AnalyticsCategories } from '@/constants/analyticsCategories'

import classes from './DotsMenuItem.module.less'

interface DotsMenuProps {
  action: string
  category: AnalyticsCategories
}

export const DotsMenuItem = ({
  action,
  category,
  children,
  ...rest
}: PropsWithChildren<DotsMenuProps & MenuItemProps>): ReactElement => {
  return (
    <MenuItem {...rest} className={classes.dotsMenuItem} category={category} action={action}>
      {children}
    </MenuItem>
  )
}
