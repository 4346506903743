import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import Tag, { TagType } from '@/atoms/Tag'

interface ComputedReportStatusProps {
  status: string | undefined
}

export default function ComputedReportStatus({
  status,
}: ComputedReportStatusProps): JSX.Element | null {
  const { t } = useTranslation('common')

  const getStatusConfig = (state: string): { type: TagType; key: string } | null => {
    switch (state) {
      case 'SUCCESS':
        return { type: 'success', key: 'computed-reports.status.success' }
      case 'FAILURE':
        return { type: 'danger', key: 'computed-reports.status.failure' }
      case 'PROCESSING':
        return { type: 'warning', key: 'computed-reports.status.processing' }
      default:
        return null
    }
  }

  if (!status) {
    return null
  }

  const statusConfig = getStatusConfig(status)

  if (!statusConfig) {
    return null
  }

  return (
    <>
      <Tag shape="pill" showDot type={statusConfig.type}>
        <span>{t(statusConfig.key)}</span>
      </Tag>
    </>
  )
}
