import {
  ActionSimulatorGetActivityDataDto,
  ActionSimulatorGetActivityResultDto,
  ActionSimulatorTriggerCalculationDto,
  ActionSimulatorTriggerCalculationResultDto,
  ActionSimulatorUpdateClimateActionDto,
  ActionSimulatorUpdateClimateActionResultDto,
  GetActionSimulatorImpactSummaryQueryDto,
  GetActionSimulatorImpactSummaryResultDto,
  GetMethodologyChangesQueryDto,
  GetTotalNetReductionImpactQueryDto,
  GetTotalNetReductionImpactResultDto,
  YearOnYearDataDto,
} from '@cozero/dtos'
import { GroupedMethodologyChangelog, Page, YearOnYearDataResult } from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import apiSlice from '../../api'
import { TAG_ACTION_SIMULATOR_ACTIVITY_DATA } from '../constants'

const actActionSimulatorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActivityData: builder.query<
      ActionSimulatorGetActivityResultDto,
      ActionSimulatorGetActivityDataDto
    >({
      query: (data) => ({
        url: actApiGatewayClient.actionSimulator.GET_ACTIVITY_DATA,
        method: 'POST',
        data,
      }),
      providesTags: () => [{ type: TAG_ACTION_SIMULATOR_ACTIVITY_DATA }],
    }),
    getImpactPreviewData: builder.query<YearOnYearDataResult, YearOnYearDataDto>({
      query: ({ businessUnitId, targetId, baselineYear, actionSimulatorClimateActionId }) => ({
        url: actApiGatewayClient.actionSimulator.GET_IMPACT_PREVIEW_DATA,
        method: 'POST',
        data: { businessUnitId, targetId, baselineYear, actionSimulatorClimateActionId },
      }),
    }),
    triggerCalculation: builder.mutation<
      ActionSimulatorTriggerCalculationResultDto,
      ActionSimulatorTriggerCalculationDto
    >({
      query: (data) => ({
        url: actApiGatewayClient.actionSimulator.TRIGGER_CALCULATION,
        method: 'POST',
        data,
      }),
    }),
    updateClimateAction: builder.mutation<
      ActionSimulatorUpdateClimateActionResultDto,
      ActionSimulatorUpdateClimateActionDto
    >({
      query: (data) => ({
        url: actApiGatewayClient.actionSimulator.UPDATE_CLIMATE_ACTION,
        method: 'POST',
        data,
      }),
    }),
    getImpactSummary: builder.query<
      GetActionSimulatorImpactSummaryResultDto,
      GetActionSimulatorImpactSummaryQueryDto
    >({
      query: (params) => ({
        url: actApiGatewayClient.actionSimulator.GET_IMPACT_SUMMARY,
        method: 'GET',
        params,
      }),
    }),
    getTotalNetReductionImpact: builder.query<
      GetTotalNetReductionImpactResultDto,
      GetTotalNetReductionImpactQueryDto
    >({
      query: (params) => ({
        url: actApiGatewayClient.actionSimulator.GET_TOTAL_NET_REDUCTION,
        method: 'GET',
        params,
      }),
    }),
    getMethodologyChanges: builder.query<
      Page<GroupedMethodologyChangelog>,
      GetMethodologyChangesQueryDto
    >({
      query: (params) => ({
        url: actApiGatewayClient.actionSimulator.GET_METHODOLOGY_CHANGES,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  useGetActivityDataQuery,
  useGetImpactPreviewDataQuery,
  useGetTotalNetReductionImpactQuery,
  useGetImpactSummaryQuery,
  useTriggerCalculationMutation,
  useUpdateClimateActionMutation,
  useGetMethodologyChangesQuery,
} = actActionSimulatorApiSlice

export default actActionSimulatorApiSlice
