import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { Json2CsvOptions, json2csvAsync } from 'json-2-csv'

import { HotspotAnalysisData } from '@cozero/models'

import { prettifyDate } from '@/utils/date'

export interface CsvRow
  extends Omit<
    HotspotAnalysisData,
    'emissions' | 'scopeCategoryNumber' | 'emissionsOfScopePercent' | 'emissionsOfOrganization'
  > {
  scopeCategoryNumber: string | null
  scopeSubcategory: string | null
  emissions: number
  emissionsOfScopePercent: number
  emissionsOfOrganization: number
}

export const flattenTree = (data: HotspotAnalysisData[]): HotspotAnalysisData[] => {
  if (data.length === 0) {
    return []
  }

  return data.flatMap(({ children, ...node }) => {
    const flattenedChildren = children ? flattenTree(children) : []

    return [node, ...flattenedChildren]
  })
}

export const getCsvBlob = async (data: CsvRow[], keys: Json2CsvOptions['keys']): Promise<Blob> => {
  const content = await json2csvAsync(data, { keys, emptyFieldValue: '' })

  return new Blob([content], { type: 'text/csv' })
}

export const getXlsxBlob = async (
  data: CsvRow[],
  columns: Partial<ExcelJS.Column>[],
): Promise<Blob> => {
  const workbook = new ExcelJS.Workbook()

  const worksheet = workbook.addWorksheet()
  worksheet.columns = columns
  worksheet.addRows(data)

  const buffer = await workbook.xlsx.writeBuffer()
  return new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
}

export const getExportFilename = ({
  dates,
  extension,
}: {
  dates?: [Date, Date]
  extension: 'csv' | 'xlsx'
}): string => {
  if (!dates) {
    return `${`hotspot-analysis`}.${extension}`
  }

  const prettyDates = dates.map((date) => prettifyDate(date, 'yyyy-MM-DD')).join('_')

  return `${`hotspot-analysis_${prettyDates}`}.${extension}`
}

export const downloadBlob = (blob: Blob, filename: string): void => {
  saveAs(blob, filename)
}
