import {
  CreateHotspotAnalysisViewDto,
  CreateHotspotAnalysisViewFromFiltersDto,
  DuplicateHotspotAnalysisViewDto,
  HotspotAnalysisViewDto,
  UpdateHotspotAnalysisViewDateFilterDto,
  UpdateHotspotAnalysisViewFiltersDto,
  UpdateHotspotAnalysisViewNameDto,
} from '@cozero/dtos'
import { HotspotAnalysisViewType } from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import { providesList } from '@/utils/redux'

import apiSlice from '../api'

import { TAG_EMISSION_HOTSPOTS_DATA, TAG_HOTSPOTS_VIEWS } from './constants'

const GET_HOTSPOT_VIEWS = 'getHotspotViews'
const CREATE_HOTSPOT_VIEWS = 'createHotspotViews'
const CREATE_HOTSPOT_VIEW_FROM_FILTERS = 'createHotspotViewFromFilters'
const DUPLICATE_HOTSPOT_VIEWS = 'duplicateHotspotViews'
const RENAME_HOTSPOT_VIEWS = 'renameHotspotViews'
const DELETE_HOTSPOT_VIEWS = 'deleteHotspotViews'
const UPDATE_HOTSPOT_VIEWS_FILTERS = 'updateHotspotViewsFilters'
const UPDATE_HOTSPOT_VIEWS_DATE_FILTER = 'updateHotspotViewsDateFilter'

const actAnalyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_HOTSPOT_VIEWS]: builder.query<HotspotAnalysisViewDto[], { type: HotspotAnalysisViewType }>(
      {
        query: ({ type }) => ({
          url: actApiGatewayClient.hotspotViews.GET_MANY,
          method: 'GET',
          params: { type },
        }),
        providesTags: (result) =>
          providesList<HotspotAnalysisViewDto[], typeof TAG_HOTSPOTS_VIEWS>(
            result,
            TAG_HOTSPOTS_VIEWS,
          ),
      },
    ),

    [CREATE_HOTSPOT_VIEWS]: builder.mutation<HotspotAnalysisViewDto, CreateHotspotAnalysisViewDto>({
      query: (data) => ({
        url: actApiGatewayClient.hotspotViews.CREATE,
        method: 'POST',
        data,
      }),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        const { data: result } = await queryFulfilled
        dispatch(
          actAnalyticsApiSlice.util.updateQueryData(
            GET_HOTSPOT_VIEWS,
            { type: result.type },
            (draftViews) => {
              draftViews.push(result)
            },
          ),
        )
      },
    }),

    [CREATE_HOTSPOT_VIEW_FROM_FILTERS]: builder.mutation<
      HotspotAnalysisViewDto,
      CreateHotspotAnalysisViewFromFiltersDto
    >({
      query: (data) => ({
        url: actApiGatewayClient.hotspotViews.CREATE_FROM_FILTERS,
        method: 'POST',
        data,
      }),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        const { data: result } = await queryFulfilled
        dispatch(
          actAnalyticsApiSlice.util.updateQueryData(
            GET_HOTSPOT_VIEWS,
            { type: result.type },
            (draftViews) => {
              draftViews.push(result)
            },
          ),
        )
      },
    }),

    [DUPLICATE_HOTSPOT_VIEWS]: builder.mutation<
      HotspotAnalysisViewDto,
      { id: number; data: DuplicateHotspotAnalysisViewDto }
    >({
      query: ({ id, data }) => ({
        url: actApiGatewayClient.hotspotViews.DUPLICATE.replace(':id', `${id}`),
        method: 'POST',
        data,
      }),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        const { data: result } = await queryFulfilled
        dispatch(
          actAnalyticsApiSlice.util.updateQueryData(
            GET_HOTSPOT_VIEWS,
            { type: result.type },
            (draftViews) => {
              draftViews.push(result)
            },
          ),
        )
      },
    }),

    [RENAME_HOTSPOT_VIEWS]: builder.mutation<
      HotspotAnalysisViewDto,
      { id: number; type: HotspotAnalysisViewType; data: UpdateHotspotAnalysisViewNameDto }
    >({
      query: ({ id, data }) => ({
        url: actApiGatewayClient.hotspotViews.UPDATE_NAME.replace(':id', `${id}`),
        method: 'PATCH',
        data,
      }),
      async onQueryStarted({ id, type, data }, { dispatch, queryFulfilled }) {
        // optimistic update that undo update on error
        const optimisticPatchResult = dispatch(
          actAnalyticsApiSlice.util.updateQueryData(GET_HOTSPOT_VIEWS, { type }, (draftViews) =>
            draftViews.map((view) => (view.id !== id ? view : { ...view, name: data.name })),
          ),
        )
        try {
          await queryFulfilled
        } catch {
          optimisticPatchResult.undo()
        }
      },
    }),

    [UPDATE_HOTSPOT_VIEWS_FILTERS]: builder.mutation<
      HotspotAnalysisViewDto,
      {
        id: number
        type: HotspotAnalysisViewType
        data: UpdateHotspotAnalysisViewFiltersDto
        forceUpdate?: boolean
      }
    >({
      query: ({ id, data, forceUpdate = false }) => ({
        url: actApiGatewayClient.hotspotViews.UPDATE_FILTERS.replace(':id', `${id}`),
        method: 'PATCH',
        data,
        params: forceUpdate ? { forceUpdate } : {},
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: TAG_EMISSION_HOTSPOTS_DATA, id: arg.id }],
      async onQueryStarted({ id, type, data }, { dispatch, queryFulfilled }) {
        // optimistic update that invalidates tags on error
        dispatch(
          actAnalyticsApiSlice.util.updateQueryData(GET_HOTSPOT_VIEWS, { type }, (draftViews) =>
            draftViews.map((view) => (view.id !== id ? view : { ...view, ...data })),
          ),
        )
        try {
          await queryFulfilled
        } catch {
          dispatch(actAnalyticsApiSlice.util.invalidateTags([TAG_HOTSPOTS_VIEWS]))
        }
      },
    }),

    [UPDATE_HOTSPOT_VIEWS_DATE_FILTER]: builder.mutation<
      HotspotAnalysisViewDto,
      {
        id: number
        type: HotspotAnalysisViewType
        data: UpdateHotspotAnalysisViewDateFilterDto
        forceUpdate?: boolean
      }
    >({
      query: ({ id, data, forceUpdate = false }) => ({
        url: actApiGatewayClient.hotspotViews.UPDATE_DATE_FILTER.replace(':id', `${id}`),
        method: 'PATCH',
        data,
        params: forceUpdate ? { forceUpdate } : {},
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: TAG_EMISSION_HOTSPOTS_DATA, id: arg.id }],
      async onQueryStarted({ id, type, data }, { dispatch, queryFulfilled }) {
        // optimistic update that invalidates tags on error
        dispatch(
          actAnalyticsApiSlice.util.updateQueryData(GET_HOTSPOT_VIEWS, { type }, (draftViews) =>
            draftViews.map((view) => (view.id !== id ? view : { ...view, ...data })),
          ),
        )
        try {
          await queryFulfilled
        } catch {
          dispatch(actAnalyticsApiSlice.util.invalidateTags([TAG_HOTSPOTS_VIEWS]))
        }
      },
    }),

    [DELETE_HOTSPOT_VIEWS]: builder.mutation<
      Pick<HotspotAnalysisViewDto, 'id'>,
      {
        id: number
        type: HotspotAnalysisViewType
      }
    >({
      query: ({ id }) => ({
        url: actApiGatewayClient.hotspotViews.DELETE.replace(':id', `${id}`),
        method: 'DELETE',
      }),
      async onQueryStarted({ id, type }, { dispatch, queryFulfilled }) {
        // optimistic update that invalidates tags on error
        dispatch(
          actAnalyticsApiSlice.util.updateQueryData(GET_HOTSPOT_VIEWS, { type }, (draftViews) =>
            draftViews.filter((view) => view.id !== id),
          ),
        )
        try {
          await queryFulfilled
        } catch {
          dispatch(actAnalyticsApiSlice.util.invalidateTags([TAG_HOTSPOTS_VIEWS]))
        }
      },
    }),
  }),
})

export const {
  useGetHotspotViewsQuery,
  useCreateHotspotViewsMutation,
  useDuplicateHotspotViewsMutation,
  useRenameHotspotViewsMutation,
  useDeleteHotspotViewsMutation,
  useUpdateHotspotViewsFiltersMutation,
  useCreateHotspotViewFromFiltersMutation,
  useUpdateHotspotViewsDateFilterMutation,
} = actAnalyticsApiSlice

export default actAnalyticsApiSlice
