import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { Spin } from 'antd/es'
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface'

import { omit } from 'lodash-es'

import { BusinessUnit, LogSorter, LogType, PageFilter, TableViewLog } from '@cozero/models'
import { routes } from '@cozero/utils'

import useLogsTableColumns from '@/organisms/LogsTable/useLogsTableColumns'

import Table from '@/molecules/Table'

import useGenerateData from '@/hooks/useGenerateData'
import { useGetPaginatedLogsQuery } from '@/redux/logs'

import classes from './LogsTable.module.less'

interface Props {
  type: LogType
  businessUnitsAllowed: boolean
  filters?: PageFilter[]
  sorters?: LogSorter[]
  currentPage: number
  pageSize: number
  defaultPageSize?: number
  selectedBusinessUnit?: BusinessUnit
  loading: boolean
  locationId?: string
  productId?: string
  setSort: (sort: LogSorter[]) => void
  setCurrentPage: (size: number) => void
  setPageSize: (size: number) => void
  setNoLogs?: (state: boolean) => void
}

function LogsTable({
  type,
  businessUnitsAllowed,
  filters,
  sorters,
  pageSize,
  defaultPageSize = 15,
  currentPage,
  selectedBusinessUnit,
  loading: logLoading,
  locationId,
  productId,
  setPageSize,
  setCurrentPage,
  setNoLogs,
  setSort,
}: Props): JSX.Element {
  const navigate = useNavigate()
  const { generateColumns, columns } = useGenerateData()
  const columnsData = useLogsTableColumns({ tableSorter: sorters, type, businessUnitsAllowed })
  const { data, isFetching: isFetchingLogs } = useGetPaginatedLogsQuery({
    query: {
      type: type,
      page: currentPage || 1,
      pageSize: pageSize,
      filters: filters?.map((obj) => omit(obj, 'options')) || [],
    },
    sort: sorters,
    businessUnitId: selectedBusinessUnit?.id,
  })

  const generatedColumns = useMemo(() => {
    generateColumns(columnsData)
  }, [sorters])

  useEffect(() => {
    generatedColumns
  }, [businessUnitsAllowed, sorters])

  const changeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TableViewLog> | SorterResult<TableViewLog>[],
  ): void => {
    if (pagination.current && pagination.current !== currentPage) {
      setCurrentPage(pagination.current)
    } else {
      if (Array.isArray(sorter)) {
        const sortersAux: LogSorter[] = sorter.map((x) => ({
          id: new Date().getTime().toString(),
          key: (x.column?.key || 'createdAt') as string,
          label: x.column?.key?.toString() || 'createdAt',
          logType: type,
          selectedSort: x.order === 'descend' ? 'desc' : 'asc',
        }))
        generatedColumns
        setSort(sortersAux)
      } else {
        if (sorter && Object.keys(sorter).length) {
          const aux = {
            id: new Date().getTime().toString(),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            key: (sorter.column?.key || 'createdAt') as any,
            label: sorter.column?.key?.toString() || 'createdAt',
            logType: type,
            selectedSort: sorter.order === 'descend' ? 'desc' : 'asc',
          }
          generatedColumns
          setSort([aux])
        }
      }
    }
  }

  const navigateToNewTab = (location: string): void => {
    const newWindow = window.open(location, '_blank')
    if (newWindow) {
      newWindow.opener = null
    }
  }

  const gotToEditLog = (logId: string, locationId?: string, newTab = true): void => {
    let route = `${routes.organization.locations.editLog
      .replace(':locationId', locationId || '')
      .replace(':id', logId)}`

    if (window.location.pathname.includes(routes.log.carbonFootprint.base) || !locationId) {
      route = `${routes.log.carbonFootprint.organization.edit.replace(':id', logId)}`
    }

    if (newTab) {
      navigateToNewTab(route)
    } else {
      navigate(route)
    }
  }
  useEffect(() => {
    if (!isFetchingLogs && !logLoading && data?.totalLogs && data?.totalLogs === 0 && setNoLogs) {
      setNoLogs(true)
    }
  }, [isFetchingLogs, logLoading, data])

  return (
    <Spin spinning={isFetchingLogs}>
      <Table
        scroll={{ x: 1700 }}
        // Types not working properly for 'fixed'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columns={columns?.filter(Boolean) as any}
        dataSource={Array.isArray(data?.logs) ? data?.logs : data?.logs ? [data?.logs] : []}
        rowKey="id"
        className={classes.table}
        rowClassName={classes.tableRow}
        onChange={changeTable}
        onRow={(record) => {
          return {
            onClick: (event) => {
              if (event.ctrlKey || event.metaKey) {
                gotToEditLog(record.id.toString(), locationId || productId)
              } else {
                gotToEditLog(record.id.toString(), locationId || productId, false)
              }
            },
          }
        }}
        pagination={{
          current: currentPage,
          defaultPageSize,
          pageSize,
          total: data?.totalLogs,
          hideOnSinglePage: true,
          onShowSizeChange: (_current, size) => setPageSize(size),
        }}
        showWrapper={false}
      />
    </Spin>
  )
}

export default LogsTable
