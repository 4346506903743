/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import moment, { Moment } from 'moment-timezone'

import DateRangePicker from '@/atoms/DateRangePicker'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { useMixpanel } from '@/hooks/useMixpanel'
import { useAppSelector } from '@/redux'
import {
  useCreateHotspotViewFromFiltersMutation,
  useUpdateHotspotViewsDateFilterMutation,
} from '@/redux/actAnalytics'
import { useSelectedHotspotAnalysisView } from '@/redux/actAnalytics/hooks/useSelectedHotspotAnalysisView.hook'
import { selectHotspotAnalysisDateFilter } from '@/redux/actAnalytics/selectors'
import { setHotspotAnalysisDateFilter } from '@/redux/actAnalytics/slice'

import classes from './HotspotAnalysisDatePicker.module.less'
import { HotspotAnalysisFilterConfirmationDialog } from './HotspotAnalysisFilterConfirmationDialog'
import { useHotspotAnalysisContext } from './hooks/useHotspotAnalysisContext'
import { getDatePickerRanges } from './utils/HotspotAnalysis.utils'

const RANGES = getDatePickerRanges()

const CONFLICT_UPDATE_HTTP_STATUS = 409

export const DATE_PICKER_UPDATE_FIXED_CACHED_KEY = 'update-date-picker'

interface Props {
  disabled?: boolean
}

export const HotspotAnalysisDatePicker = ({ disabled = false }: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const { trackAction } = useMixpanel()

  const { type } = useHotspotAnalysisContext()

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const selectedDateFilter = useAppSelector(selectHotspotAnalysisDateFilter)
  const [localDateFilter, setLocalDateFilter] = useState<[Moment, Moment] | undefined>(undefined)

  const [updateHotspotViewsDateFilter] = useUpdateHotspotViewsDateFilterMutation({
    fixedCacheKey: DATE_PICKER_UPDATE_FIXED_CACHED_KEY,
  })
  const [selectedHotspotAnalysisView, setSelectedHotspotAnalysisView] =
    useSelectedHotspotAnalysisView()
  const [createHotspotViewFromFilters] = useCreateHotspotViewFromFiltersMutation()

  const updateDateFilter = useCallback(
    ([start, end]: [Moment, Moment], { forceUpdate = false }: { forceUpdate: boolean }) => {
      if (selectedHotspotAnalysisView?.id) {
        setLocalDateFilter([start, end])

        updateHotspotViewsDateFilter({
          id: selectedHotspotAnalysisView.id,
          data: {
            startDate: start.toDate(),
            endDate: end.toDate(),
          },
          forceUpdate,
          type,
        })
          .unwrap()
          .then(() => {
            trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'date-filter-updated')
            dispatch(setHotspotAnalysisDateFilter([start.toDate(), end.toDate()]))
            setLocalDateFilter(undefined) // resets to default (selected view date filter)
          })
          .catch(({ status }) => {
            if (status === CONFLICT_UPDATE_HTTP_STATUS) {
              setShowConfirmationDialog(true)
            }
          })
      }
    },
    [
      dispatch,
      trackAction,
      updateHotspotViewsDateFilter,
      setLocalDateFilter,
      selectedHotspotAnalysisView,
      type,
    ],
  )

  const handleDialogConfirm = useCallback(() => {
    if (localDateFilter) {
      updateDateFilter(localDateFilter, { forceUpdate: true })
    }
    setShowConfirmationDialog(false)
  }, [updateDateFilter, localDateFilter])

  const handleCreateNewViewClick = useCallback(async () => {
    const newView = await createHotspotViewFromFilters({
      startDate: localDateFilter?.[0]?.toDate(),
      endDate: localDateFilter?.[1]?.toDate(),
      viewId: selectedHotspotAnalysisView?.id as number,
    }).unwrap()

    setSelectedHotspotAnalysisView(newView)
    setLocalDateFilter(undefined) // resets to default (selected view date filter)
    setShowConfirmationDialog(false)
    trackAction(AnalyticsCategories.ACT_HOTSPOT_ANALYSIS, 'view-added-from-filters-dialog')
  }, [
    localDateFilter,
    trackAction,
    createHotspotViewFromFilters,
    selectedHotspotAnalysisView,
    setSelectedHotspotAnalysisView,
  ])

  const handleDialogCancel = useCallback(() => {
    setLocalDateFilter(undefined) // resets to default (selected view date filter)
    setShowConfirmationDialog(false)
  }, [])

  const dateFilter = React.useMemo<[Moment | null, Moment | null]>(() => {
    if (localDateFilter) {
      return localDateFilter
    }
    return selectedDateFilter
      ? [moment(selectedDateFilter[0]), moment(selectedDateFilter[1])]
      : [null, null]
  }, [selectedDateFilter, localDateFilter])

  const onOpenChange = useCallback(
    (value: [Moment, Moment] | null) => {
      if (value !== null) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const startOfMonth = moment(value[0].tz(timezone).startOf('month').utc().toDate())
        const endOfMonth = moment(value[1].tz(timezone).endOf('month').utc().toDate())
        updateDateFilter([startOfMonth, endOfMonth], {
          forceUpdate: false,
        })
      }
    },
    [updateDateFilter],
  )

  return (
    <>
      <HotspotAnalysisFilterConfirmationDialog
        show={showConfirmationDialog}
        onCancel={handleDialogCancel}
        onCreateNewView={handleCreateNewViewClick}
        onConfirm={handleDialogConfirm}
      />
      <DateRangePicker
        disabled={disabled}
        inputReadOnly
        className={classes.datePicker}
        popupClassName={classes.popup}
        format="yyyy-MM-DD"
        picker="month"
        allowClear={false}
        visualStyle="ghost"
        style={{ width: 250 }}
        ranges={RANGES}
        value={dateFilter}
        onCloseChange={onOpenChange}
        data-cy="hotspot-date-picker"
      />
    </>
  )
}
