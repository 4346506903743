import React, { ReactElement, ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@antv/g2plot'
import classNames from 'classnames'

import { Shape, Shapes } from '@/molecules/Shape/Shape'

import { BORDER_RADIUS_BASE, SHADOW_MD } from '@/styles/variables'
import { formatNumber } from '@/utils/number'

import classes from './GraphTooltip.module.less'

const MAX_ROWS = 8

export type TooltipRowData = {
  color?: string | null
  value: number | string
  title?: string
  isEmphasized?: boolean
  shape?: Shapes
}

type GraphTooltipProps = {
  title?: string
  titleDataUnit?: string
  titleValue?: string
  data: TooltipRowData[]
  footer?: ReactElement | null
}

const GraphTooltip = ({
  title,
  titleValue,
  data,
  footer,
  titleDataUnit,
}: GraphTooltipProps): JSX.Element => {
  const { t } = useTranslation('common')

  // Limit tooltips to a maximum number of rows defined in MAX_ROWS, if the type of value is a number
  const formattedData = useMemo(() => {
    const rowsWithNumericalValue = data.filter(
      (datum): datum is TooltipRowData & { value: number } => typeof datum.value === 'number',
    )

    if (rowsWithNumericalValue.length !== data.length) {
      return data
    }

    const sliced = rowsWithNumericalValue.slice(0, MAX_ROWS)
    const tail = rowsWithNumericalValue.slice(MAX_ROWS, data.length)

    const tailValue = tail.reduce((acc, { value }) => acc + value, 0)

    if (tailValue > 0) {
      return [
        ...sliced,
        {
          color: null,
          isEmphasized: true,
          value: tailValue,
          title: `${tail.length} ${
            tail.length > 1 ? t('reports.tooltip.items-left') : t('reports.tooltip.item-left')
          }`,
        },
      ]
    }

    return sliced
  }, [data])

  const renderedTooltipRows = useMemo(
    (): ReactNode =>
      formattedData
        .filter(({ title }) => title)
        .map(({ shape = 'dot', ...datum }): JSX.Element => {
          const formattedValue =
            typeof datum.value === 'number' ? formatNumber(datum.value) : datum.value
          return (
            <div key={datum.color} className={classes.tooltipRow}>
              <div className={classes.legend}>
                {datum.color && (
                  <Shape
                    className={classNames(classes.shape, classes[shape])}
                    type={shape}
                    color={datum.color}
                  />
                )}

                {datum.title && (
                  <div
                    className={classNames(classes.legendTitle, {
                      [classes.emphasized]: datum.isEmphasized,
                    })}
                  >
                    {datum.title}
                  </div>
                )}

                {!datum.title && <div className={classes.value}>{formattedValue}</div>}
              </div>

              {datum.title && <div className={classes.value}>{formattedValue}</div>}
            </div>
          )
        }),
    [data],
  )

  return (
    <div className={classes.tooltip}>
      {(title || titleValue || titleDataUnit) && (
        <div className={classes.tooltipHeader}>
          <div className={classes.tooltipTitle}>{title}</div>
          <div className={classes.value}>{titleValue}</div>
          {titleDataUnit && <div>{titleDataUnit}</div>}
        </div>
      )}

      <div className={classes.tooltipBody}>{renderedTooltipRows}</div>

      {footer && <div className={classes.tooltipFooter}>{footer}</div>}
    </div>
  )
}

const defaultConfig: Tooltip = {
  domStyles: {
    'g2-tooltip': {
      padding: 'none',
      boxShadow: SHADOW_MD,
      borderRadius: BORDER_RADIUS_BASE,
      fontFamily: 'Inter, sans-serif',
      opacity: 1,
    },
  },
}

export default Object.assign(GraphTooltip, { defaultConfig })
