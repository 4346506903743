import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { HotspotAnalysisViewType } from '@cozero/models'

import { HotspotAnalysis } from '../Act/Analytics/components/HotspotAnalysis/HotspotAnalysis'

import classes from './BalanceSheetReport.module.less'

export const BalanceSheetReport = (): ReactElement => {
  const { t } = useTranslation('common')

  return (
    <div className={classes.root}>
      <HotspotAnalysis
        title={t('reports.balanceSheetReport.title')}
        tooltip={t('reports.balanceSheetReport.tooltip')}
        type={HotspotAnalysisViewType.REDUCED}
      />
    </div>
  )
}
