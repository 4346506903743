import React, { ReactElement, ReactNode, Suspense, lazy } from 'react'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import SuspenseSpinner from '@/templates/SuspenseSpinner'

import Home from '@/pages/Home'
import ComputedReport from '@/pages/Overview/ComputedReports'
import { BalanceSheetReport } from '@/pages/Reports/BalanceSheetReport'

import ComputedReportCrumb from '@/molecules/Breadcrumbs/ComputedReportCrumb'

import FiltersProvider from '@/contexts/filters'
import LogProvider from '@/contexts/log'

import FeatureRoute from '../FeatureRoute'
import PrivateRoute from '../PrivateRoute'

const Reports = lazy(() => import('@/pages/Reports'))
const ViewReport = lazy(() => import('@/pages/Reports/ViewReport'))
const NotFoundPage = lazy(() => import('@/pages/404'))

const Wrapper = ({
  feature = 'log',
  children,
}: {
  feature?: string
  children: ReactNode
}): ReactElement => (
  <PrivateRoute>
    <LogProvider>
      <FeatureRoute requiredFeature={feature}>{children}</FeatureRoute>
    </LogProvider>
  </PrivateRoute>
)

const getOverviewRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.overview.base,
    props: { preventNavigation: true },
    children: [
      {
        path: routes.overview.dashboard,
        element: (
          <Wrapper feature="reports">
            <FiltersProvider saveQueryString={true}>
              <Home />
            </FiltersProvider>
          </Wrapper>
        ),
        breadcrumb: 'Home',
      },
      {
        path: routes.overview.reports,
        element: (
          <Wrapper feature="reports">
            <Suspense fallback={<SuspenseSpinner />}>
              <Reports />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: t('layout.reports'),
      },
      {
        path: routes.overview.viewBalanceSheetReport,
        element: (
          <Wrapper feature="reports">
            <Suspense fallback={<SuspenseSpinner />}>
              <BalanceSheetReport />
            </Suspense>
          </Wrapper>
        ),
        breadcrumb: t('reports.balanceSheetReport.title'),
      },
      {
        path: routes.overview.viewReport,
        element: (
          <Wrapper feature="reports">
            <FiltersProvider>
              <Suspense fallback={<SuspenseSpinner />}>
                <ViewReport />
              </Suspense>
            </FiltersProvider>
          </Wrapper>
        ),
      },
      {
        path: routes.overview.computedReport.base,
        props: { preventNavigation: true },
        breadcrumb: t('reports.computed-reports'),
        children: [
          {
            path: routes.overview.computedReport.view,
            element: (
              <Wrapper feature="computed-reports">
                <ComputedReport />
              </Wrapper>
            ),
            breadcrumb: ComputedReportCrumb,
          },
          {
            path: routes.overview.computedReport.details,
            element: (
              <Wrapper feature="computed-reports">
                <ComputedReport />
              </Wrapper>
            ),
            breadcrumb: ComputedReportCrumb,
          },
        ],
      },
      {
        path: `${routes.overview.base}/*`,
        element: (
          <Wrapper>
            <Suspense fallback={<SuspenseSpinner />}>
              <NotFoundPage />
            </Suspense>
          </Wrapper>
        ),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
]

export default getOverviewRoutes
