import { t } from 'i18next'

type FilterType = 'businessUnits' | 'locations' | 'territories'

const MAIN_DELETED_WARNING_TRANSLATION_KEY = 'act.analytics.hotspotAnalysis.filters.deletedWarning'

// Helper function to determine the translation key based on the number of deleted filters and type
const getTranslationKey = ({
  deletedFilters,
  isTerritory,
}: {
  deletedFilters: number
  isTerritory: boolean
}): string => {
  if (isTerritory) {
    return `${MAIN_DELETED_WARNING_TRANSLATION_KEY}.${'territory.text'}`
  }

  switch (deletedFilters) {
    case 1:
      return `${MAIN_DELETED_WARNING_TRANSLATION_KEY}.generics.single`
    case 2:
      return `${MAIN_DELETED_WARNING_TRANSLATION_KEY}.generics.double`
    default:
      return `${MAIN_DELETED_WARNING_TRANSLATION_KEY}.generics.multiple`
  }
}

// Helper function to determine the parameters for the translation based on the type and names
const getTranslationParams = ({
  filterType,
  names,
}: {
  filterType: FilterType
  names: { value: number; label: string }[]
}): Record<string | number, string | number> => {
  if (filterType === 'territories') {
    const quantity = t(`${MAIN_DELETED_WARNING_TRANSLATION_KEY}.territory.quantity`, {
      postProcess: 'interval',
      count: names.length,
    })

    const pronoun = t(`${MAIN_DELETED_WARNING_TRANSLATION_KEY}.generics.pronoun`, {
      postProcess: 'interval',
      count: names.length,
    })

    return {
      quantity,
      pronoun,
    }
  }

  if (names.length === 1) {
    return { filter: names[0].label }
  }
  return { filter1: names[0].label, filter2: names[1].label }
}

export const generateDeletedFiltersWarningText = ({
  filterType,
  deletedFilters,
}: {
  filterType: FilterType
  deletedFilters:
    | {
        value: number
        label: string
      }[]
    | number[]
}): string => {
  if (deletedFilters.length === 0) {
    return ''
  }

  // Determine the translation key and parameters
  const translationKey = getTranslationKey({
    deletedFilters: deletedFilters.length,
    isTerritory: filterType === 'territories',
  })

  const translationParams = getTranslationParams({
    filterType,
    names: deletedFilters as { value: number; label: string }[],
  })

  return t(translationKey, translationParams)
}
