import { t } from 'i18next'
import { orderBy, range } from 'lodash-es'
import moment, { Moment } from 'moment-timezone'

import { HotspotAnalysisData, HotspotAnalysisSorting } from '@cozero/models'

import { TagType } from '@/atoms/Tag'

import { HotspotAnalysisRow } from '../types/HotSpotAnalysis.types'

export const getRowKey = ({
  level,
  scopeId,
  logCategoryId,
  logEntrySubcategoryId,
  activityDataSourceId,
  scopeCategoryNumber,
}: HotspotAnalysisRow): string => {
  switch (level) {
    case 1:
      return `${scopeCategoryNumber}`
    case 2:
      return `${scopeCategoryNumber}.${scopeId}`
    case 3:
      return `${scopeCategoryNumber}.${scopeId}.${logCategoryId}`
    case 4:
      return `${scopeCategoryNumber}.${scopeId}.${logCategoryId}.${logEntrySubcategoryId}`
    default: {
      return `${scopeCategoryNumber}.${scopeId}.${logCategoryId}.${logEntrySubcategoryId}.${activityDataSourceId}`
    }
  }
}

export const getRowKeyWithSuffix = (row: HotspotAnalysisRow): string => {
  const rowKey = getRowKey(row)

  if (row.level < 5) {
    return rowKey
  }

  const suffix = row.pinned ? 'pinned' : ''
  return suffix ? `${rowKey}-${suffix}` : rowKey
}

export const getLevelDescriptor = ({ level }: HotspotAnalysisRow): string => {
  switch (level) {
    case 1:
      return 'scopeCategory'
    case 2:
      return 'scopeSubcategory'
    case 3:
      return 'logCategory'
    case 4:
      return 'logEntrySubcategory'
    default:
      return 'activityDataSource'
  }
}

export const getParentRowKey = ({ level, ...record }: HotspotAnalysisRow): string => {
  return getRowKey({ ...record, level: level - 1 })
}

export const getScope = ({
  scopeCategoryNumber,
  level,
  pinned,
}: HotspotAnalysisRow): string | null => {
  if (level === 1 || pinned) {
    return scopeCategoryNumber ? `Scope ${scopeCategoryNumber}` : null
  }

  return null
}

export const getScopeCategory = ({
  level,
  scopeSubcategoryTitle,
  pinned,
}: HotspotAnalysisRow): string | null => {
  if (level === 2 || pinned) {
    return scopeSubcategoryTitle ?? ''
  }

  return null
}

export const getLogCategory = ({
  level,
  logCategoryName,
  pinned,
}: HotspotAnalysisRow): string | null => {
  if (level === 3 || pinned) {
    return logCategoryName || ''
  }

  return null
}

export const getLogSubcategory = ({
  level,
  logEntrySubcategoryName,
  pinned,
}: HotspotAnalysisRow): string | null => {
  if (level === 4 || pinned) {
    return logEntrySubcategoryName || ''
  }

  return null
}

export const getBusinessActivity = ({
  level,
  activityDataSourceName,
}: HotspotAnalysisRow): string | null => {
  if (level === 5) {
    return activityDataSourceName || ''
  }

  return null
}

export const getHotspotAssessmentType = (assessment: 'high' | 'medium' | 'low'): TagType => {
  switch (assessment) {
    case 'high':
      return 'danger'
    case 'medium':
      return 'warning'
    case 'low':
      return 'success'
  }
}

export const getHotspotAssessmentLabel = (assessment: 'high' | 'medium' | 'low'): string => {
  switch (assessment) {
    case 'high':
      return t('act.analytics.hotspotAnalysis.table.hotspotAssessment.high')
    case 'medium':
      return t('act.analytics.hotspotAnalysis.table.hotspotAssessment.medium')
    case 'low':
      return t('act.analytics.hotspotAnalysis.table.hotspotAssessment.low')
  }
}

export const getDatePickerRanges = (): Record<string, [Moment, Moment]> => {
  const currentYear = moment().year()

  return range(4, 0, -1).reduce((ranges, offset) => {
    const year = currentYear - offset
    const baseDate = moment().year(year)

    return {
      ...ranges,
      [year]: [baseDate.clone().startOf('year').utc(), baseDate.clone().endOf('year').utc()],
    }
  }, {})
}

const DEFAULT_SORT_MAPPING: { [key: HotspotAnalysisData['level']]: keyof HotspotAnalysisData } = {
  0: 'scopeCategoryNumber',
  1: 'scopeSubcategoryTitle',
  2: 'logCategoryName',
  3: 'logEntrySubcategoryName',
  4: 'activityDataSourceName',
}

export const getSortedData = (
  data: HotspotAnalysisData[] | undefined,
  sorting: HotspotAnalysisSorting | null,
  level = 0,
): HotspotAnalysisData[] => {
  if (!data) {
    return []
  }

  if (!sorting || !sorting.field || !sorting.order) {
    return data
  }

  const { field, order } = sorting

  return orderBy(
    data,
    [...(field ? [field] : []), DEFAULT_SORT_MAPPING[level]],
    [...(order ? [order] : []), 'asc'],
  ).map((item) => ({
    ...item,
    children: getSortedData(item.children, sorting, item.level),
  }))
}
