import {
  EmissionHotspotsRequestDto,
  HotspotFiltersOptionsDto,
  HotspotFiltersOptionsResultDto,
  YearOnYearDataDto,
} from '@cozero/dtos'
import { EmissionHotspotsResult, ReductionEffortResult, YearOnYearDataResult } from '@cozero/models'
import { actApiGatewayClient } from '@cozero/uris'

import apiSlice from '../api'

import {
  TAG_EMISSION_HOTSPOTS_DATA,
  TAG_REDUCTION_EFFORT_GRAPH,
  TAG_YEAR_ON_YEAR_DATA,
} from './constants'

const GET_REDUCTION_EFFORT_GRAPH = 'getReductionEffortGraph'
const GET_YEAR_ON_YEAR_DATA = 'getYearOnYearData'
const GET_EMISSION_HOTSPOTS_DATA = 'getEmissionHotspotsData'
const GET_HOTSPOTS_FILTERS_OPTIONS = 'getHotspotsFiltersOptions'

const actAnalyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    [GET_REDUCTION_EFFORT_GRAPH]: builder.query<
      ReductionEffortResult,
      { businessUnitId: number; targetId: number; endYear: number }
    >({
      query: ({ businessUnitId, targetId, endYear }) => ({
        url: actApiGatewayClient.analytics.GET_REDUCTION_EFFORT_GRAPH,
        method: 'POST',
        data: { businessUnitId, targetId, endYear },
      }),
      providesTags: () => [TAG_REDUCTION_EFFORT_GRAPH],
    }),
    [GET_YEAR_ON_YEAR_DATA]: builder.query<YearOnYearDataResult, YearOnYearDataDto>({
      query: ({ businessUnitId, targetId, baselineYear }) => ({
        url: actApiGatewayClient.analytics.GET_YEAR_ON_YEAR_DATA,
        method: 'POST',
        data: { businessUnitId, targetId, baselineYear },
      }),
      providesTags: () => [TAG_YEAR_ON_YEAR_DATA],
    }),
    [GET_EMISSION_HOTSPOTS_DATA]: builder.query<EmissionHotspotsResult, EmissionHotspotsRequestDto>(
      {
        query: (data) => ({
          url: actApiGatewayClient.analytics.GET_EMISSION_HOTSPOTS_DATA,
          method: 'POST',
          data,
        }),
        providesTags: (_result, _response, args) => [
          {
            type: TAG_EMISSION_HOTSPOTS_DATA,
            id: args.viewId,
          },
        ],
      },
    ),
    [GET_HOTSPOTS_FILTERS_OPTIONS]: builder.query<
      HotspotFiltersOptionsResultDto,
      HotspotFiltersOptionsDto
    >({
      query: ({ viewId }) => ({
        url: actApiGatewayClient.analytics.GET_HOTSPOTS_FILTERS_OPTIONS,
        method: 'POST',
        data: { viewId },
      }),
    }),
  }),
})

export const {
  useGetReductionEffortGraphQuery,
  useGetYearOnYearDataQuery,
  useGetEmissionHotspotsDataQuery,
  useGetHotspotsFiltersOptionsQuery,
} = actAnalyticsApiSlice

export default actAnalyticsApiSlice
