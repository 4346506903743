import React, { ReactElement, ReactNode } from 'react'

import { TFunction } from 'i18next'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { routes } from '@cozero/utils'

import UpsertProductPage from '@/pages/Organization/NewProduct'
import GenericStepSelector from '@/pages/Organization/NewProduct/GenericStepSelector'
import ProductConfiguration from '@/pages/Organization/NewProduct/ProductConfiguration'
import Quantities from '@/pages/Organization/NewProduct/Quantities'
import SelectSteps from '@/pages/Organization/NewProduct/SelectSteps'
import { LifecycleStepsID, LifecycleStepsKey } from '@/pages/Organization/NewProduct/steps'

import LifecycleProvider from '@/contexts/lifecycle'
import LogProvider from '@/contexts/log'

import PrivateRoute from '../PrivateRoute'

const Wrapper = ({
  children,
}: {
  excludeRoles?: string[]
  feature?: string
  children: ReactNode
}): ReactElement => (
  <LogProvider>
    <LifecycleProvider>
      <PrivateRoute>{children}</PrivateRoute>
    </LifecycleProvider>
  </LogProvider>
)

const getLogRoutes = (t: TFunction): BreadcrumbsRoute[] => [
  {
    path: routes.log.carbonFootprint.productsUpsert.base,
    element: (
      <Wrapper>
        <UpsertProductPage />
      </Wrapper>
    ),
    breadcrumb: t('layout.products'),
    children: [
      {
        path: routes.log.carbonFootprint.productsUpsert.stepsCreate,
        element: <SelectSteps />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.configurationCreate,
        element: <ProductConfiguration />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.quantitiesCreate,
        element: <Quantities />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.quantitiesCreate,
        element: <Quantities />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.rawMaterialsAcquisitionCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['raw-materials-acquisition']}
            step={LifecycleStepsKey.RAW_MATERIALS_ACQUISITION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.productionCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.production}
            step={LifecycleStepsKey.PRODUCTION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.distributionAndStorageCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['distribution-and-storage']}
            step={LifecycleStepsKey.DISTRIBUTION_AND_STORAGE}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.endOfLifeCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['end-of-life']}
            step={LifecycleStepsKey.END_OF_LIFE}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.packagingCreate,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.packaging}
            step={LifecycleStepsKey.PACKAGING}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.usageCreate,
        element: (
          <GenericStepSelector stepId={LifecycleStepsID.usage} step={LifecycleStepsKey.USAGE} />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.stepsEdit,
        element: <SelectSteps edit />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.configurationEdit,
        element: <ProductConfiguration />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.quantitiesEdit,
        element: <Quantities />,
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.rawMaterialsAcquisitionEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['raw-materials-acquisition']}
            step={LifecycleStepsKey.RAW_MATERIALS_ACQUISITION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.productionEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.production}
            step={LifecycleStepsKey.PRODUCTION}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.distributionAndStorageEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['distribution-and-storage']}
            step={LifecycleStepsKey.DISTRIBUTION_AND_STORAGE}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.endOfLifeEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID['end-of-life']}
            step={LifecycleStepsKey.END_OF_LIFE}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.packagingEdit,
        element: (
          <GenericStepSelector
            stepId={LifecycleStepsID.packaging}
            step={LifecycleStepsKey.PACKAGING}
          />
        ),
        breadcrumb: t('layout.products'),
      },
      {
        path: routes.log.carbonFootprint.productsUpsert.usageEdit,
        element: (
          <GenericStepSelector stepId={LifecycleStepsID.usage} step={LifecycleStepsKey.USAGE} />
        ),
        breadcrumb: t('layout.products'),
      },
    ] as (BreadcrumbsRoute & { children: BreadcrumbsRoute[] })[],
  },
]

export default getLogRoutes
