import React, { useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Col, Popover, Row, Space } from 'antd/es'
import { ColumnsType } from 'antd/es/table'

import moment from 'moment'

import { FactorRequest } from '@cozero/models'
import { routes } from '@cozero/utils'

import Table from '@/molecules/Table'

import Button from '@/atoms/Button'
import CheckCircleOutlineIcon from '@/atoms/Icons/CheckCircleOutline'
import DotsHorizontalIcon from '@/atoms/Icons/DotsHorizontal'
import EyeOutlineIcon from '@/atoms/Icons/EyeOutline'
import PaperClipOutlineIcon from '@/atoms/Icons/PaperClipOutline'
import XCircleOutlineIcon from '@/atoms/Icons/XCircleOutline'
import Tag, { TagType } from '@/atoms/Tag'
import Text from '@/atoms/Text'
import Tooltip from '@/atoms/Tooltip'

import { AnalyticsCategories } from '@/constants/analyticsCategories'
import { formatLifecycleSteps } from '@/utils/formats'
import { formatCompact } from '@/utils/number'
import { calculateEmissionValue } from '@/utils/recalculations'

import classes from './classes.module.less'

interface Props {
  factorRequests: FactorRequest[]
  updateFactorRequest: (
    id: number,
    factorRequest: Partial<FactorRequest>,
  ) => Promise<FactorRequest | void>
}

interface StatusDetails {
  status: TagType
  text: string
  icon: JSX.Element | string
}

interface StatusMapping {
  [key: string]: StatusDetails
}

function getStatusDetails(record: FactorRequest, t: TFunction): StatusDetails {
  const { status, updatedAt, createdAt } = record
  const dateFormat = 'DD.MM.YYYY'
  const statusMapping: StatusMapping = {
    SUBMITTED: {
      status: 'info',
      text: `${t('customer-requests.to-review')}`,
      icon: <EyeOutlineIcon width={14} height={14} className={classes.statusIcon} />,
    },
    ACCEPTED: {
      status: 'success',
      text: `${t('customer-requests.accepted')}`,
      icon: <CheckCircleOutlineIcon width={14} height={14} className={classes.statusIcon} />,
    },
    REJECTED: {
      status: 'danger',
      text: `${t('customer-requests.rejected')}`,
      icon: <XCircleOutlineIcon width={14} height={14} className={classes.statusIcon} />,
    },
    REQUESTED: {
      status: 'default',
      text: `${status.toLowerCase().replace(/\b(\w)/g, (s: string) => s.toUpperCase())} ${t(
        'on',
      )} ${
        updatedAt ? moment(updatedAt).format(dateFormat) : moment(createdAt).format(dateFormat)
      }`,
      icon: '',
    },
  }

  return statusMapping[status]
}

function FactorRequestsTable({ factorRequests, updateFactorRequest }: Props): JSX.Element {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const [openPopoverId, setOpenPopoverId] = useState<number | null>(null)

  function closePopover(): void {
    setOpenPopoverId(null)
  }

  function handleOpenChange(newOpen: boolean, id: number): void {
    if (newOpen) {
      setOpenPopoverId(id)
    } else {
      closePopover()
    }
  }

  function handleUpdateFactorRequest(id: number, factorRequest: Partial<FactorRequest>): void {
    updateFactorRequest(id, { ...factorRequest })
    closePopover()
  }

  const columns: ColumnsType<FactorRequest> = [
    {
      key: 'productName',
      title: t('customer-requests.product-name'),
      render(text: string, record: FactorRequest) {
        if (!record.activityDataSource) {
          return
        }
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {String(record.activityDataSource.name)}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'productCode',
      title: t('customer-requests.product-code'),
      render(text: string, record: FactorRequest) {
        if (!record.productCode) {
          return
        }
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {record.productCode}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'supplier',
      title: 'Supplier',
      render(text: string, record: FactorRequest) {
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {record.supplier?.name}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'status',
      title: t('customer-requests.status'),
      render(text: string, record: FactorRequest) {
        const statusDetails = getStatusDetails(record, t)
        return (
          <Space className={classes.actions}>
            <Tag type={statusDetails.status} icon={statusDetails.icon}>
              {statusDetails.text}
            </Tag>
          </Space>
        )
      },
    },
    {
      key: 'dueDate',
      title: t('customer-requests.due-date'),
      render(text: string, record: FactorRequest) {
        if (!record.dueDate) {
          return
        }
        return (
          <span className={classes.nameSubcontainer}>
            <Tag>{moment(record.dueDate).format('LL')}</Tag>
          </span>
        )
      },
    },
    {
      key: 'requester',
      title: t('customer-requests.requester'),
      render(text: string, record: FactorRequest) {
        const user = record.requester
        if (!user) {
          return null
        }
        const userName = user.firstName ? `${user.firstName} ${user.lastName}` : user.email
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {userName}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'sharedLifecycleSteps',
      title: t('customer-requests.shared-lifecycle-steps'),
      width: 400,
      render(text: string, record: FactorRequest) {
        if (!record.factorRequestResponse?.shownLifecycleSteps?.length) {
          return
        }
        const sharedLifeCycles = formatLifecycleSteps(
          record?.factorRequestResponse?.shownLifecycleSteps as {
            id: number
            value: number
          }[],
        )
        return (
          <>
            {sharedLifeCycles.map((step) => (
              <Tag key={step.id}>
                {t(
                  'product.lifecycle-steps.' +
                    (step.key.replace('product-', '') ?? 'product-configuration') +
                    '.title',
                )}
              </Tag>
            ))}
          </>
        )
      },
    },
    {
      key: 'productUnit',
      title: t('customer-requests.product-unit'),
      render(text: string, record: FactorRequest) {
        if (!record.unit.name) {
          return
        }
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {String(record?.unit?.name)}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'responsible',
      title: t('customer-requests.responsible'),
      render(text: string, record: FactorRequest) {
        return (
          <span className={classes.nameSubcontainer}>
            <Text size="xl" className={classes.categoryName}>
              {record.responsible}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'note',
      title: t('customer-requests.note'),
      ellipsis: {
        showTitle: false,
      },
      render(text: string, record: FactorRequest) {
        return (
          <span className={classes.nameSubcontainer}>
            <Tooltip title={record.note}>{record.note}</Tooltip>
          </span>
        )
      },
    },
    {
      key: 'attachments',
      title: t('customer-requests.attachment'),
      render(text: string, record: FactorRequest) {
        if (!record?.factorRequestResponse?.files?.length) {
          return
        }
        return (
          <span className={classes.nameSubcontainer} style={{ columnGap: '6px' }}>
            <PaperClipOutlineIcon width={14} height={14} />
            <Text size="xl" className={classes.categoryName}>
              {record.factorRequestResponse?.files.length} {t('customer-requests.files-attached')}
            </Text>
          </span>
        )
      },
    },
    {
      key: 'factor',
      title: t('customer-requests.request-emission-factor'),
      fixed: 'right',
      render(text: string, record: FactorRequest) {
        const displayValue = calculateEmissionValue(record.product?.mass, record.value)
        return (
          <div className={classes.nameSubcontainer}>
            {displayValue ? (
              <span>
                {formatCompact(displayValue)} {record.unit.key}
              </span>
            ) : (
              <Tag type={'warning'}>{t('customer-requests.missing')}</Tag>
            )}
          </div>
        )
      },
    },
    {
      key: 'actions',
      title: t('customer-requests.actions'),
      fixed: 'right',
      render(text: string, record: FactorRequest) {
        const PopoverContent = (
          <div>
            <Row>
              <Col className={classes.header}>
                <Text size="xl" color="secondary">
                  {t('customer-requests.popover-title')}
                </Text>
              </Col>
            </Row>

            <div className={classes.actionsButtons}>
              <Button
                type="text"
                prefixIcon={<EyeOutlineIcon width={16} height={16} />}
                action={'review supplier share emission data'}
                category={AnalyticsCategories.FACTOR_REQUESTS}
                onClick={() =>
                  navigate(routes.log.factors.review.replace(':id', record.id.toString()))
                }
              >
                {t('customer-requests.review')}
              </Button>
              <Button
                type="text"
                onClick={() =>
                  handleUpdateFactorRequest(record.id, {
                    status: 'ACCEPTED',
                    unitId: record.unitId,
                  })
                }
                prefixIcon={<CheckCircleOutlineIcon width={16} height={16} />}
                action={'accept'}
                category={AnalyticsCategories.FACTOR_REQUESTS}
              >
                {t('customer-requests.accept')}
              </Button>
              <Button
                type="text"
                onClick={() =>
                  handleUpdateFactorRequest(record.id, {
                    status: 'REJECTED',
                    unitId: record.unitId,
                  })
                }
                prefixIcon={<XCircleOutlineIcon width={16} height={16} />}
                action={'reject'}
                category={AnalyticsCategories.FACTOR_REQUESTS}
                color="danger"
              >
                {t('customer-requests.reject')}
              </Button>
            </div>
          </div>
        )

        return (
          <Row justify="space-between">
            <Col span={6}>
              <Popover
                placement="bottom"
                content={PopoverContent}
                trigger="click"
                open={openPopoverId === record.id}
                overlayClassName={classes.customPopover}
                showArrow={false}
                onOpenChange={(newOpen) => handleOpenChange(newOpen, record.id)}
              >
                <Button
                  type="text"
                  category={AnalyticsCategories.FACTOR_REQUESTS}
                  action={'open'}
                  disabled={record.status !== 'SUBMITTED'}
                  prefixIcon={<DotsHorizontalIcon />}
                ></Button>
              </Popover>
            </Col>
          </Row>
        )
      },
    },
  ]

  return (
    <Table
      dataSource={factorRequests}
      rowKey="id"
      className={classes.table}
      columns={columns}
      scroll={{ x: 'max-content' }}
      showWrapper={false}
    />
  )
}

export default FactorRequestsTable
