/* eslint react-hooks/exhaustive-deps: 2 */

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from 'antd'
import { Button, Col, Drawer, Form, Row, Space } from 'antd/es'

import moment from 'moment'

import { LogicOperatorInputValueDto } from '@cozero/dtos'

import { SearchSelect } from '@/molecules/FiltersDrawer/SearchSelect'

import DatePicker from '@/atoms/DatePicker'

import classes from './FilterDrawerForm.module.less'
import { LogicOperatorInput } from './inputs/LogicOperatorInput'
import {
  FilterValues,
  FomInputType,
  FormInput,
  SearchSelectFiltersOptions,
  SearchSelectFiltersOptionsLoading,
  SearchSelectFiltersOptionsOnSearch,
} from './types'

type Props<
  TSearchSelectFiltersValuesKey extends string,
  TDatePickerFiltersValuesKey extends string,
  TTextFiltersValuesKey extends string,
  TLogicOperatorFiltersValuesKey extends string,
> = {
  open: boolean
  onClose: () => void
  value: FilterValues<
    TSearchSelectFiltersValuesKey,
    TDatePickerFiltersValuesKey,
    TTextFiltersValuesKey,
    TLogicOperatorFiltersValuesKey
  >
  onChange: (
    value: FilterValues<
      TSearchSelectFiltersValuesKey,
      TDatePickerFiltersValuesKey,
      TTextFiltersValuesKey,
      TLogicOperatorFiltersValuesKey
    >,
  ) => void
  onSubmit: (
    value: FilterValues<
      TSearchSelectFiltersValuesKey,
      TDatePickerFiltersValuesKey,
      TTextFiltersValuesKey,
      TLogicOperatorFiltersValuesKey
    >,
  ) => void
  inputs: FormInput<
    TSearchSelectFiltersValuesKey,
    TDatePickerFiltersValuesKey,
    TTextFiltersValuesKey,
    TLogicOperatorFiltersValuesKey
  >[]
  defaultValues?: FilterValues<
    TSearchSelectFiltersValuesKey,
    TDatePickerFiltersValuesKey,
    TTextFiltersValuesKey,
    TLogicOperatorFiltersValuesKey
  >
  options: SearchSelectFiltersOptions<TSearchSelectFiltersValuesKey>
  isLoading: SearchSelectFiltersOptionsLoading<TSearchSelectFiltersValuesKey>
  onSearch: SearchSelectFiltersOptionsOnSearch<TSearchSelectFiltersValuesKey>
}

export const FilterDrawerForm = <
  TSearchSelectFiltersValuesKey extends string,
  TDatePickerFiltersValuesKey extends string,
  TTextFiltersValuesKey extends string,
  TLogicOperatorFiltersValuesKey extends string,
>({
  onClose,
  open,
  value: formValue,
  onChange,
  onSubmit,
  inputs,
  defaultValues = {},
  options,
  isLoading,
  onSearch,
}: Props<
  TSearchSelectFiltersValuesKey,
  TDatePickerFiltersValuesKey,
  TTextFiltersValuesKey,
  TLogicOperatorFiltersValuesKey
>): React.ReactElement => {
  const { t } = useTranslation()

  const saveFilters = useCallback(() => {
    onSubmit(formValue)
    onClose()
  }, [formValue, onClose, onSubmit])

  const resetFilters = useCallback(() => {
    onChange(defaultValues)
  }, [onChange, defaultValues])

  const handleSelectChange = useCallback(
    (
      newValues: number[] | string[] | string | Date | number | null | LogicOperatorInputValueDto,
      key: keyof FilterValues<
        TSearchSelectFiltersValuesKey,
        TDatePickerFiltersValuesKey,
        TTextFiltersValuesKey,
        TLogicOperatorFiltersValuesKey
      >,
    ): void => {
      onChange({ ...formValue, [key]: newValues })
    },
    [formValue, onChange],
  )

  const handleDrawerClose = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Drawer
      title={t('act.analytics.hotspotAnalysis.filters.title')}
      onClose={handleDrawerClose}
      open={open}
      footer={
        <Space>
          <Button onClick={resetFilters} data-cy="drawer-filters-clear">
            {t('log.filter.clear')}
          </Button>

          <Button type="primary" onClick={() => saveFilters()} data-cy="drawer-filters-apply">
            {t('log.filter.save')}
          </Button>
        </Space>
      }
      footerStyle={{ display: 'flex', justifyContent: 'flex-start' }}
    >
      <Form layout="vertical">
        {inputs.map((input, index) => {
          return (
            <Row gutter={16} key={index}>
              <Col span={24}>
                <Form.Item label={input.label}>
                  {input.type === FomInputType.SEARCH_SELECT && (
                    <SearchSelect
                      multipleAllowed
                      size="large"
                      value={formValue[input.key]}
                      optionFilterProp="label"
                      placeholder={t('log.log-entries-drawer.select-and-search.placeholder')}
                      onSearch={onSearch?.[input.key]}
                      loading={isLoading?.[input.key]}
                      options={options?.[input.key]}
                      onChange={(value) => {
                        handleSelectChange(value, input.key)
                      }}
                    />
                  )}
                  {input.type === FomInputType.TEXT && (
                    <Input
                      className={classes.textInput}
                      size="large"
                      style={{ width: '100%' }}
                      value={formValue[input.key]}
                      placeholder={input.placeholder}
                      onChange={(el) => {
                        handleSelectChange(el.target.value, input.key)
                      }}
                    ></Input>
                  )}
                  {input.type === FomInputType.DATE_PICKER && (
                    <DatePicker
                      className={classes.datePickerInput}
                      picker="date"
                      value={formValue[input.key] ? moment(formValue[input.key]) : null}
                      onChange={(value) => {
                        handleSelectChange(value?.toDate() ?? null, input.key)
                      }}
                      allowClear={true}
                      style={{ width: '100%' }}
                      suffixIcon={null}
                    />
                  )}
                  {input.type === FomInputType.DATE_PICKER_YEAR && (
                    <DatePicker
                      className={classes.datePickerInput}
                      picker="year"
                      value={formValue[input.key] ? moment(formValue[input.key]) : null}
                      onChange={(value) => {
                        handleSelectChange(value?.toDate() ?? null, input.key)
                      }}
                      allowClear={true}
                      style={{ width: '100%' }}
                      suffixIcon={null}
                    />
                  )}
                  {input.type === FomInputType.LOGIC_OPERATOR && (
                    <LogicOperatorInput
                      value={formValue[input.key]}
                      onChange={(value) => {
                        handleSelectChange(value, input.key)
                      }}
                      placeholder={t('log.log-entries-drawer.logical-operator-input.placeholder')}
                      options={input.options}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )
        })}
      </Form>
    </Drawer>
  )
}
