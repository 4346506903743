import React from 'react'

import classNames from 'classnames'

import { IconProps } from '@/types/general'

import baseIconClasses from '../Icons.module.less'

const OfficeBuilding = ({
  width = 20,
  height = 20,
  color = 'currentColor',
  wrapperClassName,
  border = false,
  ...rest
}: IconProps): JSX.Element => {
  const { inline, defaultWrapper, defaultBorder } = baseIconClasses
  const wrapperClassNames = classNames(inline, wrapperClassName, {
    [defaultWrapper]: border,
    [defaultBorder]: border,
  })

  return (
    <div className={wrapperClassNames}>
      <svg
        width={width}
        height={height}
        {...rest}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3C2 1.34315 3.34315 0 5 0H15C16.6569 0 18 1.34315 18 3V18L19 18C19.5523 18 20 18.4477 20 19C20 19.5522 19.5523 20 19 20H0.999987C0.447702 20 -7.39088e-06 19.5522 0 19C7.39107e-06 18.4477 0.447729 18 1.00001 18L2 18V3ZM4 18H7V14C7 12.8954 7.89543 12 9 12H11C12.1046 12 13 12.8954 13 14V18H16V3C16 2.44772 15.5523 2 15 2H5C4.44772 2 4 2.44772 4 3V18ZM11 18V14H9V18H11ZM6 4.99998C6 4.44769 6.44772 3.99998 7 3.99998H8C8.55228 3.99998 9 4.44769 9 4.99998C9 5.55226 8.55228 5.99998 8 5.99998H7C6.44772 5.99998 6 5.55226 6 4.99998ZM11 4.99998C11 4.44769 11.4477 3.99998 12 3.99998H13C13.5523 3.99998 14 4.44769 14 4.99998C14 5.55226 13.5523 5.99998 13 5.99998H12C11.4477 5.99998 11 5.55226 11 4.99998ZM6 8.99998C6 8.44769 6.44772 7.99998 7 7.99998H8C8.55228 7.99998 9 8.44769 9 8.99998C9 9.55226 8.55228 9.99998 8 9.99998H7C6.44772 9.99998 6 9.55226 6 8.99998ZM11 8.99998C11 8.44769 11.4477 7.99998 12 7.99998H13C13.5523 7.99998 14 8.44769 14 8.99998C14 9.55226 13.5523 9.99998 13 9.99998H12C11.4477 9.99998 11 9.55226 11 8.99998Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

export default OfficeBuilding
